import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import { VirtualItem } from "@tanstack/react-virtual";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { DashboardsContext, OverviewQueryTitles } from "../../../contexts/DashboardsContext";
import useBulkDashboards from "../../../hooks/useBulkDashboards";
import useDashboards from '../../../hooks/useDashboards';
import usePaginator from "../../../hooks/usePaginator";
import { getGroupDashboardQueriesFunc, refreshWorkspaceScoreFunc, updateWorkspaceFunc } from "../../../lib/helper";
import { scrollbarStyle } from "../../../shared/dashboard";
import { Dashboard, DashboardQuery, Workspace } from "../../../types/files";
import NoDashboardsFoundView from "../../molecules/dashboards-empty/NoDashboardsFoundView";
import DashboardsSearchOrCreate from '../../molecules/dashboards-search-create/DashboardsSearchOrCreate';
import DashboardsTableFilter from "../../molecules/dashboards-table/DashboardsTableFilter";
import DashboardsTableHeader from "../../molecules/dashboards-table/DashboardsTableHeader";
import DashboardsTableRow from "../../molecules/dashboards-table/DashboardsTableRow";
import { TableHeaders } from "../../molecules/workspaces/SharedByAll";
import { collectionKey } from "../../pages/InvestorsPage";
import { FallbackLoading } from "../../templates/loader";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            minWidth: 1430,
            minHeight: 800,
            maxHeight: 800,
            width: 'min-content',
            height: 'fit-content',
            padding: 20,
            borderRadius: 24,
        },
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.5rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '8px 0',
        height: '100%',
        overflowY: 'auto',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    selected :{
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1rem',
        color: 'black',
    },
    headers: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        scrollBehavior: 'smooth',
        ...scrollbarStyle,
    },
    //
    table: {
        width: '100%',
        height: 'calc(100vh - 120px)',
    },
    header: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        scrollBehavior: 'auto',
        borderBottom: `1px solid ${theme.colors.neutral['400']}`,
        ...scrollbarStyle,
    },
    rows: {
        position: 'relative',
        minWidth: '100%',
        width: 'fit-content',
        height: '100%',
        overflow: 'auto',
        scrollBehavior: 'smooth',
        ...scrollbarStyle,
    },
    row: {
        position: 'absolute',
        top: 0,
        left: 0,
        minWidth: '100%',
        width: 'fit-content',
        height: 'auto',
        borderTop: `1px solid ${theme.colors.neutral['400']}`,
        background: `#fff`,
        "&:hover": {
            background: `rgb(246, 251, 251, 0.8)`,
        },
    },
    cancelButton: {
        width: 100,
        borderRadius: 40,
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    saveButton: {
        width: 100,
        borderRadius: 20,
        background: theme.palette.primary.main,
        color: 'white',
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    loadingText: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 700,
        color: theme.colors.neutral['900'],
    },
    loadingTextSub: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: '#9e9e9e',
    },
    loading: {
        width: '100%',
        height: '100%',
        color: theme.palette.primary.main,
    },
    checkBox: {
        padding: 'unset !important',
        marginRight: '4px !important',
        "& > svg": {
            width: '20px !important',
            height: '20px !important',
        }
    },
    checkBoxText: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['700'],
    },
}));

const AddCompaniesToInvestorModal: React.FC<{
    isOpen: boolean,
    workspace?: Workspace,
    onClose: () => void,
}> = ({ isOpen, workspace, onClose }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { mappedOverviewQueries, workspace: globalWorkspace, setWorkspace, dashboards, setDashboardsQueries } = useContext(DashboardsContext);
    const { getBulkDashboards, assignToWorkspaces, addToBulk, clearBulk} = useBulkDashboards();
    const { filterDashboardList } = useDashboards();

    const [loading, setLoading] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [searching, setSearching] = useState<boolean>(false);
    const [allChecked, setAllChecked] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [dashboardArray, setDashboardArray] = useState<Dashboard[]|null>(null);
    const [sortBy, setSortBy] = useState<string>('Added');
    const [sectors, setSectors] = useState<string[]>([]);
    const [stages, setStages] = useState<string[]>([]);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);
    const modalWrapperRef = useRef<HTMLDivElement>(null);
    const virtualRowsRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);

    const syncScroll = (e?: Event) => {
        e?.preventDefault();

        if (headerRef.current && virtualRowsRef.current)
            headerRef.current.scrollLeft = virtualRowsRef.current.scrollLeft;
    };

    const preventManualScroll = (e: Event) => {
        e.preventDefault();
        if (headerRef.current && virtualRowsRef.current) 
            headerRef.current.scrollLeft = virtualRowsRef.current.scrollLeft;
    };

    const attachListeners = () => {
        if (!headerRef.current || !virtualRowsRef.current) return;

        virtualRowsRef.current.addEventListener("scroll", syncScroll);
        headerRef.current.addEventListener("wheel", preventManualScroll, { passive: false });
        headerRef.current.addEventListener("touchmove", preventManualScroll, { passive: false });
    };

    const detachListeners = () => {
        if (!headerRef.current || !virtualRowsRef.current) return;

        virtualRowsRef.current.removeEventListener("scroll", syncScroll);
        headerRef.current.removeEventListener("wheel", preventManualScroll);
        headerRef.current.removeEventListener("touchmove", preventManualScroll);
    };

    const localWorkspace = useMemo(() => workspace ?? globalWorkspace, [globalWorkspace, workspace]);

    const bulkCount = useMemo(() => getBulkDashboards(collectionKey).length, [getBulkDashboards]);

    const sortedDashboards = useMemo(() => {
        if (sortBy === 'Fit score') {
            return Array.from(new Map(
                [...dashboardArray ?? []].map(dashboard => {
                    const investmentThesis: { workspaceId: string, score: number}[]
                        = mappedOverviewQueries.get(`${dashboard.id}:Investment Thesis`) || [];
                    const score = investmentThesis?.find(iT => iT.workspaceId === localWorkspace!.id)?.score ?? 0;

                    return [dashboard, score];
                }))).sort((a, b) => b[1] - a[1]).map(entry => entry[0]);
        }

        // else sort by 'Added'
        return [...dashboardArray ?? []].sort((prev: Dashboard, next: Dashboard) => {
            const prevDate = prev.createdAt;
            const nextDate = next.createdAt;

            return nextDate.localeCompare(prevDate);
        });
    // eslint-disable-next-line
    }, [dashboardArray, sortBy]);

    const filteredDashboards = useMemo(() => {
        let filtered: Dashboard[] = sortedDashboards;

        if (!!sectors.length){
            filtered = filtered.filter(dashboard =>
                sectors.every(key => (dashboard.tags || []).includes(key))
            );
        }

        if (!!stages.length){
            filtered = filtered.filter(dashboard => {
                const stage = dashboard.investmentStage || '';

                return stages.some(key => key === stage);
            });
        }

        return filtered;
    }, [sectors, sortedDashboards, stages]);

    const { allRows, rowVirtualizer, hasNextPage, isFetching } = usePaginator({
        key: 'add-companies-to-investor-modal-166',
        source: filteredDashboards,
        limit: 20,
        itemHeight: 60,
        virtualRowsRef,
    });

    const handleFilter = useCallback(({ tags, stage }) => {
        setSectors(tags);
        setStages(stage);
    }, []);

    const handleRefresh = useCallback((refresh?: boolean) => {
        if (!!localWorkspace) {
            const promises: Promise<any>[] = [];

            if (refresh)
                updateWorkspaceFunc(localWorkspace).then((updatedWorkspace?: Workspace) => setWorkspace({...updatedWorkspace!}));

            dashboards.forEach(dashboard => promises.push(
                refreshWorkspaceScoreFunc({
                    dashboardId: dashboard.id,
                    workspaceId: localWorkspace.id,
                    group: userGroup,
                })
            ));

            Promise.all(promises).then(() => {
                setSearching(true);
            });
        }
    // eslint-disable-next-line
    }, [localWorkspace, dashboards, userGroup]);

    const handleAssigntoWorkspace = useCallback(() => {
        setSaving(true);
        assignToWorkspaces(collectionKey, [localWorkspace!.id]).finally(() => {
            setSaving(false);
            onClose();
        });
    }, [assignToWorkspaces, localWorkspace, onClose]);

    useEffect(() => {
        if (!loading) {
            const observer = new MutationObserver(() => {
                if (headerRef.current && virtualRowsRef.current) {
                    clearTimeout(timerRef.current);
                    timerRef.current = setTimeout(() => attachListeners(), 500);
                }
            });
        
            if (modalWrapperRef.current)
                observer.observe(modalWrapperRef.current, { childList: true, subtree: true });        
            timerRef.current = setTimeout(() => attachListeners(), 500);

            return () => {
                observer.disconnect();
                detachListeners();
                clearTimeout(timerRef.current);
            };
        }    
    // eslint-disable-next-line
    }, [isOpen, loading]);

    useEffect(() => {
        if (allChecked)
            filteredDashboards.map(dashboard => addToBulk(collectionKey, dashboard));
        else
            clearBulk();
    // eslint-disable-next-line
    }, [allChecked]);

    useEffect(() => {
        if (filteredDashboards.length > bulkCount)
            setAllChecked(false);
    // eslint-disable-next-line
    }, [bulkCount]);

    useEffect(() => {
        setSaving(false);
        setSearching(false);
        clearBulk(collectionKey);
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (searching) {
            timerRef.current = setTimeout(() => {
                clearInterval(timerRef.current);
                getGroupDashboardQueriesFunc(userGroup).then((dashboardQueriesData) => {
                    setDashboardsQueries(dashboardQueriesData.filter((query: DashboardQuery) =>
                        OverviewQueryTitles.includes(query.title)
                    ));
                    setSearching(false);
                    setSortBy('Fit score');
                });
                timerRef.current = setTimeout(() => {
                    clearInterval(timerRef.current);
                    setSearching(false);
                    setSortBy('Fit score');
                }, 5000);
            }, 10000);
        }

        return () => {
            clearInterval(timerRef.current);
        }
    // eslint-disable-next-line
    }, [searching]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setDashboardArray(filterDashboardList(search || ''));
            clearTimeout(timerRef.current);
        }, 500);

        return () => {
            clearTimeout(timerRef.current);
        }
    }, [filterDashboardList, search]);

    useEffect(() => {
        setLoading((!!timerRef.current && !dashboardArray)|| isFetching);
    }, [dashboardArray, isFetching]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={!loading && !searching ? onClose : undefined} ref={modalWrapperRef}>
            <DialogTitle className={classes.dialogTitle}>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" width="100%">
                    <Box minWidth="400px">
                        <span>{`Add companies to ${localWorkspace?.name}`}</span>
                    </Box>
                    <Box minWidth="400px" maxWidth="800px">
                        <DashboardsSearchOrCreate search mediumFit onSearch={(value) => setSearch(value)} />
                    </Box>
                </Stack>
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DashboardsTableFilter onFilter={handleFilter} />
            <DialogContent className={classes.dialogContent}>
                <Stack className={classes.table}>
                    {loading ? (<FallbackLoading />) : !!filteredDashboards?.length ? (<>
                        <Stack alignItems="flex-start" justifyContent="flex-start" height="100%">
                            <Box width="100%" height="fit-content" overflow="hidden">
                                <Box className={classes.header} ref={headerRef}>
                                    <DashboardsTableHeader
                                        columns={TableHeaders}
                                        columnSorted={sortBy}
                                        onColumnSort={setSortBy}
                                        onRefreshScores={handleRefresh}
                                        noStatus />
                                </Box>
                            </Box>
                            <Box width="100%" height="100%" overflow="hidden">
                                <Box className={classes.rows} ref={virtualRowsRef}
                                    sx={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                                    {rowVirtualizer.getVirtualItems().map((virtualRow: VirtualItem) => (
                                        <Box className={classes.row}
                                            sx={{  height: `${virtualRow.size}px`, transform: `translateY(${virtualRow.start}px)`, }}
                                            key={'add-companies-to-investor-399-' + virtualRow.index} >
                                            {(virtualRow.index > allRows.length - 1) && hasNextPage
                                                ? (<FallbackLoading />)
                                                : (<DashboardsTableRow
                                                        dashboard={allRows[virtualRow.index]}
                                                        workspace={workspace ?? undefined}
                                                        columns={TableHeaders}
                                                        collectionKey={collectionKey}
                                                        refreshingScore={searching}
                                                        isPublic noStatus />)}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Stack>
                    </>) : (
                        <Stack className={classes.headers} alignItems="flex-start" justifyContent="flex-start">
                            <DashboardsTableHeader columns={TableHeaders} />
                            <Stack className={classes.rows} alignItems="flex-start" justifyContent="flex-start">
                                <NoDashboardsFoundView 
                                    noDataYet={!dashboards.length} 
                                    noCompaniesYet={!search && !sectors.length && !stages.length} 
                                    noCompaniesFound />
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" width="100%"
                    sx={{ transform: 'translateX(-10px)'}}>
                    <Checkbox edge="end"
                        className={classes.checkBox}
                        checked={allChecked}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setAllChecked(prev => !prev);
                        }}
                        disableRipple
                    />
                    <Typography className={classes.checkBoxText}>
                        {'Select all'}&nbsp;
                        <Typography component="span" className={classes.checkBoxText}
                            color={`${theme.colors.neutral['600']} !important`} fontWeight="500 !important" display="inline">
                            {`(${filteredDashboards.length} compan${filteredDashboards.length > 1 ? 'ies': 'y'})`}
                        </Typography>
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" width="100%">
                    {!searching && (
                        <Typography className={classes.selected}>
                            {`${bulkCount} compan${bulkCount > 1 ? 'ies': 'y'} selected`}
                        </Typography>
                    )}
                    <Button className={classes.cancelButton}
                        disabled={searching}
                        onClick={onClose}> {'Cancel'} </Button>
                    <LoadingButton variant="contained"
                        className={classes.saveButton}
                        loading={saving}
                        disabled={searching}
                        onClick={handleAssigntoWorkspace}
                    > {'Add'} </LoadingButton>
                </Stack>
            </DialogActions>
        </Dialog>
    </>);
}

export default AddCompaniesToInvestorModal;