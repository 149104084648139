import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import WestIcon from '@mui/icons-material/West';
import { Box, Breadcrumbs, Link, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useContext, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { DashboardContext } from "../../../contexts/DashboardContext";
import useBackwardsCompatible from "../../../hooks/useBackwardsCompatible";
import useDashboard from "../../../hooks/useDashboard";
import useRoute from "../../../hooks/useRoute";
import theme from "../../../theme";
import hideDisplay from "../../../utils/hideDisplay";
import AddDataButton from "../../atoms/AddDataButton";
import CampainSection from "../../atoms/CampainSection";
import IntegrationIcons from "../../atoms/IntegrationIcons";
import ShareDealButton from "../../atoms/ShareDealButton";
import DashboardStatusMenu from "../dashboard-menus/DashboardStatusMenu";
import DashboardDealEditor from "./DashboardDealEditor";
import DashboardTitleEditor from "./DashboardTitleEditor";
import {DashboardsContext} from '../../../contexts/DashboardsContext';

const useStyles = makeStyles(() => ({
    title: {
        fontFamily: 'Poppins',
        fontSize: '2.2rem',
        fontWeight: 'bolder',
    },
    link: {
        "&:hover": {
            textDecoration: 'underline',
            cursor: 'pointer',
        }
    },
    next: {
        width: 14,
        height: 14,
    },
    chip: {
        color: '#D97706',
        backgroundColor: '#FEF3C7',
        height: 30,
        "&:hover": {
            cursor: 'pointer',
        }
    },
    signUpButton: {
        width: '100%',
        height: 40,
        borderRadius: 20,
        backgroundImage: 'linear-gradient(to right, #3ABDC6 0%, #6FEEF6  15%, #3ABDC6  50%, #6FEEF6  80%, #3ABDC6 100%)',
        backgroundSize: '105% auto',
        boxShadow: '2px 2px 4px #9CCED3, -1px -1px 3px #fff',
        color: 'white',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    breadcrumbs: {
        "& .MuiBreadcrumbs-separator, & a": {
            fontFamily: 'Inter',
            fontSize: '1rem !important',
            fontWeight: 600,
            color: theme.palette.primary.main,
            textDecoration: 'none !important',
            cursor: 'auto',
            '&:hover': {
                textDecoration: 'underline !important',
                cursor: 'pointer',
            },
        },
    }
}));

export const SignUpLink = 'https://form.typeform.com/to/xV9TrSLL?typeform-source=www.notissia.com';

export const DashboardTitle: React.FC<{ readonly?: boolean }> = ({ readonly }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { workspace } = useContext(DashboardsContext);
    const { dashboard, isPublicView, isShared } = useContext(DashboardContext);
    const { getURLFrom, validateFrom, redirectFromDashboard } = useRoute();
    const { updateTitle, updateOwner, updateSource } = useDashboard();
    const { dashboardWorkspaces } = useBackwardsCompatible();
    const [isEditable, setIsEditable] = useState(false);

    const from = getURLFrom();

    if (!dashboard)
        return (<></>);

    return (<>
        <Stack direction="row" justifyContent="space-between" width="100%" my={1}>
            <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="center">
                {((!!from?.origin || validateFrom(dashboardWorkspaces(dashboard) || [])) && (!isPublicView || !!workspace)) ? (
                    <Breadcrumbs className={classes.breadcrumbs}
                        separator={<ArrowForwardIosIcon className={classes.next} />}>
                            {[
                                !!workspace ? (
                                    <Link onClick={redirectFromDashboard} key="dashboard-title-98">
                                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                                            <WestIcon />
                                            <span>{workspace.name}</span>
                                        </Stack>
                                    </Link>
                                ) : (
                                    <Link onClick={redirectFromDashboard} key="dashboard-title-95">
                                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                                            <WestIcon />
                                            <span style={{ textTransform: 'capitalize' }}>
                                                {`${from?.origin?.replace('dashboards', 'all companies')}`}
                                            </span>
                                        </Stack>
                                    </Link>
                                )
                            ]}
                    </Breadcrumbs>
                ) : (<Box mt={1} />)}
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                    {isEditable ? (
                        <DashboardTitleEditor
                            title={dashboard.title}
                            variant="h4"
                            onUpdate={(title) => {
                                if (updateTitle(title)) {
                                    setIsEditable(false);
                                    return true;
                                }

                                return false;
                            }}
                            onCancel={() => {
                                setIsEditable(false);
                            }}
                            edit={!readonly}
                            readonly={readonly}
                        />
                    ) : (
                        <Typography variant="h3" className={classes.title}
                            onClick={() => setIsEditable(!readonly)}>{dashboard.title}</Typography>
                    )}
                    {!isPublicView && !!dashboard?.status && (
                        <DashboardStatusMenu dashboard={dashboard} readOnly={isPublicView} />
                    )}
                </Stack>
            </Stack>
            {!isShared && (
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                    {isPublicView ? (!userGroup ? (<CampainSection />) : (
                        <Typography fontFamily="Inter" fontSize="1rem" fontWeight={600} color={theme.colors.cyan['400']}>
                            {'This dashboard is from an external user.'}
                        </Typography>
                    )) : (
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                            <Box display="none">
                                <IntegrationIcons />
                            </Box>
                            {hideDisplay(<AddDataButton dashboard={dashboard} />)}
                            {hideDisplay(<ShareDealButton dashboard={dashboard} />)}
                            <Box display="none">
                                <DashboardDealEditor
                                    label="Deal Owner:"
                                    currentValue={dashboard.userId}
                                    onUpdate={updateOwner}
                                    readOnly={readonly}
                                />
                                <DashboardDealEditor
                                    label="Deal Source:"
                                    currentValue={dashboard.source}
                                    onUpdate={(name) => updateSource(name, 'name')}
                                    readOnly={readonly}
                                />
                            </Box>
                        </Stack>
                    )}
                </Stack>
            )}
        </Stack>
    </>);
}
