import { TabContext, TabPanel } from "@mui/lab";
import { FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import useDashboards from "../../../hooks/useDashboards";
import { Workspace } from "../../../types/files";
import SharedByAll from "../../molecules/workspaces/SharedByAll";
import SharedByMeV2 from "../../molecules/workspaces/SharedByMeV2";
import { FallbackLoading } from "../../templates/loader";
import DashboardsTableFilter from "../../molecules/dashboards-table/DashboardsTableFilter";

export enum SharedBy {
    All = 'All',
    Me = 'Me',
}

const useStyles = makeStyles((theme) => ({
    panel: {
        height: '100%',
        width: '100%',
        transition: 'width .3s ease',
        overflow: 'hidden',
    },
    expanded: {
        height: '100%',
        width: '100%',
        minWidth: 'calc(100vw - 94px - 156px)',
        maxWidth: '100%',
    },
    preferences: {
        overflow: 'hidden',
        zIndex: 1300,
    },
    tabPanel: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        padding: 'unset',
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.75rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    selectorForm: {
        minWidth: 'unset',
        width: 'fit-content',
        height: 'auto',
        padding: 0,
        border: 0,
        "& > .MuiInputBase-root":{
            "& > .MuiSelect-select": {
                paddingLeft: 6,
            },
            "& > .MuiSvgIcon-root": {
                width: 24,
                height: 24,
                fill: theme.colors.neutral['600'],
                '&.Mui-disabled': {
                    fill: 'rgba(0, 0, 0, 0.26)',
                },
            },
            "& fieldset": {
              border: 0,
            },
        },
    },
    menuItem: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: 8,
    },
    menuLabel: {
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 600,
        color: theme.colors.neutral['600'],
    },
    menuSelected: {
        fontFamily: 'Inter',
        fontWeight: 700,
        color: theme.colors.primary['400'],
    },
}));

const InvestmentThesisV2: React.FC<{
    workspace?: Workspace,
    search?: string,
}> = ({ workspace, search }) => {
    const classes = useStyles();
    const { workspace: globalWorkspace } = useContext(DashboardsContext);
    const { dashboardsLoaded } = useDashboards();

    const [selected, setSelected] = useState<SharedBy>(SharedBy.All);
    const [sectors, setSectors] = useState<string[]>([]);
    const [stages, setStages] = useState<string[]>([]);
    const [count, setCount] = useState<number>(0);

    const localWorkspace = useMemo(() => workspace ?? globalWorkspace, [globalWorkspace, workspace]);
    
    const handleFilter = useCallback(({ tags, stage }) => {
        setSectors(tags);
        setStages(stage);
    }, []);

    if (!localWorkspace)
        return (<></>);

    return (<>
        {!dashboardsLoaded ? (
            <FallbackLoading height={`calc(100vh - 250px)`} />
        ) : (
            <Stack direction="column" className={classes.panel} alignItems="stretch" justifyContent="flex-start">
                <TabContext value={selected}>
                    <Stack direction="row" spacing={2} justifyContent="flex-start" width="100%">
                        <Stack direction="row" alignItems="center" justifyContent="flex-start" ml={2} minWidth="165px">
                            <Typography className={classes.menuLabel} fontSize="1.1rem !important">
                                {'Shared by: '}
                            </Typography>
                            <FormControl className={classes.selectorForm} variant="outlined">
                                <Select value={selected} onChange={(e) => setSelected(e.target.value as SharedBy)}
                                    renderValue={(selected: string) => (
                                        <Typography className={classes.menuSelected} fontSize="1.1rem !important">
                                            {(selected === SharedBy.All) ? SharedBy.All : SharedBy.Me}
                                        </Typography>
                                    )}>
                                    <MenuItem className={classes.menuItem} value={SharedBy.All}>
                                        <Typography className={classNames(classes.menuLabel, (selected === SharedBy.All) && classes.menuSelected)}>
                                            {SharedBy.All}
                                        </Typography>
                                    </MenuItem>
                                    {/* <MenuItem className={classes.menuItem} value={SharedBy.Me}>
                                        <Typography className={classNames(classes.menuLabel, (selected === SharedBy.Me) && classes.menuSelected)}>
                                            {SharedBy.Me}
                                        </Typography>
                                    </MenuItem> */}
                                </Select>
                            </FormControl>
                        </Stack>
                        {(selected === SharedBy.All) && (<DashboardsTableFilter count={count} onFilter={handleFilter} />)}
                    </Stack>
                    {/* <Divider sx={{ width: '100%' }} /> */}
                    <TabPanel className={classes.tabPanel} value={SharedBy.All}>
                        <SharedByAll 
                            workspace={localWorkspace} 
                            sectors={sectors} 
                            stages={stages} 
                            search={search} 
                            onFilter={handleFilter}
                            onCount={setCount} />
                    </TabPanel>
                    <TabPanel className={classes.tabPanel} value={SharedBy.Me}>
                        <SharedByMeV2 workspace={workspace} search={search} />
                    </TabPanel>
                </TabContext>
            </Stack>
        )}
    </>);
}

export default InvestmentThesisV2;
