import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Chip, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classnames from "classnames";
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import useBackwardsCompatible from "../../../hooks/useBackwardsCompatible";
import useDashboards from "../../../hooks/useDashboards";
import useRoute from "../../../hooks/useRoute";
import { updateCurrentUserWorkspaceUserMembershipFunc, updateWorkspaceFunc } from "../../../lib/helper";
import { defaultGeographies, defaultStages, scrollbarStyle } from "../../../shared/dashboard";
import theme from "../../../theme";
import { WorkspaceUserMembershipStatus } from '../../../types/common';
import { Dashboard, Workspace } from "../../../types/files";
import DashboardAddToWorkspaceButton from "../../atoms/dashboards/DashboardAddToWorkspaceButton";
import InvestorsIconFC from '../../atoms/InvestorsIconFC';
import MultiSelector from "../../atoms/MultiSelector";
import ThesisScore from "../../atoms/ThesisScore";
import WorkspaceSelect from "../../atoms/workspaces/WorkspaceSelect";
import WorkspaceShareEmail from "../../atoms/workspaces/WorkspaceShareEmail";
import WorkspaceMenu from "../workspace-menus/WorkspaceMenu";

const useStyles = makeStyles((theme) => ({
    row: {
        minWidth: '100%',
        width: 'fit-content',
        height: 'fit-content',
        borderBottom: `1px solid ${theme.colors.primary['300']}`,
        background: `#fff`,
        "&:hover": {
            background: `rgb(246, 251, 251, 0.8)`,
        },
    },
    solo: {
        padding: 8,
        background: `rgb(246, 251, 251, 0.8)`,
        borderRadius: 16,
        border: `1px solid ${theme.colors.cyan['500']}`,
    },
    findInvestor: {
        minHeight: '60px',
        borderBottom: `1px solid ${theme.colors.neutral['400']}`,
        cursor: 'pointer',
        "&:hover": {
            background: `rgb(246, 251, 251, 0.8)`,
        },
    },
    cell: {
        minWidth: 180,
        maxWidth: 180,
        minHeight: 60,
        maxHeight: 60,
        padding: '2px 10px',
    },
    narrowCell: {
        minWidth: 150,
        maxWidth: 150,
    },
    narrowerCell: {
        minWidth: 50,
        maxWidth: 50,
    },
    customCell: {
        minWidth: 240,
        maxWidth: 240,
    },
    customNarrowCell: {
        minWidth: 20,
        maxWidth: 20,
    },
    wideCell: {
        minWidth: 280,
        maxWidth: 280,
    },
    widerCell: {
        minWidth: 380,
        maxWidth: 380,
    },
    fullWidthCell: {
        minWidth: 0,
        maxWidth: 'unset !important',
        width: '100%',
    },
    hiddenCell: {
        display: 'none',
    },
    endCell: {
        minWidth: 4,
        maxWidth: 4,
        padding: 'unset',
    },
    label: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        textDecoration: 'none',
    },
    soloTitle: {
        fontSize: '1.1em !important',
        fontWeight: 'bold !important',
        color: theme.palette.common.black,
    },
    sublabel: {
        fontWeight: '500',
        color: theme.colors.neutral['500'],
    },
    owned: {
        fontFamily: 'Inter',
        fontSize: '0.85rem',
        color: theme.palette.primary.light,
    },
    shared: {
        fontFamily: 'Inter',
        fontSize: '0.85rem',
        color: `${theme.colors.neutral['600']} !important`,
    },
    admin: {
        fontWeight: 400,
        color: theme.palette.primary.light,
    },
    value: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.neutral['500'],
    },
    icon: {
        width: 20,
        height: 20,
    },
    checkBox: {
        padding: 'unset !important',
        marginRight: '4px !important',
        "& > svg": {
            width: '20px !important',
            height: '20px !important',
        }
    },
    wave: {
        borderRadius: '4px !important',
        height: '24px !important',
    },
    chip: {
        width: 'fit-content',
        height: '36px',
        borderRadius: '32px',
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '0.85rem',
        textTransform: 'none',
        backgroundColor: theme.colors.neutral['200'],
        color: theme.colors.neutral['700'],
        "&:hover": {
            backgroundColor: theme.colors.neutral['300'],
            color: theme.colors.neutral['800'],
        },
    },
    commonButton: {
        minWidth: 80,
        width: 120,
        height: 36,
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
    },
    cancelButton: {
        width: 100,
        height: 36,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const WorkspacesTableRow: React.FC<{
    workspace: Workspace & {group?: string},
    dashboard?: Dashboard,
    columns: string[],
    investors?: number,
    collectionKey?: string,
    forSharing?: boolean,
    withFitScore?: boolean,
    refreshingScore?: boolean,
    noSharing?: boolean,
    noMailing?: boolean,
    noMenu?: boolean,
    solo?: boolean,
    findInvestor?: boolean,
    overview?: boolean,
    invite?: boolean,
    flags?: any,
    onInvite?: (workspace: Workspace) => void,
}> = ({ workspace, dashboard, columns, investors, collectionKey, forSharing, withFitScore, refreshingScore, noSharing, noMailing, noMenu, solo, findInvestor, overview, invite, flags, onInvite }) => {
    const classes = useStyles();
    const { workspaces, setWorkspaces } = useContext(GroupSettingsContext);
    const { mappedOverviewQueries } = useContext(DashboardsContext);
    const { filterDashboardList } = useDashboards();
    const { dashboardWorkspaces } = useBackwardsCompatible();
    const { redirectToWorkspace } = useRoute();

    const [updating, setUpdating] = useState<string>('');
    const [hover, setHover] = useState<boolean>(false);
    const [state, setState] = useState<boolean>(false);
    const [dashboardArray, setDashboardArray] = useState<Dashboard[]>([]);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const showColumn = (column: string) => !!columns.find(col => col === column);

    const getMembership = useCallback((dashboard: Dashboard) =>
        dashboard?.workspaceMemberships?.find(wsMem => wsMem.workspaceId === workspace?.id)
    , [workspace]);

    const queriesLoaded = useMemo(() => !!mappedOverviewQueries.size, [mappedOverviewQueries.size]);
    const tags = useMemo(() => workspace?.investmentThesis?.focusArea?.tags || [], [workspace]);
    const stages = useMemo(() => workspace?.investmentThesis?.stage?.stages || [], [workspace]);
    const regions = useMemo(() => workspace?.investmentThesis?.geography?.regions || [], [workspace]);

    const isUserOwnedWorkspace = useMemo(() => !!workspaces.find(ws => ws.id === workspace?.id),[workspace, workspaces]);

    const sectorsCountMap: Map<string, number> = useMemo(() => {
        const sectorCount = new Map();

        dashboardArray.forEach(dashboard => {
            (dashboard.tags?? []).forEach(sector =>
                sectorCount.set(sector, (sectorCount.get(sector) ?? 0) + 1)
            );
        });

        return new Map(Array.from(sectorCount.entries()).sort());
    }, [dashboardArray]);

    const latelySharedArray = useMemo(() => dashboardArray.filter(dashboard => {
        const wsMembership = getMembership(dashboard);

        return moment().diff(!!wsMembership ? wsMembership.updatedAt : dashboard.updatedAt, 'days') < 7;
    }), [dashboardArray, getMembership]);

    const getQueryAnswer = useCallback((title: string) => (
        mappedOverviewQueries.get(`${dashboard?.id}:${title}`)
    ), [dashboard, mappedOverviewQueries]);

    const handleSave = useCallback(async (selections: string[], type: string) => {
        const investmentThesis = { ...workspace!.investmentThesis };

        switch (type) {
            case 'focusArea':
                investmentThesis.focusArea = { tags: selections };
                break;
            case 'stage':
                investmentThesis.stage = { stages: selections };
                break;
            case 'geography':
                investmentThesis.geography = { regions: selections };
                break;
        }

        setUpdating(type);
        updateWorkspaceFunc({...workspace, investmentThesis }).then((updatedWorkspace?: Workspace) => {
            setWorkspaces(prev => prev.map(ws => ws.id === workspace.id ? updatedWorkspace! : ws));
            setUpdating('');
        });
    // eslint-disable-next-line
    }, [workspace]);

    const handleAccept = useCallback(async (workspace: Workspace) => {
        setUpdating('accept');
        await updateCurrentUserWorkspaceUserMembershipFunc({workspaceId: workspace.id, status: WorkspaceUserMembershipStatus.ACCEPTED});
        setUpdating('');
        onInvite?.(workspace);
    }, [onInvite]);

    const handleDecline = useCallback(async (workspace: Workspace) => {
        setUpdating('decline');
        await updateCurrentUserWorkspaceUserMembershipFunc({workspaceId: workspace.id, status: WorkspaceUserMembershipStatus.REJECTED});
        setUpdating('');
        onInvite?.(workspace);
    }, [onInvite]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setDashboardArray(filterDashboardList('', workspace?.id)
                .filter(dashboard => dashboardWorkspaces(dashboard)?.includes(workspace!.id)));
            clearTimeout(timerRef.current);
        }, 500);

        return () => {
            clearTimeout(timerRef.current);
        }
    // eslint-disable-next-line
    }, [filterDashboardList, workspace]);

    return (<>
        <Stack direction="column" className={classnames(classes.row, solo && classes.solo, findInvestor && classes.findInvestor)}
            alignItems="stretch" justifyContent="flex-start"
            onMouseEnter={() => setHover(findInvestor || false)}
            onMouseLeave={() => setHover(false)}
            onClick={(findInvestor && !!collectionKey) ? () => setState(prev => !prev) : undefined}>
            <Stack direction="row" alignItems="stretch" justifyContent="flex-start" width="100%">
                {!solo && (
                    <Stack direction="column" alignItems="center" minWidth={24} width="min-content"
                        justifyContent={(findInvestor && !!workspace.email) ? 'flex-start' : 'center'} >
                        {!!collectionKey ? (overview ? (
                            <Box>
                                {workspace.isShared ? (
                                    <InvestorsIconFC color="rgb(163, 167, 172)" />
                                ) : (
                                    <PersonOutlineOutlinedIcon sx={{ width: 24, height: 24, fill: "rgb(163, 167, 172)" }} />
                                )}
                            </Box>
                        ) :  (
                            <Box pt={(findInvestor && !!workspace.email) ? "4px" : 0}>
                                {(findInvestor && (!hover && state === false)) ? (
                                    <PersonOutlineOutlinedIcon sx={{ width: 24, height: 24, fill: "rgb(163, 167, 172)" }} />
                                ) : (
                                    <WorkspaceSelect collection={collectionKey} workspace={workspace} state={findInvestor ? state : undefined} />
                                )}
                            </Box>
                        )) : (<Box width={24} />)}
                    </Stack>
                )}
                <Stack direction="column" className={classnames(classes.cell, findInvestor? classes.wideCell : classes.customCell)}
                    alignItems="flex-start" justifyContent="center">
                    <Typography className={classnames(classes.label, solo && classes.soloTitle)}
                        onClick={() => (!invite && !findInvestor) ? redirectToWorkspace(workspace.id, workspace.group, workspace.isShared) : undefined}
                        sx={{ cursor: (!invite && !findInvestor) ? 'pointer' : 'auto' }}>
                        {workspace.name}
                    </Typography>
                    {(findInvestor && !!workspace.email) && (
                        <Typography className={classes.sublabel}>
                            {workspace.email}
                        </Typography>
                    )}
                </Stack>
                {showColumn('Fit score') && (<>{withFitScore && (
                    <Stack direction="column" className={classes.cell} spacing={1} alignItems="flex-start" justifyContent="center">
                        {(!refreshingScore && queriesLoaded) ? (
                            <ThesisScore workspaceId={workspace.id} investmentTheses={getQueryAnswer('Investment Thesis') || []} />
                        ) : (
                            <Box sx={{ width: '100%' }}>
                                <Skeleton animation="wave" className={classes.wave} />
                            </Box>
                        )}
                    </Stack>
                )}</>)}
                {showColumn('Companies') && (
                    <Stack direction="column" className={classnames(classes.cell, classes.customCell)}
                        alignItems="flex-start" justifyContent="center">
                        <Typography className={classnames(classes.label, classes.soloTitle)} color={`${theme.colors.neutral['700']} !important`}>
                            {dashboardArray.length}
                            {!!latelySharedArray.length && (<>
                                <Typography component="span" color={`${theme.colors.success['500']} !important`} display="inline">
                                    &nbsp;&nbsp;{`(+${latelySharedArray.length} new this week)`}
                                </Typography>
                            </>)}
                        </Typography>
                    </Stack>
                )}
                {showColumn('Investors') && (<>{(investors !== undefined) && (
                    <Stack direction="column" className={classes.cell} alignItems="flex-start" justifyContent="center">
                        <Typography className={classnames(classes.label, classes.sublabel)} color="gray !important">
                            {!invite ? investors : 'Accept to view'}
                        </Typography>
                    </Stack>
                )}</>)}
                {showColumn('Workspace Admin') && (
                    <Stack direction="column" className={classes.cell} alignItems="flex-start" justifyContent="center">
                        <Typography className={classnames(classes.label, classes.admin)} >
                            {'workspace.email'}
                        </Typography>
                    </Stack>
                )}
                {showColumn('Email') && (
                    <Stack direction="column" className={classes.cell} alignItems="flex-start" justifyContent="center">
                        <Typography className={classnames(classes.label, classes.sublabel)} >
                            {workspace.email}
                        </Typography>
                    </Stack>
                )}
                {showColumn('[1]') && (
                    <Stack className={classnames(classes.cell, classes.customNarrowCell)} />
                )}
                {showColumn('Sector') && (
                    <Stack direction="column" className={classnames(classes.cell, classes.wideCell)}
                        alignItems="flex-start" justifyContent="center">
                        <MultiSelector
                            mainOptions={tags}
                            selections={tags}
                            onSelection={(selections) => handleSave(selections, 'focusArea')}
                            readOnly={false}
                            disabled={false}
                            loading={updating === 'focusArea'}
                            cropped={230} />
                    </Stack>
                )}
                {showColumn('Stage') && (
                    <Stack direction="column" className={classnames(classes.cell, classes.wideCell)}
                        alignItems="flex-start" justifyContent="center">
                        <MultiSelector
                            mainOptions={defaultStages}
                            selections={stages}
                            onSelection={(selections) => handleSave(selections, 'stage')}
                            background="#E0F1F1"
                            foreGround="#048290"
                            readOnly={false}
                            disabled={false}
                            loading={updating === 'stage'}
                            cropped={230} />
                    </Stack>
                )}
                {showColumn('Geography') && (
                    <Stack direction="column" className={classnames(classes.cell, classes.wideCell)}
                        alignItems="flex-start" justifyContent="center">
                        <MultiSelector
                            mainOptions={defaultGeographies}
                            selections={regions}
                            onSelection={(selections) => handleSave(selections, 'geography')}
                            background={theme.colors.blue['100']}
                            foreGround={theme.colors.blue['900']}
                            readOnly={false}
                            disabled={false}
                            loading={updating === 'geography'}
                            cropped={230} />
                    </Stack>
                )}
                {showColumn('Sectors') && (
                    <Stack direction="column" className={classnames(classes.cell, classes.fullWidthCell)}
                        alignItems="flex-start" justifyContent="center" sx={{ width: `calc(100vw - 1120px) !important`}}>
                        <Stack direction="row" alignItems="center" justifyContent="flex-start" width="100%" overflow="hidden">
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start"
                                sx={{ ...scrollbarStyle, overflowX: 'auto', overflowY: 'hidden' }}>
                                {Array.from(sectorsCountMap.entries()).map(([sector, count], i) => (
                                    <Chip className={classes.chip}
                                        label={<span>
                                            {sector}&nbsp;
                                            <span style={{ fontWeight: 500, color: "gray" }}>
                                                ({count})
                                            </span>
                                        </span>}
                                        key={'workspaces-table-row-380-' + i} />
                                ))}
                            </Stack>
                            <Stack width="10%" />
                        </Stack>
                    </Stack>
                )}
                {showColumn('[2]') && (
                    <Stack className={classnames(classes.cell, classes.fullWidthCell)} />
                )}
                {showColumn('[added]') && (
                    <Stack direction="column" className={classnames(classes.cell, classes.customCell)}
                        alignItems="flex-start" justifyContent="center" sx={{ background: '#fff '}}>
                        {isUserOwnedWorkspace ? (
                            <Typography className={classes.owned}>
                                {`Created ${moment(workspace.createdAt).fromNow()}`}
                            </Typography>
                        ) : (
                            <Typography className={classes.shared}>
                                {`Joined ${moment(workspace.updatedAt).fromNow()}`}
                            </Typography>
                        )}
                    </Stack>
                )}
                {showColumn('[invite]') && (
                    <Stack direction="column" className={classnames(classes.cell, classes.customCell)}
                        alignItems="flex-start" justifyContent="center">
                        <Stack direction="row" spacing={1} alignItems="center" width="100%" sx={{ background: '#fff '}}>
                            {!flags.sharedWorkspaces ? (
                                <Tooltip placement="top-start" title={
                                    <Typography component="span" fontSize="1rem" fontWeight={500}>
                                        {`To accept this invite and view deals,`}<br/>
                                        {`please subscribe to the Pro plan `}
                                        <Typography
                                            component="a"
                                            href="/subscriptions"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            display="inline"
                                            sx={{
                                                color: '#fff',
                                                fontSize: '1rem',
                                                fontWeight: 'bold',
                                                textDecoration: 'underline',
                                                "&:hover": {
                                                    textDecoration: 'underline !important',
                                                }
                                            }}
                                        >here</Typography>.
                                    </Typography>}
                                    componentsProps={{tooltip:{sx:{
                                        display: 'flex', alignItems: 'center', borderRadius: '12px',
                                        backgroundColor: 'rgba(150, 150, 150)', width: '300px', height: '70px',  padding: 2,
                                    }}}}>
                                    <LoadingButton variant="contained"
                                        className={classes.commonButton}
                                        loading={updating === 'accept'}
                                        sx={{ cursor: 'auto'}}>
                                        {'Accept invite'}
                                    </LoadingButton>
                                </Tooltip>
                            ) : (
                                <LoadingButton variant="contained"
                                    className={classes.commonButton}
                                    loading={updating === 'accept'}
                                    onClick={() => handleAccept(workspace) }>
                                    {'Accept invite'}
                                </LoadingButton>
                            )}
                            <LoadingButton variant="outlined"
                                className={classes.cancelButton}
                                loading={updating === 'decline'}
                                onClick={() => handleDecline(workspace)}>
                                {'Decline'}
                            </LoadingButton>
                        </Stack>
                    </Stack>
                )}
                {showColumn('[menu]') && (
                    <Stack direction="row" className={classnames(classes.cell, classes.narrowerCell)}
                        spacing={1} alignItems="center" justifyContent="flex-end" sx={{ background: '#fff '}}>
                        {!forSharing ? (<>
                            {!noSharing && (<WorkspaceShareEmail workspace={workspace} />)}
                            {!noMenu && (<WorkspaceMenu workspace={workspace} isOwned={isUserOwnedWorkspace} />)}
                        </>) : (!!dashboard) && (<>
                            <DashboardAddToWorkspaceButton dashboard={dashboard} workspaceId={workspace.id} />
                            {!noMailing && (<WorkspaceShareEmail dashboard={dashboard} workspace={workspace} />)}
                        </>)}
                    </Stack>
                )}
                <Stack className={classnames(classes.cell, classes.endCell)} sx={{ background: '#fff '}} />
            </Stack>
        </Stack>
    </>);
}

export default memo(withLDConsumer()(WorkspacesTableRow));