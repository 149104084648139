import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { ReactComponent as InvestorsIcon } from "../../../assets/icons/investors.svg";
import theme from "../../../theme";
import CreateEditInvestorModal from "../../modals/investors/CreateEditInvestorModal";

const useStyles = makeStyles((theme) => ({
    mainText: {
        fontFamily: 'Inter',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        color: '#666666',
        textAlign: 'center',
    },
    otherText: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 400,
        color: theme.colors.neutral['600'],
        textAlign: 'center',
    },
    createButton: {
        width: 'auto',
        height: 42,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
}));

const InvestorsIconFC: React.FC<{ color: string }> = ({ color }) => (
    <InvestorsIcon width={150} height={120} stroke={color} />
);


const NoInvestorsYetView: React.FC<{}> = () => {
    const classes = useStyles();
    const [createInvestorModalOpen, setCreateInvestorModalOpen] = useState<boolean>(false);

    return (<>
        <Stack spacing={3} alignItems="center" justifyContent="center" width="100%" height="calc(100vh - 300px)">
        <InvestorsIconFC color={theme.colors.neutral['400']} />
            <Typography className={classes.mainText}>
                {'Notissia tracks your network based on'}<br/>
                {'the deal flow they share with you.'}
            </Typography>
            <Typography className={classes.otherText}>
                {'People from your network who share'}<br />
                {'deals with you will appear here.'}
            </Typography>
            <Box />
            <Button variant="outlined"
                className={classes.createButton}
                startIcon={<AddCircleOutlineIcon />}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setCreateInvestorModalOpen(true);
                }}> {'Add people'} </Button>
        </Stack>
        {createInvestorModalOpen && (
            <CreateEditInvestorModal
                isOpen={createInvestorModalOpen}
                onClose={() => {
                    setCreateInvestorModalOpen(false);
                }}
            />
        )}
    </>);
}

export default NoInvestorsYetView;