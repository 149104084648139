import { Button, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState, useMemo, useEffect, useRef } from "react";
import DashboardsSearchOrCreate from "../../dashboards-search-create/DashboardsSearchOrCreate";
import { Dashboard } from "../../../../types/files";
import ArrayUtils from "../../../../utils/ArrayUtils";
import useDashboards from "../../../../hooks/useDashboards";
import configuredAsyncComponentLoader, { FallbackLoading } from "../../../templates/loader";
import useBulkDashboards from "../../../../hooks/useBulkDashboards";
import BulkShareDeals, { BulkShareCuratedTabs } from "../../../atoms/[legacy]/home-bulk-share-deals";

const DashboardCardMatchesList = configuredAsyncComponentLoader(
    () => import('../../../atoms/dashboards/DashboardCardMatchesList'),
    { fallback: <FallbackLoading height={`calc(100vh - 250px)`} />}
);

const useStyles = makeStyles((theme) => ({
    mainContent: {
        width: '45vw',
        height: 'max-content',
        maxHeight: '60vh',
        padding: 'unset',
        overflow: 'hidden',
    },
    mainTitle: {
        fontFamily: 'Inter',
        fontSize: '1.3rem !important',
        fontWeight: '700 !important',
        color: theme.palette.primary.main,
    },
    subcaption: {
        fontFamily: 'Inter',
        fontSize: '1rem !important',
        fontWeight: 400,
        color: theme.colors.neutral['600'],
    },
    scrollable: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
    },
    commonButton: {
        width: 'auto',
        minWidth: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    nextButton: {
        width: 'auto',
        minWidth: 100,
        borderRadius: 32,
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
    },
}));

export const collectionKey = 'bulk-share-companies';

const ShareCompanies: React.FC<{
    onSelect?: React.Dispatch<React.SetStateAction<BulkShareCuratedTabs>>,
}> = ({ onSelect }) => {
    const classes = useStyles();
    const {getBulkDashboards} = useBulkDashboards();
    const { filterDashboardList } = useDashboards();

    const [dashboardArray, setDashboardArray] = useState<Dashboard[]|null>(null);
    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const hasSelection = useMemo(() => !!getBulkDashboards(collectionKey).length, [getBulkDashboards]);
    const dashboards = useMemo(() => filterDashboardList(search || ''), [filterDashboardList, search]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setDashboardArray(ArrayUtils.sortByDescending([...dashboards], 'createdAt'));
            clearTimeout(timerRef.current);
        }, 1000);

        return () => {
            clearTimeout(timerRef.current);
        }
    }, [dashboards]);

    useEffect(() => {
        setLoading(!!timerRef.current && !dashboardArray && !search);
    }, [dashboardArray, search]);

    return (<>
        <BulkShareDeals.Panel value={BulkShareCuratedTabs.Companies}
            content={(
                <Stack direction="column" className={classes.mainContent} alignItems="flex-start" justifyContent="stretch">
                    <Stack spacing={1} alignItems="flex-start">
                        <Typography className={classes.mainTitle}>
                            {'Select which companies to share:'}
                        </Typography>
                        <Typography className={classes.subcaption}>
                            {'You will be able to curate which company to share with which investor after.'}
                        </Typography>
                    </Stack>
                    <Stack alignItems="center" width="100%" px={3} pt={2} pb={1}>
                        <DashboardsSearchOrCreate search mediumFit onSearch={(value) => setSearch(value)} />
                    </Stack>
                    <Stack width="100%" height="45vh" overflow="hidden">
                        <Stack direction="column" className={classes.scrollable}
                            spacing={1} alignItems="flex-start" justifyContent="stretch">
                            {loading ? (<FallbackLoading />) : (
                                <DashboardCardMatchesList
                                    dashboards={dashboardArray}
                                    collectionKey={collectionKey}
                                    display noSharing noSaving />
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            )}
            actions={(
                <Stack direction="row" justifyContent="flex-end">
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Button className={classes.commonButton}
                            onClick={() => onSelect?.(BulkShareCuratedTabs.Close)}>
                            {'Cancel'}
                        </Button>
                        <Button variant="contained"
                            className={classes.nextButton}
                            disabled={!hasSelection}
                            onClick={() => onSelect?.(BulkShareCuratedTabs.EachStepper)}>
                            {'Next'}
                            {hasSelection && (
                                <Typography component="span" fontWeight={300}>
                                    {': select investors'}
                                </Typography>
                            )}
                        </Button>
                    </Stack>
                </Stack>
            )} />
    </>);
}

export default ShareCompanies;