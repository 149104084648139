import React, { useState } from "react";
import { Button, } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as ShareIcon } from "../../../../assets/icons/share.svg";
import BulkShareDealsModal from "../../../modals/[legacy]/BulkShareDealsModal";

const useStyles = makeStyles((theme) => ({
    button: {
        width: 'auto',
        height: 40,
        borderRadius: 32,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    shareIcon: {
        height: 24,
        width: 24,
        stroke: theme.palette.common.white,
    },
}));

const BulkShareButton: React.FC<{}> = () => {
    const classes = useStyles();
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    return (<>
        <Button variant="contained"
            className={classes.button}
            startIcon={<ShareIcon className={classes.shareIcon} />}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setModalOpen(true);
            }}>{`Share deals`} </Button>
        {modalOpen && (
            <BulkShareDealsModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
        )}
    </>);
}

export default BulkShareButton;
