import { useEffect, useMemo, useState } from "react";
import useBulkDashboards from "../../../hooks/useBulkDashboards";
import { collectionKey } from '../../organisms/dashboards/DashboardsView';
import FindInvestorsShareDealModal from "../dashboard-details/FindInvestorsShareDealModal";
import FindInvestorsShareDealsUnifiedModal from "./FindInvestorsShareDealsUnifiedModal";
import FindInvestorsShareDealsModal from "./FindInvestorsShareDealsModal";

const FindInvestorsShareDealsLoaderModal: React.FC<{
    isOpen: boolean,
    onClose: () => void,
}> = ({ isOpen, onClose }) => {
    const { getBulkDashboards, clearBulk } = useBulkDashboards();
    const [manualSharing, setManualSharing] = useState<boolean>(false);

    const bulkDashboards = useMemo(() =>
        getBulkDashboards(collectionKey).map(bulk => bulk.dashboard)
    , [getBulkDashboards]);

    useEffect(() => {
        return () => clearBulk(collectionKey);
    // eslint-disable-next-line
    }, []);

    if (bulkDashboards.length === 1)
        return (<FindInvestorsShareDealModal isOpen={isOpen} onClose={onClose} />);

    if (manualSharing)
        return (<FindInvestorsShareDealsModal isOpen={isOpen} onClose={onClose} />);
    return (
        <FindInvestorsShareDealsUnifiedModal
            isOpen={isOpen && !manualSharing}
            onSwitch={() => setManualSharing(true)}
            onClose={onClose} />
    );
}

export default FindInvestorsShareDealsLoaderModal;