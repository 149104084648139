import { TabContext, TabPanel } from "@mui/lab";
import { FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import useBackwardsCompatible from '../../../hooks/useBackwardsCompatible';
import useDashboards from "../../../hooks/useDashboards";
import { scrollbarStyle } from "../../../shared/dashboard";
import { Dashboard, Workspace } from "../../../types/files";
import SharedByDealOwner from "../../molecules/workspaces/SharedByDealOwner";
import SharedByMe from "../../molecules/workspaces/SharedByMe";
import { FallbackLoading } from "../../templates/loader";

export enum SharedBy {
    DealOwner = 'Deal Owner',
    Me = 'Me',
}

const useStyles = makeStyles((theme) => ({
    panel: {
        height: '100%',
        width: '100%',
        transition: 'width .3s ease',
        overflow: 'hidden',
    },
    preferences: {
        overflow: 'hidden',
        zIndex: 1300,
    },
    tabPanel: {
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: 'unset',
        ...scrollbarStyle,
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.75rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    selectorForm: {
        minWidth: 'unset',
        width: 'fit-content',
        height: 'auto',
        padding: 0,
        border: 0,
        "& > .MuiInputBase-root":{
            "& > .MuiSelect-select": {
                paddingLeft: 6,
            },
            "& > .MuiSvgIcon-root": {
                width: 24,
                height: 24,
                fill: theme.colors.neutral['600'],
                '&.Mui-disabled': {
                    fill: 'rgba(0, 0, 0, 0.26)',
                },
            },
            "& fieldset": {
              border: 0,
            },
        },
    },
    menuItem: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: 8,
    },
    menuLabel: {
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 600,
        color: theme.colors.neutral['600'],
    },
    menuSelected: {
        fontFamily: 'Inter',
        fontWeight: 700,
        color: theme.colors.primary['400'],
    },
}));

const InvestmentThesis: React.FC<{
    workspace?: Workspace,
    search?: string,
}> = ({ workspace, search }) => {
    const classes = useStyles();
    const { isPublicView, workspace: globalWorkspace } = useContext(DashboardsContext);
    const { dashboardsLoaded, filterDashboardList } = useDashboards();
    const { dashboardSource } = useBackwardsCompatible();

    const [selected, setSelected] = useState<SharedBy>(SharedBy.DealOwner);
    const [dashboardArray, setDashboardArray] = useState<Dashboard[]>([]);
    const hasSharedByMeCheck = useRef<boolean>(false);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const localWorkspace = useMemo(() => workspace ?? globalWorkspace, [globalWorkspace, workspace]);

    const filteredDashboards = useMemo(() =>
        filterDashboardList(search || '').filter(dashboard => {
            const source = dashboardSource(dashboard);

            return (dashboard.source?.toLowerCase() === localWorkspace?.name?.toLowerCase()
                || (localWorkspace?.name?.toLowerCase() === source?.name?.toLowerCase()
                    && localWorkspace?.email?.toLowerCase() === source?.email?.toLowerCase()));
        }).sort((a, b) => b.createdAt.localeCompare(a.createdAt))
    // eslint-disable-next-line
    , [filterDashboardList, localWorkspace, search]);

    const hasSharedByMe = useMemo(() => !!filterDashboardList(search || '', localWorkspace?.id).length
    , [filterDashboardList, localWorkspace, search]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            clearTimeout(timerRef.current);
            setDashboardArray(filteredDashboards);

            if (!hasSharedByMeCheck.current && hasSharedByMe && !filteredDashboards.length) {
                hasSharedByMeCheck.current = true;
                setSelected(SharedBy.Me);
            }
        }, 500);

        return () => {
            clearTimeout(timerRef.current);
        }
    // eslint-disable-next-line
    }, [filteredDashboards]);

    useEffect(() => {
        if (isPublicView)
            setSelected(SharedBy.Me);
    }, [isPublicView]);

    if (!localWorkspace)
        return (<></>);

    return (<>
        {!dashboardsLoaded ? (
            <FallbackLoading height={`calc(100vh - 250px)`} />
        ) : (
                <Stack direction="column" className={classes.panel} alignItems="stretch" justifyContent="flex-start"
                    sx={isPublicView ? { maxWidth: 'unset !important'} : {}}>
                        <TabContext value={selected}>
                            {!isPublicView && (<>
                                <Stack direction="row" justifyContent="space-between" width="100%">
                                    <Stack direction="row" alignItems="center" justifyContent="flex-start" ml={2}>
                                        <Typography className={classes.menuLabel} fontSize="1.1rem !important">
                                            {'Shared by: '}
                                        </Typography>
                                        <FormControl className={classes.selectorForm} variant="outlined">
                                            <Select value={selected} onChange={(e) => setSelected(e.target.value as SharedBy)}
                                                renderValue={(selected: string) => (
                                                    <Typography className={classes.menuSelected} fontSize="1.1rem !important">
                                                        {(selected === SharedBy.DealOwner) ? localWorkspace.name : SharedBy.Me}
                                                    </Typography>
                                                )}>
                                                <MenuItem className={classes.menuItem} value={SharedBy.DealOwner}>
                                                    <Typography className={classNames(classes.menuLabel, (selected === SharedBy.DealOwner) && classes.menuSelected)}>
                                                        {localWorkspace.name}
                                                    </Typography>
                                                </MenuItem>
                                                <MenuItem className={classes.menuItem} value={SharedBy.Me}>
                                                    <Typography className={classNames(classes.menuLabel, (selected === SharedBy.Me) && classes.menuSelected)}>
                                                        {SharedBy.Me}
                                                    </Typography>
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                </Stack>
                            </>)}
                            {!isPublicView && (
                                <TabPanel className={classes.tabPanel} value={SharedBy.DealOwner}>
                                    <SharedByDealOwner
                                        workspace={localWorkspace || undefined}
                                        dashboards={dashboardArray}
                                        loading={!dashboardsLoaded} />
                                </TabPanel>
                            )}
                            <TabPanel className={classes.tabPanel} value={SharedBy.Me}>
                                <SharedByMe workspace={workspace} search={search} />
                            </TabPanel>
                        </TabContext>
                </Stack>
        )}
    </>);
}

export default InvestmentThesis;
