import { StrictMode } from "react";
import { render } from "react-dom";
import RootContainer from "./containers/RootContainer";
import SharePointContext from "./contexts/SharepointContext";
import "./assets/datadog-instrumentation";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import awsConfig from "./aws-exports";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "./styles/amplify.css";
import "./styles/fonts.css";
import "./styles/image-marker.css";
import "./styles/index.css";
import "./styles/mui.css";
import "./styles/react-editor.css";
import "./styles/uppy.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <SharePointContext>
        <RootContainer />
      </SharePointContext>
      {awsConfig.environment !== "app" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  </StrictMode>,
  document.getElementById("root")
);
