import React, { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getGroupSettingsFunc, updateGroupSettingsFunc } from '../lib/helper';
import { AuthContext } from './AuthContext';
import ObjectUtils from '../utils/ObjectUtils';
import { InvestmentThesis, Workspace } from '../types/files';

type GroupSettingsContextProps = {
  loaded: boolean;
  group: string;
  statuses: string[];
  setStatuses: Dispatch<SetStateAction<string[]>>;
  tags: string[];
  setTags: Dispatch<SetStateAction<string[]>>;
  stages: string[];
  setStages: Dispatch<SetStateAction<string[]>>;
  externalSync: boolean;
  setExternalSync: Dispatch<SetStateAction<boolean>>;
  autoIngestFromEmail: boolean;
  setAutoIngestFromEmail: Dispatch<SetStateAction<boolean>>;
  autoSendDigestEmail: boolean;
  setAutoSendDigestEmail: Dispatch<SetStateAction<boolean>>;
  investmentThesis: InvestmentThesis|null;
  setInvestmentThesis: Dispatch<SetStateAction<InvestmentThesis|null>>;
  workspaces: Workspace[];
  setWorkspaces: Dispatch<SetStateAction<Workspace[]>>;
  sharedWorkspaces: Workspace[];
  setSharedWorkspaces: Dispatch<SetStateAction<Workspace[]>>;
  excludedCompanyTerms: string[];
  setExcludedCompanyTerms: Dispatch<SetStateAction<string[]>>;
  refreshSettings: () => void;
};

export const GroupSettingsContext = createContext<GroupSettingsContextProps>({
  loaded: false,
  group: '',
  statuses: [],
  setStatuses: () => {},
  tags: [],
  setTags: () => {},
  stages: [],
  setStages: () => {},
  externalSync: false,
  setExternalSync: () => {},
  autoIngestFromEmail: false,
  setAutoIngestFromEmail: () => {},
  autoSendDigestEmail: false,
  setAutoSendDigestEmail: () => {},
  investmentThesis: null,
  setInvestmentThesis: () => {},
  workspaces: [],
  setWorkspaces: () => {},
  sharedWorkspaces: [],
  setSharedWorkspaces: () => {},
  excludedCompanyTerms: [],
  setExcludedCompanyTerms: () => {},
  refreshSettings: () => {},
});

const GroupSettingsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { userGroup } = useContext(AuthContext);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [group, setGroup] = useState<string>('');
  const [statuses, setStatuses] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [stages, setStages] = useState<string[]>([]);
  const [externalSync, setExternalSync] = useState<boolean>(false);
  const [autoIngestFromEmail, setAutoIngestFromEmail] = useState<boolean>(false);
  const [autoSendDigestEmail, setAutoSendDigestEmail] = useState<boolean>(false);
  const [investmentThesis, setInvestmentThesis] = useState<InvestmentThesis|null>(null);
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [sharedWorkspaces, setSharedWorkspaces] = useState<Workspace[]>([]);
  const [excludedCompanyTerms, setExcludedCompanyTerms] = useState<string[]>([]);

  const updatedStatusRef = useRef<string[]>([]);
  const updatedTagsRef = useRef<string[]>([]);
  const updatedStagesRef = useRef<string[]>([]);
  const updatedAutoSyncRef = useRef<boolean>(false);
  const updatedAutoIngestRef = useRef<boolean>(false);
  const updatedAutoSendDigestRef = useRef<boolean>(false);
  const updatedInvestmentThesis = useRef<InvestmentThesis|null>(null);
  const updatedWorkspacesRef = useRef<Workspace[]>([]);
  const updatedSharedWorkspacesRef = useRef<Workspace[]>([]);
  const excludedCompanyTermsRef = useRef<string[]>([]);

  const refreshSettings = useCallback(() => {
    getGroupSettingsFunc({}).then((groupSettingsData) => {
      updatedTagsRef.current = groupSettingsData?.tags?.sort((a, b) => a > b ? 1 : 0) ?? [];
      updatedStagesRef.current = groupSettingsData?.investmentStages?.sort((a, b) => a > b ? 1 : 0) ?? [];
      updatedStatusRef.current = groupSettingsData?.statuses?.map(statuses => !!statuses ? statuses.trim().toLowerCase() : '<rename this statuses>') ?? [];
      updatedAutoSyncRef.current = groupSettingsData?.shouldSyncAllDashboardsExternally ?? false;
      updatedAutoIngestRef.current = groupSettingsData?.shouldIngestDashboardsFromEmail ?? false;
      updatedAutoSendDigestRef.current = groupSettingsData?.shouldSendDigestEmail ?? false;
      updatedInvestmentThesis.current = groupSettingsData?.investmentThesis ?? null;
      updatedWorkspacesRef.current = groupSettingsData?.workspaces ?? [];
      updatedSharedWorkspacesRef.current = [];
      excludedCompanyTermsRef.current = groupSettingsData?.excludedCompanyTerms ?? [];

      setGroup(groupSettingsData?.group as string);
      setTags(updatedTagsRef.current);
      setStages(updatedStagesRef.current);
      setStatuses(updatedStatusRef.current);
      setExternalSync(updatedAutoSyncRef.current);
      setAutoIngestFromEmail(updatedAutoIngestRef.current);
      setAutoSendDigestEmail(updatedAutoSendDigestRef.current);
      setInvestmentThesis(updatedInvestmentThesis.current);
      setWorkspaces(updatedWorkspacesRef.current);
      setSharedWorkspaces(updatedSharedWorkspacesRef.current);
      setExcludedCompanyTerms(excludedCompanyTermsRef.current);
    }).finally(() => setLoaded(true));
  }, []);

  useEffect(() => {
    if (userGroup) {
      setLoaded(false);
      refreshSettings();
    }
  // eslint-disable-next-line
  }, [userGroup]);

  useEffect(() => {
    if (userGroup) {
      if (!ObjectUtils.equalObjects(statuses, updatedStatusRef.current)) {
        Promise.resolve(updateGroupSettingsFunc({ group: userGroup, statuses: statuses, }));
        updatedStatusRef.current = structuredClone(statuses);
      }
    }
  // eslint-disable-next-line
  }, [statuses]);

  useEffect(() => {
    if (userGroup) {
      if (!ObjectUtils.equalObjects(tags, updatedTagsRef.current)) {
        Promise.resolve(updateGroupSettingsFunc({ group: userGroup, tags: tags, }));
        updatedTagsRef.current = structuredClone(tags);
      }
    }
  // eslint-disable-next-line
  }, [tags]);

  useEffect(() => {
    if (userGroup) {
      if (!ObjectUtils.equalObjects(stages, updatedStagesRef.current)) {
        Promise.resolve(updateGroupSettingsFunc({ group: userGroup, investmentStages: stages, }));
        updatedStagesRef.current = structuredClone(stages);
      }
    }
  // eslint-disable-next-line
  }, [stages]);

  useEffect(() => {
    if (!ObjectUtils.equalObjects(externalSync, updatedAutoSyncRef.current)) {
      Promise.resolve(updateGroupSettingsFunc({ group: userGroup, shouldSyncAllDashboardsExternally: externalSync, }));
      updatedAutoSyncRef.current = structuredClone(externalSync);
    }
  // eslint-disable-next-line
  }, [externalSync]);

  useEffect(() => {
    if (userGroup) {
      if (!ObjectUtils.equalObjects(autoIngestFromEmail, updatedAutoIngestRef.current)) {
        Promise.resolve(updateGroupSettingsFunc({ group: userGroup, shouldIngestDashboardsFromEmail: autoIngestFromEmail, }));
        updatedAutoIngestRef.current = structuredClone(autoIngestFromEmail);
      }
    }
  // eslint-disable-next-line
  }, [autoIngestFromEmail]);

  useEffect(() => {
    if (userGroup) {
      if (!ObjectUtils.equalObjects(autoSendDigestEmail, updatedAutoSendDigestRef.current)) {
        Promise.resolve(updateGroupSettingsFunc({ group: userGroup, shouldSendDigestEmail: autoSendDigestEmail, }));
        updatedAutoSendDigestRef.current = structuredClone(autoSendDigestEmail);
      }
    }
  // eslint-disable-next-line
  }, [autoSendDigestEmail]);

  useEffect(() => {
    if (userGroup) {
      if (!ObjectUtils.equalObjects(investmentThesis, updatedInvestmentThesis.current)) {
        Promise.resolve(updateGroupSettingsFunc({ group: userGroup, investmentThesis: investmentThesis!, }));
        updatedInvestmentThesis.current = structuredClone(investmentThesis);
      }
    }
  // eslint-disable-next-line
  }, [investmentThesis]);

  useEffect(() => {
    if (userGroup) {
      if (!ObjectUtils.equalObjects(workspaces, updatedWorkspacesRef.current)) {
        Promise.resolve(updateGroupSettingsFunc({ group: userGroup, workspaces: workspaces, }));
        updatedWorkspacesRef.current = structuredClone(workspaces);
      }
    }
  // eslint-disable-next-line
  }, [workspaces]);

  useEffect(() => {
    if (userGroup) {
      if (!ObjectUtils.equalObjects(sharedWorkspaces, updatedSharedWorkspacesRef.current)) {
        updatedSharedWorkspacesRef.current = structuredClone(sharedWorkspaces);
      }
    }
  // eslint-disable-next-line
  }, [sharedWorkspaces]);

  useEffect(() => {
    if (userGroup) {
      if (!ObjectUtils.equalObjects(excludedCompanyTerms, excludedCompanyTermsRef.current)) {
        Promise.resolve(updateGroupSettingsFunc({ group: userGroup, excludedCompanyTerms: excludedCompanyTerms, }));
        excludedCompanyTermsRef.current = structuredClone(excludedCompanyTerms);
      }
    }
  // eslint-disable-next-line
  }, [excludedCompanyTerms]);

  const contextValue = useMemo(() => ({
    loaded,
    group,
    statuses,
    setStatuses,
    tags,
    setTags,
    stages,
    setStages,
    externalSync,
    setExternalSync,
    autoIngestFromEmail,
    setAutoIngestFromEmail,
    autoSendDigestEmail,
    setAutoSendDigestEmail,
    investmentThesis,
    setInvestmentThesis,
    workspaces,
    setWorkspaces,
    sharedWorkspaces,
    setSharedWorkspaces,
    excludedCompanyTerms,
    setExcludedCompanyTerms,
    refreshSettings
  }), [
    loaded,
    group,
    statuses,
    setStatuses,
    tags,
    setTags,
    stages,
    setStages,
    externalSync,
    setExternalSync,
    autoIngestFromEmail,
    setAutoIngestFromEmail,
    autoSendDigestEmail,
    setAutoSendDigestEmail,
    investmentThesis,
    setInvestmentThesis,
    workspaces,
    setWorkspaces,
    sharedWorkspaces,
    setSharedWorkspaces,
    excludedCompanyTerms,
    setExcludedCompanyTerms,
    refreshSettings,
  ]);

  return (
    <GroupSettingsContext.Provider value={contextValue}>
      {children}
    </GroupSettingsContext.Provider>
  );
};

export default GroupSettingsProvider;
