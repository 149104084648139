import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import { AuthContext } from "../../../contexts/AuthContext";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import useBackwardsCompatible from "../../../hooks/useBackwardsCompatible";
import useBulkDashboards from "../../../hooks/useBulkDashboards";
import useBulkWorkspaces from "../../../hooks/useBulkWorkspaces";
import { sendShareWorkspaceEmailFunc, updateWorkspaceFunc } from "../../../lib/helper";
import { Dashboard, Workspace } from "../../../types/files";
import ArrayUtils from "../../../utils/ArrayUtils";
import FindInvestorsShareDeal from "../../atoms/multi-action/FindInvestorsShareDeal";
import { collectionKey } from "../../organisms/dashboards/DashboardsView";

const useSuccessStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: 'unset',
            maxHeight: 'unset',
            width: 'fit-content',
            height: 'fit-content',
            padding: '16px 24px',
            borderRadius: 24,
        },
    },
    dialogTitle: {
        display: 'flex',
        width: 'auto',
        height: 'auto',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.3rem',
    },
    dialogContent: {
        display: 'flex',
        width: 'auto',
        height: 'auto',
        flexDirection: 'column',
        padding: '8px 0',
        overflowY: 'hidden',
    },
    dialogActions: {
        display: 'flex',
        width: 'auto',
        height: 'auto',
        flexDirection: 'column',
        alignItems: 'center',
    },
    sharedIcon: {
        width: 40,
        height: 40,
        color: theme.palette.primary.main,
    },
    successText : {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    commonButton: {
        minWidth: 80,
        width: 'fit-content',
        borderRadius: 20,
        background: theme.palette.primary.main,
        color: 'white',
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const useMainStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '1200px',
            minHeight: '850px',
            maxHeight: '850px',
            width: '80vw',
            height: '100%',
            padding: 16,
            borderRadius: 24,
            overflow: 'hidden',
        },
    },
    dialogTitle: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 'unset',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'auto',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: 'black',
    },
    navigateButton: {
        width: 'auto',
        height: 40,
        border: 'none',
        color: '#666666',
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
        "&:hover": {
            border: 'none',
            background: 'none',
        },
        "& > .MuiButton-startIcon, .MuiButton-endIcon": {
            "& > .MuiSvgIcon-root": {
                width: 24,
                height: 24,
                color: theme.palette.primary.main,
            }
        },
    },
    navigate: {
        color: theme.palette.primary.main,
        paddingLeft: 8,
        "&:hover": {
            textDecoration: 'underline',
        },
    },
    cancelButton: {
        width: 100,
        borderRadius: 40,
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    saveAllButton: {
        minWidth: 100,
        width: 'auto',
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 20,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
        "& > span": {
            margin: 'unset',
        }
    },
    commonButton: {
        height: 36,
        borderRadius: 32,
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
    },
    icon: {
        width: 32,
        height: 32,
        stroke: '#7bd4d4',
    }
}));

const CountDownSec = 3;

const FindInvestorsShareDealSuccessModal: React.FC<{
    isOpen: boolean,
    onClose: () => void,
}> = ({ isOpen, onClose }) => {
    const classes = useSuccessStyles();
    const [countDown, setCountDown] = useState<number>(CountDownSec);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    useEffect(() => {
        timerRef.current = setInterval(() => {
            setCountDown(prev => {
                if (prev > 1) {
                    return prev - 1;
                } else {
                    clearInterval(timerRef.current);
                    onClose();
                    return prev;
                }
            });
        }, 1000);

        return () => {
            clearInterval(timerRef.current);
        }
    }, [onClose]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={onClose}>
            <DialogContent className={classes.dialogContent}>
                <Stack spacing={2} alignItems="center" justifyContent="center" width="100%" py={3}>
                    <CheckCircleIcon className={classes.sharedIcon} />
                    <Typography className={classes.successText}>
                        {'Deals successfully sent'}
                    </Typography>
                </Stack>
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" alignItems="center" justifyContent="center" width="100%">
                    <Button variant="contained"
                        className={classes.commonButton}
                        onClick={onClose}> {`Close (${countDown})`} </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    </>);
}

const FindInvestorsShareDealsModal: React.FC<{
    isOpen: boolean,
    onClose: () => void,
}> = ({ isOpen, onClose }) => {
    const classes = useMainStyles();
    const { user } = useContext(AuthContext);
    const { setWorkspaces } = useContext(GroupSettingsContext);
    const { setDashboards } = useContext(DashboardsContext);
    const { getBulkWorkspaces, assignToDashboards } = useBulkWorkspaces();
    const { getBulkDashboards  } = useBulkDashboards();
    const { dashboardWorkspaces } = useBackwardsCompatible();

    const [loading, setLoading] = useState<boolean>(false);
    const [shared, setShared] = useState<boolean>(false);
    const [mailables, setMailables] = useState<{workspace: Workspace, dashboards: Dashboard[]}[]>([]);
    const [activeStep, setActiveStep] = useState<number>(0);
    const scrollableRef = useRef<HTMLDivElement | null>(null);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const bulkDashboards = useMemo(() =>
        getBulkDashboards(collectionKey).map(bulk => bulk.dashboard)
    , [getBulkDashboards]);

    const bulkWorkspaces = useMemo(() =>
        bulkDashboards.map(dashboard => getBulkWorkspaces(`dashboard[${dashboard!.id}]`))
    , [bulkDashboards, getBulkWorkspaces]);

    const handleNavigate = useCallback((index: number) => {
        setActiveStep(prev => prev + Math.sign(index) * Number((prev + index ) >= 0 && (prev + index) < bulkDashboards.length));
    }, [bulkDashboards]);

    const handleClose = useCallback(() => {
        setLoading(false);
        timerRef.current = setTimeout(() => {
            onClose();
        }, 200);

        return () => {
            clearTimeout(timerRef.current);
        }
    }, [onClose]);

    const handleShareEmails = useCallback(async () => {
        return new Promise<Dashboard[] | any>(async (resolve) => {
            const updatePromises: Promise<Workspace>[] = [];
            const assignPromises: Promise<Dashboard[]>[] = [];
            const sharePromises: Promise<string | any>[] = [];

            setLoading(true);
            mailables.forEach(({workspace, dashboards}) => {
                if (!workspace.isPublic)
                    updatePromises.push(updateWorkspaceFunc({...workspace, isPublic: true, }));

                assignPromises.push(new Promise<Dashboard[]>(async (resolve) => {
                    const unassignedIds = dashboards.filter(dashboard =>
                        !dashboardWorkspaces(dashboard).includes(workspace.id)
                    ).map(dashboard => dashboard.id);
                    const dashboardsData: Dashboard[] = await assignToDashboards(collectionKey, unassignedIds) ?? [];

                    resolve(dashboardsData);
                }));

                sharePromises.push(sendShareWorkspaceEmailFunc({
                    workspaceId: workspace.id,
                    toAddress: workspace.email,
                    replyToAddress: user.attributes.email,
                    dashboardIds: dashboards.map(dashboard => dashboard.id),
                }));
            });

            Promise.all([Promise.all(updatePromises), Promise.all(assignPromises)])
                .then(async ([updatedWorkspaces, multiUpdatedDashboards]) => {
                const updatedDashboards = multiUpdatedDashboards.flat();

                setWorkspaces(prev => prev.map(ws => updatedWorkspaces?.find(updated => updated.id === ws.id) ?? ws));
                setDashboards(prev => ArrayUtils.sortByDescending(prev.map(current => {
                    const updates = updatedDashboards.filter(updated => updated.id === current.id);

                    if (!!updates.length) {
                        const dashboardUpdate = ArrayUtils.sortByDescending(updates, 'updatedAt')[0];

                        return dashboardUpdate;
                    }

                    return current;
                }), 'createdAt'));

                await Promise.all(sharePromises);
                resolve(true);
            }).finally(() => setShared(true));
        });
    // eslint-disable-next-line
    }, [mailables, user]);

    useEffect(() => {
        setMailables(Array.from(new Map(bulkDashboards.flatMap(dashboard =>
                getBulkWorkspaces(`dashboard[${dashboard!.id}]`).map(workspace => [workspace, dashboard])
            ).reduce((acc, [workspace, dashboard]) => {
                if (!acc.has(workspace))
                    acc.set(workspace, []);
                acc.get(workspace).push(dashboard);

                return acc;
            }, new Map())
        ).entries()).map(([workspace, dashboards]) => ({ workspace, dashboards })));
    }, [bulkDashboards, getBulkWorkspaces]);

    return (<>
        {shared ? (
            <FindInvestorsShareDealSuccessModal isOpen={shared} onClose={handleClose} />
        ) : (<>
            <Dialog className={classes.dialog} open={isOpen} onClose={handleClose}>
                <DialogTitle className={classes.dialogTitle}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                        <ShareIcon className={classes.icon} />
                        <Typography className={classes.title}>{`Find investors and share`}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end">
                        {(activeStep > 0 && activeStep < bulkDashboards.length) && (
                            <Button variant="outlined"
                                className={classes.navigateButton}
                                startIcon={<WestIcon />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleNavigate(-1);
                                }} disableRipple>
                                {'Back:'}
                                <span className={classes.navigate}>
                                    {bulkDashboards[activeStep - 1].title}
                                </span>
                            </Button>
                        )}
                        {(activeStep >= 0 && activeStep < bulkDashboards.length - 1) && (
                            <Button variant="outlined"
                                className={classes.navigateButton}
                                endIcon={<EastIcon />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleNavigate(+1);
                                }} disableRipple>
                                {'Next:'}
                                <span className={classes.navigate}>
                                    {bulkDashboards[activeStep + 1].title}
                                </span>
                            </Button>
                        )}
                    </Stack>
                </DialogTitle>
                <Divider sx={{ margin: '8px 0'}} />
                <DialogContent className={classes.dialogContent} ref={scrollableRef}>
                    {bulkDashboards.map((dashboard, i) => (
                        <FindInvestorsShareDeal dashboard={dashboard} isExpandable={activeStep === i} />
                    ))}
                </DialogContent>
                <Divider sx={{ margin: '8px 0'}} />
                <DialogActions className={classes.dialogActions}>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" width="100%">
                        <Button className={classes.cancelButton}
                            onClick={handleClose}> {'Cancel'} </Button>
                        <LoadingButton variant="contained"
                            className={classes.commonButton}
                            disabled={!bulkWorkspaces.length}
                            loading={loading}
                            onClick={handleShareEmails}
                            sx={{ width: 120 }}>{'Send'}</LoadingButton>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>)}
    </>);
}

export default FindInvestorsShareDealsModal;