import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthProvider from "../contexts/AuthContext";
import { Stack } from "@mui/material";
import { LaunchDarkly } from "../helpers/constants";
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { basicLogger } from 'launchdarkly-js-client-sdk';
import awsExports from '../aws-exports';
import DashboardDetailsPage from "./pages/DashboardDetailsPage";
import PublicSidebar from "./templates/PublicSidebar";
import WorkspacePage from "./pages/WorkspacePage";
import SharedWorkspacePage from "./pages/SharedWorkspacePage";

const { environment } = awsExports;

const LoginRedirectComponent: React.FC<{}> = () => {
	useEffect(() => {
		window.location.replace(window.location.origin + "/home");
	}, []);

	return (<></>);
}

const PublicApp: React.FC<{}> = () => {
  return (<>
    <Stack direction="row" minHeight="100vh" maxHeight="100vh">
      	<PublicSidebar />
      		<Stack width="calc(100vw - 94px)" maxHeight="100%">
				<Switch>
					<Route exact path="/shared/public/:workspaceId">
						<SharedWorkspacePage publicWorkspace />
					</Route>

					<Route exact path="/people/public/:workspaceId">
						<WorkspacePage publicWorkspace />
					</Route>

					<Route exact path={[
						"/dashboards/shared/public/:dashboardId/:viewMode/:fileId",
						"/dashboards/shared/public/:dashboardId",
						"/dashboards/public/:dashboardId/:viewMode/:fileId",
						"/dashboards/public/:dashboardId",
					]}>
						<DashboardDetailsPage publicDashboard />
					</Route>

					<Route exact path="/login">
						<AuthProvider>
							<LoginRedirectComponent />
						</AuthProvider>
					</Route>

					<Route exact path={["/", "*"]}>
						<Redirect to="/login" />
					</Route>
				</Switch>
			</Stack>
		</Stack>
	</>);
}

export default withLDProvider({
  clientSideID: LaunchDarkly(environment).clientId,
  options: {
    logger: basicLogger({ level: 'error' })
  }
})(PublicApp);
