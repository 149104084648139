import { Container, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback } from "react";
import awsConfig from '../../aws-exports';
import { scrollbarStyle } from "../../shared/dashboard";
import DashboardCounter from "../molecules/subscription-plans/DashboardCounter";
import SubscriptionSelector from "../organisms/subscriptions/SubscriptionSelector";

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        height: '100%',
        maxWidth: 'unset !important',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    planChip: {
        height: 36,
        width: 170,
        borderRadius: 32,
        borderWidth: 3,
        textTransform: 'none',
        padding: '8px 24px',
        fontSize: 18,
        fontWeight: 'bold',
        background: '#7bd4d4',
        color: '#fff',
        "&:hover": {
            borderWidth: 3,
            fontWeight: 'bold',
        },
    },
    currentStatsText: {
        fontSize: '1.25rem',
        fontWeight: '700',
        fontFamily: 'Inter',
        color: theme.colors.warning[600],
    },
    dashboardStatsText: {
        fontSize: '1.2rem',
        fontWeight: '600',
        fontFamily: 'Inter',
    },
    subStatsText: {
        fontSize: '0.9rem',
        fontFamily: 'Inter',
        color: theme.colors.warning[500],
    },
}));

const SubscriptionsPage: React.FC = () => {
    const classes = useStyles();

    const handleStripePortal = useCallback(() => {
        const { environment } = awsConfig;
        const url = environment === "app"
            ? "https://billing.stripe.com/p/login/eVa4huagU5wAgj64gg"
            : "https://billing.stripe.com/p/login/test_00gcPV0xIefd2bueUU";

        window.open(url, "_blank");
    }, []);

    return (<>
        <Container className={classes.rootContainer}>
            <Stack direction="column" spacing={3} pt={4} pb={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">                    
                    <Typography variant="h4-bold" fontFamily="Poppins">Our plans</Typography>
                    <DashboardCounter />
                </Stack>
                <SubscriptionSelector onSelectSubscription={handleStripePortal} />
            </Stack>
        </Container>
    </>);
}

export default SubscriptionsPage;