import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Divider, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AuthContext } from '../../../contexts/AuthContext';
import { DashboardsContext, OverviewQueryTitles } from '../../../contexts/DashboardsContext';
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import useBulkWorkspaces from "../../../hooks/useBulkWorkspaces";
import { getDashboardQueriesFunc, refreshWorkspaceScoreFunc } from '../../../lib/helper';
import { scrollbarStyle } from '../../../shared/dashboard';
import { Dashboard, DashboardQuery, Workspace } from '../../../types/files';
import { getUniqueElements } from '../../../utils/getUniqueElements';
import NoWorkspacesFoundView from '../../molecules/workspaces-empty/NoWorkspacesFoundView';
import WorkspacesSearchOrCreate from '../../molecules/workspaces-search-create/WorkspacesSearchOrCreate';
import WorkspacesTableHeader from '../../molecules/workspaces-table/WorkspacesTableHeader';
import WorkspacesTableRow from "../../molecules/workspaces-table/WorkspacesTableRow";
import { FallbackLoading } from "../../templates/loader";

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        overflow: 'hidden',
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        color: '#666666',
    },
    table: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    headers: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        ...scrollbarStyle,
    },
    rows: {
        minWidth: '100%',
        width: 'fit-content',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    selected :{
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1rem',
        color: 'black',
    },
    copyButton: {
        width: 'fit-content',
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 600,
        color: theme.palette.primary.main,
        textTransform: 'none',
        justifyContent: 'flex-start',
        "& > .MuiButton-startIcon": {
            "& > .MuiSvgIcon-root": {
                width: 24,
                height: 24,
                color: theme.palette.primary.main,
            }
        },
    },
}));

export const WorkspaceSelectionKey = 'workspaces:select';
const TableHeaders = ['Name', 'Fit score', '[2]'];

const FoundInvestors: React.FC<{ dashboards: Dashboard[], collectionKey: string, }> = ({ dashboards, collectionKey }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { mappedOverviewQueries, setDashboardsQueries } = useContext(DashboardsContext);
    const { workspaces } = useContext(GroupSettingsContext);
    const { getBulkWorkspaces, clearBulk } = useBulkWorkspaces();

    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [investors, setInvestors] = useState<Workspace[]>([]);
    const [searching, setSearching] = useState<boolean>(false);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const bulkWorkspaces = useMemo(() => getBulkWorkspaces(collectionKey), [getBulkWorkspaces, collectionKey]);

    const workspaceScoreMap: Map<Workspace, number> = useMemo(() => new Map(
        workspaces.map(workspace => {
            const investmentThesis: { workspaceId: string, score: number}[]
                = dashboards.map(dashboard => mappedOverviewQueries.get(`${dashboard?.id}:Investment Thesis`) || []).flat();
            const score = investmentThesis?.find(iT => iT.workspaceId === workspace!.id)?.score ?? 0;

            return [workspace, score];
        }))
    , [dashboards, mappedOverviewQueries, workspaces]);

    const searchWorkspaces = useMemo(() => {
        const searchText = (search ?? '').trim().toLowerCase();
        const keywords = searchText.split(',').map(token => token.trim());
        let filtered: Workspace[] = Array.from(workspaceScoreMap)
            .sort((a, b) => a[0].name.localeCompare(b[0].name))
            .sort((a, b) => b[1] - a[1]).map(entry => entry[0]);

        if (keywords.some(keyword => keyword.length > 0)) {
            if (keywords.length === 1) {
                // if keyword is only one, search in name, email, and website
                filtered = filtered.filter(workspace =>
                    workspace.name?.toLowerCase().includes(searchText) ||
                    workspace.email?.toLowerCase().includes(searchText) ||
                    workspace.website?.toLowerCase().includes(searchText)
                );
            } else {
                // match keywords in sector, stage, geography
                filtered = filtered.filter(workspace => {
                    const tags = workspace.investmentThesis?.focusArea?.tags?.map(sector =>sector.toLowerCase()) || [];
                    const stages = workspace.investmentThesis?.stage?.stages?.map(stage =>stage.toLowerCase()) || [];
                    const regions = workspace.investmentThesis?.geography?.regions?.map(geo =>geo.toLowerCase()) || [];

                    return keywords.map(key =>
                        [...tags, ...stages, ...regions].map(word => word.includes(key) ? key : null)
                    ).flat().filter(getUniqueElements).filter(Boolean)[0];
                });
            }
        }

        return filtered;
    }, [search, workspaceScoreMap]);

    const filteredWorkspaces = useMemo(() =>
        searchWorkspaces.filter(workspace => !workspace.isShared)
    , [searchWorkspaces]);

    const handleRefresh = useCallback(() => {
        const promises: Promise<any>[] = [];

        workspaces.forEach(workspace => {
            dashboards.forEach(dashboard => promises.push(
                refreshWorkspaceScoreFunc({
                    dashboardId: dashboard!.id,
                    workspaceId: workspace.id,
                    group: userGroup,
                })
            ));
        });

        Promise.all(promises).then(() => {
            setSearching(true);
        });
    }, [workspaces, dashboards, userGroup]);

    const handleCopy = useCallback(() => {
        const emails = bulkWorkspaces.reduce((emails, ws) => {
            if (!!ws.email)
                emails.push(ws.email)

            return emails;
        }, [] as string[]).join(', ');

        navigator.clipboard.write([new ClipboardItem({
            'text/plain': new Blob([emails], { type: 'text/plain' }),
            'text/html': new Blob([emails], { type: 'text/html' }),
        })]);
    }, [bulkWorkspaces]);

    useEffect(() => {
        setSearching(false);

        return () => {
            clearBulk(WorkspaceSelectionKey);
            dashboards.forEach(dashboard => clearBulk(`dashboard[${dashboard.id}]`));
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setInvestors(filteredWorkspaces);
            setLoading(false);
            clearTimeout(timerRef.current);
        }, 500);

        return () => {
            clearTimeout(timerRef.current);
        }
    // eslint-disable-next-line
    }, [filteredWorkspaces]);

    useEffect(() => {
        setLoading(!!timerRef.current && !investors.length);
    }, [investors.length]);

    useEffect(() => {
        if (searching) {
            timerRef.current = setTimeout(() => {
                clearInterval(timerRef.current);
                Promise.all(dashboards.map(dashboard => getDashboardQueriesFunc(dashboard!.id)))
                    .then(queriesDataArray => {
                        const queriesData = queriesDataArray.flat();

                        setDashboardsQueries(prev => prev.map((query: DashboardQuery) => {
                            if (OverviewQueryTitles.includes(query.title)) {
                                const lookUpQuery = queriesData.find(q => !!dashboards.find(d => q.dashboardId === d.id) && q.title === query.title);

                                if (!!lookUpQuery)
                                    return lookUpQuery;
                            }

                            return query;
                        }));
                        setSearching(false);
                    });
                timerRef.current = setTimeout(() => {
                    clearInterval(timerRef.current);
                    setSearching(false);
                }, 45000);
            }, 15000);
        }

        return () => {
            clearInterval(timerRef.current);
        }
    // eslint-disable-next-line
    }, [searching]);

    return (<>
        <Stack direction="column" className={classes.container} alignItems="stretch" justifyContent="space-between">
            <Stack direction="column" spacing={2} alignItems="stretch" justifyContent="flex-start" height="90%">
                <Typography className={classes.title}>
                    {`List of investors based on their deal preferences: `}
                </Typography>
                <Stack className={classes.table} >
                    <Stack className={classes.headers} alignItems="flex-start" justifyContent="flex-start">
                        <WorkspacesSearchOrCreate search mediumFit onSearch={(value) => setSearch(value)} />
                        {loading ? (<FallbackLoading />) : (<>
                            {!!investors.length ? (<>
                                <WorkspacesTableHeader columns={TableHeaders} onRefreshScores={handleRefresh} findInvestor />
                                <Stack className={classes.rows} alignItems="flex-start" justifyContent="flex-start">
                                    {investors.map(investor => (
                                        <WorkspacesTableRow
                                            workspace={investor}
                                            columns={TableHeaders}
                                            collectionKey={collectionKey}
                                            refreshingScore={searching}
                                            noMailing noMenu withFitScore findInvestor
                                            key={'found-investors-214-' + investor.id} />
                                    ))}
                                </Stack>
                            </>) : (<>
                                <WorkspacesTableHeader columns={TableHeaders} findInvestor />
                                <NoWorkspacesFoundView noResult />
                            </>)}
                        </>)}
                    </Stack>
                </Stack>
            </Stack>
            <Stack direction="column" spacing={2} alignItems="stretch" justifyContent="flex-end" height="10%">
                <Divider sx={{ width: '100%' }} />
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
                    <Typography className={classes.selected}>
                        {`${bulkWorkspaces.length} investor${bulkWorkspaces.length > 1 ? 's': ''} selected`}
                    </Typography>
                    {!loading && (
                        <Button className={classes.copyButton}
                            startIcon={<ContentCopyIcon />}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleCopy();
                            }}> {'Copy emails'} </Button>
                    )}
                </Stack>
            </Stack>
        </Stack>
    </>);
}
export default memo(FoundInvestors);