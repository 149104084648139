import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as InvestorsIcon } from "../../../assets/icons/investors.svg";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import { createWorkspaceFunc, updateWorkspaceFunc } from "../../../lib/helper";
import { Workspace } from "../../../types/files";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: 550,
            height: 'fit-content',
            padding: '16px 24px',
            borderRadius: 24,
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.3rem',
        gap: '0.5rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'hidden',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 0,
    },
    label: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    caption: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        color: theme.colors.neutral['500'],
    },
    inputField: {
        width: '100%',
        "& .MuiOutlinedInput-root": {
            height: 40,
            borderRadius: 8,
            padding: '0 8px !important',
            background: '#fff',
            "& > input, > textarea": {
                fontFamily: 'Lato',
                fontSize: '1.2rem',
                color: '#048290',
                padding: 'unset !important',
            },
            "& fieldset": {
              	borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              	borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              	borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    },
    existingIcon: {
        width: 20,
        height: 20,
        color: theme.colors.error['700'],
    },
    existingCaption: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '0.9rem',
        color: theme.colors.error['700'],
    },
    commonButton: {
        minWidth: 80,
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
    },
    cancelButton: {
        width: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const InvestorsIconFC: React.FC<{ color: string }> = ({ color }) => (
    <InvestorsIcon width={28} height={28} stroke={color} />
);

const CreateEditSharedWorkspaceModal: React.FC<{
    isOpen: boolean,
    workspaceId?: string,
    fields?: string[],
    onClose: () => void,
}> = ({ isOpen, workspaceId, fields, onClose }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { setWorkspace } = useContext(DashboardsContext);
    const { workspaces, setWorkspaces } = useContext(GroupSettingsContext);

    const [saving, setSaving] = useState<boolean>(false);
    const [saveReady, setSaveReady] = useState<boolean>(false);
    const [existing, setExisting] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    const nameRef = useRef<TextFieldProps>(null);

    const workspace = useMemo(() => workspaces.find(ws => ws.id === workspaceId), [workspaceId, workspaces]);

    const workspaceNames = useMemo(() =>
        workspaces.map(ws => ws.name.trim().toLowerCase())
    , [workspaces]);


    const handleSave = useCallback(() => {
        const name = (nameRef.current?.value as string || workspace?.name || '').trim();
        setSaving(true);
        if (!workspace) {
            if (!workspaceNames.includes(name.trim().toLowerCase())) {
                createWorkspaceFunc({ name, isPublic: true, isShared: true })
                    .then((updatedWorkspace?: Workspace) => {
                        setSaving(false);
                        if (!!updatedWorkspace?.id) {
                            setWorkspace(updatedWorkspace);
                            setWorkspaces(prev => [...prev, updatedWorkspace]);
                            enqueueSnackbar('Shared workspace created successfully', {
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                },
                                autoHideDuration: 4000,
                            });
                            onClose?.();
                        }
                    });
            } else {
                setExisting(true);
                setSaving(false);
            }
        } else {
            const filteredWorkspaceNames = workspaceNames.filter(wsName => wsName !== workspace?.name?.trim().toLowerCase());

            if ((!fields || fields?.includes('investor name')) && filteredWorkspaceNames.includes(name.trim().toLowerCase())) {
                setExisting(true);
                setSaving(false);
            } else {
                updateWorkspaceFunc({...workspace, name })
                    .then((updatedWorkspace?: Workspace) => {
                        setSaving(false);
                        if (!!updatedWorkspace?.id) {
                            setWorkspace(updatedWorkspace);
                            setWorkspaces(prev => prev.map(ws =>
                                ws.id === workspace.id! ? {...updatedWorkspace} : ws
                            ));
                            enqueueSnackbar('Investor updated successfully', {
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                },
                                autoHideDuration: 4000,
                            });
                            onClose();
                        }
                    });
            }
        }
    // eslint-disable-next-line
    }, [fields, workspace, workspaceNames]);

    const handleClear = useCallback(() => {
        if (nameRef.current)
            nameRef.current.value = '';
        setExisting(false);
    }, []);

    useEffect(() => {
        if (!!workspace && !loaded) 
            setLoaded(true);
    // eslint-disable-next-line
    }, [workspace]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={() => onClose()}>
            <DialogTitle className={classes.dialogTitle}>
                <InvestorsIconFC color="#7bd4d4" />
                <span>{`${workspace?.isShared ? 'Edit' : 'Create'} shared workspace`}</span>
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Stack direction="column" spacing={2} alignItems="stretch" justifyContent="space-between" width="100%">
                    {(!fields || fields?.includes('investor name')) && (
                        <Stack direction="row" spacing={1} alignItems="center" width="100%">
                            <Typography className={classes.label} width="85px">{'Name'}</Typography>
                            <TextField variant="outlined"
                                className={classes.inputField}
                                placeholder={'(required)'}
                                defaultValue={workspace?.name || ''}
                                onChange={() => {setExisting(false); setSaveReady(!!nameRef.current?.value);}}
                                inputRef={nameRef}
                                disabled={saving}
                                rows={1}
                                autoFocus />
                        </Stack>
                    )}
                </Stack>
                {existing && (
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" mt={4} mb={2}>
                        <ErrorOutlineIcon className={classes.existingIcon} />
                        <Typography className={classes.existingCaption}>
                            {'Investor already exists'}
                        </Typography>
                    </Stack>
                )}
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Button
                    variant="outlined"
                    className={classes.cancelButton}
                    onClick={() => onClose()}
                > {'Cancel'} </Button>
                {existing ? (
                    <Button
                        variant="outlined"
                        className={classes.commonButton}
                        onClick={handleClear}
                    > {'Clear'} </Button>
                ) : (
                    <LoadingButton
                        variant="contained"
                        className={classes.commonButton}
                        loading={saving}
                        disabled={!saveReady}
                        onClick={handleSave}
                    > {'Save'} </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    </>);
}

export default CreateEditSharedWorkspaceModal;