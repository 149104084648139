import { Box, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { VirtualItem } from "@tanstack/react-virtual";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { DashboardsContext, OverviewQueryTitles } from "../../../contexts/DashboardsContext";
import useBackwardsCompatible from "../../../hooks/useBackwardsCompatible";
import useDashboards from "../../../hooks/useDashboards";
import usePaginator from "../../../hooks/usePaginator";
import { getGroupDashboardQueriesFunc, refreshWorkspaceScoreFunc, updateWorkspaceFunc } from "../../../lib/helper";
import { scrollbarStyle } from "../../../shared/dashboard";
import { Dashboard, DashboardQuery, Workspace } from "../../../types/files";
import { collectionKey } from "../../organisms/dashboards/DashboardsView";
import { FallbackLoading } from "../../templates/loader";
import NoDashboardsFoundView from "../dashboards-empty/NoDashboardsFoundView";
import DashboardsTableHeader from "../dashboards-table/DashboardsTableHeader";
import DashboardsTableRow from "../dashboards-table/DashboardsTableRow";

const useStyles = makeStyles((theme) => ({
    container: {
        height: 'calc(100% - 30px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginTop: 20,
        ...scrollbarStyle,
    },
    headers: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        scrollBehavior: 'smooth',
        ...scrollbarStyle,
    },
    //
    table: {
        width: '100%',
        height: 'calc(100vh - 200px)',
        marginLeft: 12,
    },
    header: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        scrollBehavior: 'auto',
        borderBottom: `1px solid ${theme.colors.neutral['400']}`,
        ...scrollbarStyle,
    },
    rows: {
        position: 'relative',
        minWidth: '100%',
        width: 'fit-content',
        height: '100%',
        overflow: 'auto',
        scrollBehavior: 'smooth',
        ...scrollbarStyle,
    },
    row: {
        position: 'absolute',
        top: 0,
        left: 0,
        minWidth: '100%',
        width: 'fit-content',
        height: 'auto',
        borderTop: `1px solid ${theme.colors.neutral['400']}`,
        background: `#fff`,
        "&:hover": {
            background: `rgb(246, 251, 251, 0.8)`,
        },
    },
    loadingText: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 700,
        color: theme.colors.neutral['900'],
    },
    loadingTextSub: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: '#9e9e9e',
    },
    loading: {
        width: '100%',
        height: '100%',
        color: theme.palette.primary.main,
    },
    sharedTitle: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: '600',
        color: theme.colors.neutral['700'],
    },
}));

export const TableHeaders = ['Name', 'Fit score', 'Stage', 'Sectors', 'Location', '', 'Added', ''];

const SharedByMeV2: React.FC<{
    workspace?: Workspace,
    search?: string,
 }> = ({ workspace, search }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { mappedOverviewQueries, workspace: globalWorkspace, dashboards, isPublicView,
        setWorkspace, setDashboardsQueries } = useContext(DashboardsContext);
    const { filterDashboardList } = useDashboards();
    const { dashboardWorkspaces } = useBackwardsCompatible();

    const [loading, setLoading] = useState<boolean>(false);
    const [searching, setSearching] = useState<boolean>(false);
    const [dashboardArray, setDashboardArray] = useState<Dashboard[]>([]);
    const [sortBy, setSortBy] = useState<string>('Added');
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);    
    const virtualRowsRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);

    const localWorkspace = useMemo(() => workspace ?? globalWorkspace, [globalWorkspace, workspace]);

    const syncScroll = (e?: Event) => {
        e?.preventDefault();

        if (headerRef.current && virtualRowsRef.current) 
            headerRef.current.scrollLeft = virtualRowsRef.current.scrollLeft;
    };

    const getMembership = useCallback((dashboard: Dashboard) =>
        dashboard?.workspaceMemberships?.find(wsMem => wsMem.workspaceId === localWorkspace?.id)
    , [localWorkspace?.id]);

    const handleRefresh = useCallback((refresh?: boolean) => {
        if (!!localWorkspace) {
            const promises: Promise<any>[] = [];

            if (refresh)
                updateWorkspaceFunc(localWorkspace).then((updatedWorkspace?: Workspace) => setWorkspace({...updatedWorkspace!}));

            dashboards.forEach(dashboard => promises.push(
                refreshWorkspaceScoreFunc({
                    dashboardId: dashboard.id,
                    workspaceId: localWorkspace.id,
                    group: userGroup,
                })
            ));

            Promise.all(promises).then(() => {
                setSearching(true);
            });
        }
    // eslint-disable-next-line
    }, [dashboards, userGroup, localWorkspace]);

    const sortedDashboards = useMemo(() => {
        if (sortBy === 'Fit score') {
            return Array.from(new Map(
                [...dashboardArray].filter(dashboard => dashboardWorkspaces(dashboard)?.includes(localWorkspace!.id)).map(dashboard => {
                    const investmentThesis: { workspaceId: string, score: number}[]
                        = mappedOverviewQueries.get(`${dashboard.id}:Investment Thesis`) || [];
                    const score = investmentThesis?.find(iT => iT.workspaceId === localWorkspace!.id)?.score ?? 0;

                    return [dashboard, score];
                }))).sort((a, b) => b[1] - a[1]).map(entry => entry[0]);
        }

        // else sort by 'Added'
        return [...dashboardArray].sort((prev: Dashboard, next: Dashboard) => {
            const prevDate = getMembership(prev)?.updatedAt || prev.createdAt;
            const nextDate = getMembership(next)?.updatedAt || next.createdAt;

            return nextDate.localeCompare(prevDate);
        });
    // eslint-disable-next-line
    }, [dashboardArray, sortBy]);

    const { allRows, rowVirtualizer, hasNextPage } = usePaginator({
        key: 'shared-by-me-v2-153',
        source: sortedDashboards,
        limit: 20,
        itemHeight: 60,
        virtualRowsRef,
    });

    useEffect(() => {        
        const timeout = setTimeout(() => {
            clearTimeout(timeout);
            if (!headerRef.current || !virtualRowsRef.current) return;
    
            virtualRowsRef.current.addEventListener("scroll", syncScroll);
            headerRef.current.addEventListener("wheel", syncScroll, { passive: false });
            headerRef.current.addEventListener("touchmove", syncScroll, { passive: false });
        }, 1000);
    
        return () => {
            clearTimeout(timeout);
            if (!headerRef.current || !virtualRowsRef.current) return;
    
            // eslint-disable-next-line 
            virtualRowsRef?.current?.removeEventListener("scroll", syncScroll);
            headerRef?.current?.removeEventListener("wheel", syncScroll);
            // eslint-disable-next-line 
            headerRef?.current?.removeEventListener("touchmove", syncScroll);
        };
    }, []);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setDashboardArray(filterDashboardList(search || '', localWorkspace?.id));
            clearTimeout(timerRef.current);
        }, 500);

        return () => {
            clearTimeout(timerRef.current);
        }
    }, [filterDashboardList, localWorkspace?.id, search]);

    useEffect(() => {
        if (searching) {
            timerRef.current = setTimeout(() => {
                clearInterval(timerRef.current);
                getGroupDashboardQueriesFunc(userGroup).then((dashboardQueriesData) => {
                    setDashboardsQueries(dashboardQueriesData.filter((query: DashboardQuery) =>
                        OverviewQueryTitles.includes(query.title)
                    ));
                    setSearching(false);
                    setSortBy('Fit score');
                });
                timerRef.current = setTimeout(() => {
                    clearInterval(timerRef.current);
                    setSearching(false);
                    setSortBy('Fit score');
                }, 45000);
            }, 15000);
        }

        return () => {
            clearInterval(timerRef.current);
        }
    // eslint-disable-next-line
    }, [searching]);

    useEffect(() => {
        setLoading(!!timerRef.current && !dashboardArray && !search && !searching);
    }, [dashboardArray, search, searching]);

    if (!localWorkspace)
        return (<></>);

    return (<>
        {loading ? (<FallbackLoading />) : (<>
            {isPublicView && (<>
                <Typography className={classes.sharedTitle} pl={1} py={1}>
                    {`Deals shared:`}
                </Typography>
                <Divider sx={{ width: '100%' }} />
            </>)}
            <Stack className={classes.table}>
                {loading ? (<FallbackLoading />) : (searching) ? (
                    <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
                        <Typography className={classes.loadingText}>
                            {`Finding top matches for ${localWorkspace!.name}`}
                        </Typography>
                        <Stack alignItems="center" justifyContent="center" mt={2} mb={6}>
                            <Typography className={classes.loadingTextSub}>
                                {'All scores may take up to a minute to calculate'}
                            </Typography>
                        </Stack>
                        <CircularProgress className={classes.loading} size={60} thickness={3} />
                    </Stack>
                ) : (<>
                    {(!!sortedDashboards.length) ? (<>
                        <Stack alignItems="flex-start" justifyContent="flex-start" height="100%">
                            <Box width="100%" height="fit-content" overflow="hidden">
                                <Box className={classes.header} ref={headerRef}>
                                    <DashboardsTableHeader
                                        columns={TableHeaders}
                                        columnSorted={sortBy}
                                        onColumnSort={setSortBy}
                                        onRefreshScores={() => handleRefresh()}
                                        actions={['Remove from workspace']}
                                        xAdjust={80}
                                        noStatus multiAction />
                                </Box>
                            </Box>
                            <Box width="100%" height="100%" overflow="hidden">
                                <Box className={classes.rows} ref={virtualRowsRef} 
                                    sx={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                                    {rowVirtualizer.getVirtualItems().map((virtualRow: VirtualItem) => (
                                        <Box className={classes.row} 
                                            sx={{  height: `${virtualRow.size}px`, transform: `translateY(${virtualRow.start}px)`, }}
                                            key={'shared-by-me-v2-290-' + virtualRow.index} >
                                            {(virtualRow.index > allRows.length - 1) && hasNextPage 
                                                ? (<FallbackLoading />)
                                                : (<DashboardsTableRow
                                                        dashboard={allRows[virtualRow.index]}
                                                        workspace={workspace ?? undefined}
                                                        columns={TableHeaders}
                                                        collectionKey={collectionKey}
                                                        timestamp={getMembership(allRows[virtualRow.index])?.updatedAt}
                                                        noStatus isPublic />)}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Stack>
                    </>) : (<NoDashboardsFoundView noCompaniesYet={!search && !dashboardArray.length} noCompaniesFound />)}
                </>)}
            </Stack>
        </>)}
    </>);
}

export default SharedByMeV2;