import { useCallback, useEffect, useState } from "react";
import { TabContext } from "@mui/lab";
import { DialogTitle, Divider } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import useBulkDashboards from "../../../hooks/useBulkDashboards";
import useBulkWorkspaces from "../../../hooks/useBulkWorkspaces";
import BulkShareDeals, { BulkShareCuratedTabs } from "../../atoms/[legacy]/home-bulk-share-deals";

const useStyles = makeStyles(() => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: 'unset',
            maxHeight: 'unset',
            width: 'fit-content',
            height: 'fit-content',
            padding: '16px 24px',
            background: 'white',
            borderRadius: 24,
            overflow: 'hidden',
        },
    },
    dialogTitle: {
        display: 'flex',
        width: 'auto',
        height: 'auto',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.5rem',
    },
}));

const BulkShareDealsModal: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {
    const classes = useStyles();
    const {clearBulk: clearDashboards} = useBulkDashboards();
    const {clearBulk: clearWorkspaces} = useBulkWorkspaces();

    const [selectedTab, setSelectedTab] = useState<BulkShareCuratedTabs>(BulkShareCuratedTabs.Selection);
    const [bulkContext, setBulkContext] = useState<BulkShareCuratedTabs>(BulkShareCuratedTabs.Selection);

    const handleClose = useCallback(() => {
        clearDashboards();
        clearWorkspaces();
        onClose();
    }, [clearDashboards, clearWorkspaces, onClose]);

    useEffect(() => {
        if (selectedTab === BulkShareCuratedTabs.Close)
            handleClose();
    // eslint-disable-next-line
    }, [selectedTab]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={handleClose}>
            {selectedTab !== BulkShareCuratedTabs.Done && (<>
                <DialogTitle className={classes.dialogTitle}>
                    {'Share deals'}
                </DialogTitle>
                <Divider sx={{ margin: '8px 0'}} />
            </>)}
            <TabContext value={selectedTab}>
                <BulkShareDeals.Select onSelect={(selection) => {
                    setBulkContext(selection);
                    setSelectedTab(selection);
                }} />
                <BulkShareDeals.Companies onSelect={setSelectedTab} />
                <BulkShareDeals.Investors onSelect={setSelectedTab} />
                <BulkShareDeals.Stepper bulkContext={bulkContext} onSelect={setSelectedTab} />
                <BulkShareDeals.ConfirmAll bulkContext={bulkContext} onSelect={setSelectedTab} />
                <BulkShareDeals.Done onSelect={setSelectedTab} />
            </TabContext>
        </Dialog>
    </>);
}

export default BulkShareDealsModal;