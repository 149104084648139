import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FC, useCallback, useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import { deleteWorkspaceFunc, updateCurrentUserWorkspaceUserMembershipFunc } from "../../../lib/helper";
import theme from "../../../theme";
import { Workspace } from "../../../types/files";
import ConfirmDialog from "../../modals/ConfirmDialog";
import CreateEditInvestorModal from "../../modals/investors/CreateEditInvestorModal";
import CreateEditSharedWorkspaceModal from "../../modals/investors/CreateEditSharedWorkspaceModal";
import { WorkspaceUserMembershipStatus } from '../../../types/common';

const useStyles = makeStyles((theme) => ({
    icon: {
        "& > svg": {
            width: 24,
            height: 24,
        },
    },
    liveIcon: {
        padding: 'unset',
        color: theme.colors.neutral['600'],
        "& > svg": {
            width: 24,
            height: 24,
        },
    },
}));

const WorkspaceMenu: FC<{ workspace: Workspace, forLive?: boolean, isOwned?: boolean }> = ({ workspace, forLive, isOwned }) => {
    const classes = useStyles();
    const history = useHistory();
    const params: {[key: string]: string} = useParams();
    const { setWorkspaces, setSharedWorkspaces } = useContext(GroupSettingsContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
    const [deleteInvestorLoading, setDeleteInvestorLoading] = useState<boolean>(false);
    const [createInvestorOpen, setCreateInvestorOpen] = useState<boolean>(false);

    const openDeleteDialog = useCallback(() => {
        setConfirmDeleteOpen(true);
        setAnchorEl(null);
    }, []);

    const closeDeleteDialog = useCallback(() => {
        setDeleteInvestorLoading(false);
        setConfirmDeleteOpen(false);
    }, []);

    const handleDelete = useCallback(() => {
        setDeleteInvestorLoading(true);
        deleteWorkspaceFunc({ id: workspace.id }).then(() => {
            setWorkspaces(prev => prev.filter(ws => ws.id !== workspace.id));
            setDeleteInvestorLoading(false);
            closeDeleteDialog();

            if (params.workspaceId === workspace.id)
                history.push('/dashboards');
        });
    // eslint-disable-next-line
    }, [params.workspaceId]);

    const handleLeave = useCallback(async () => {
        setDeleteInvestorLoading(true);
        updateCurrentUserWorkspaceUserMembershipFunc({
            workspaceId: workspace.id, 
            status: WorkspaceUserMembershipStatus.REJECTED
        }).then(() => {
            setSharedWorkspaces(sharedWs => sharedWs.filter(ws => ws.id !== workspace.id));
            setDeleteInvestorLoading(false);
            closeDeleteDialog();

            if (params.workspaceId === workspace.id)
                history.push('/dashboards');
        });
    // eslint-disable-next-line
    }, [params.workspaceId]);

    return (<>
        <Box onClick={(e) => e.stopPropagation()}>
            <IconButton className={forLive ? classes.liveIcon : classes.icon}
                onClick={(e) => setAnchorEl(e.currentTarget)} disableRipple>
                <MoreVertIcon />
            </IconButton>
            <Menu open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: forLive ? 'center': 'left' }}
                onClose={() => setAnchorEl(null)}>
                {(!forLive && isOwned)&& (
                    <MenuItem onClick={() => {
                        setAnchorEl(null);
                        setCreateInvestorOpen(true);}}>
                        {`Edit ${workspace.isShared ? 'workspace name': 'investor'}`}
                    </MenuItem>
                )}
                <MenuItem onClick={openDeleteDialog}
                    sx={{ color: theme.colors.error['500']}}>
                    {isOwned ? 'Delete' : 'Leave workspace'}
                </MenuItem>
            </Menu>
            {confirmDeleteOpen && (
                <ConfirmDialog
                    title={`${isOwned ? 'Delete' : ' Leave'} confirmation`}
                    content={`Are you sure you want to ${isOwned ? 'delete' : ' leave'} this ${isOwned ? 'investor' : 'workspace'}?`}
                    confirmLabel={`${isOwned ? 'Delete' : ' Leave'}`}
                    open={confirmDeleteOpen}
                    loading={deleteInvestorLoading}
                    confirmCallback={isOwned ? handleDelete : handleLeave}
                    cancelCallback={closeDeleteDialog}
                />
            )}
            {createInvestorOpen && (
                workspace.isShared ? (
                    <CreateEditSharedWorkspaceModal
                        isOpen={createInvestorOpen}
                        workspaceId={workspace.id}
                        onClose={() => setCreateInvestorOpen(false)}
                />) : (
                    <CreateEditInvestorModal
                        isOpen={createInvestorOpen}
                        workspaceId={workspace.id}
                        onClose={() => setCreateInvestorOpen(false)}
                />)
            )}
        </Box>
    </>);
}

export default WorkspaceMenu;