import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Button, Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useState } from "react";
import { ReactComponent as InvestorsIcon } from "../../assets/icons/investors.svg";
import theme from "../../theme";
import BulkAddWorkspacesModal from "../modals/home/BulkAddWorkspacesModal";
import CreateEditInvestorModal from "../modals/investors/CreateEditInvestorModal";
import CreateEditSharedWorkspaceModal from "../modals/investors/CreateEditSharedWorkspaceModal";
import GlobalPreferencesModal from '../modals/investors/GlobalPreferencesModal';

const useStyles = makeStyles((theme) => ({
    createButton: {
        width: 'auto',
        height: 40,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    label: {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 500,
        color: 'black',
    },
    // sublabel: {
    //     marginLeft: 4,
    //     fontFamily: 'Lato',
    //     fontSize: '1rem',
    //     fontWeight: 500,
    //     color: theme.colors.neutral['500'],
    // },
}));

const InvestorsIconFC: React.FC<{ color: string }> = ({ color }) => (
    <InvestorsIcon width={24} height={24} stroke={color} />
);

const AddInvestorPerson: React.FC<{flags?: any}> = ({flags}) => {
    const classes = useStyles();
    const [createInvestorModalOpen, setCreateInvestorModalOpen] = useState<boolean>(false);
    const [createSharedWorkspaceModalOpen, setCreateSharedWorkspaceModalOpen] = useState<boolean>(false);
    const [bulkAddWorkspaceModalOpen, setBulkAddWorkspaceModalOpen] = useState<boolean>(false);
    const [preferencesModalOpen, setPreferencesModalOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    return (<>
        <Box>
            <Button variant="outlined"
                className={classes.createButton}
                endIcon={<ArrowDropDownIcon sx={{ fill: theme.palette.primary.main, transform: 'scale(1, 2)'}} />}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setAnchorEl(e.currentTarget);
                }}> {'Actions'} </Button>
            <Menu open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => setAnchorEl(null)}
                sx={{ '& > .MuiPaper-root': {borderRadius: 3} }}>
                <MenuItem  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setAnchorEl(null);
                    setCreateInvestorModalOpen(true);
                }}> <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                        <PersonOutlineOutlinedIcon sx={{ width: 24, height: 24, fill: "#7bd4d4" }} />
                        <Typography className={classes.label}> {'Add an investor'} </Typography>
                    </Stack>
                </MenuItem>
                {!flags.sharedWorkspaces ? (
                    <Tooltip placement="top-start" title={
                        <Typography component="span" fontSize="1rem" fontWeight={500}>
                            {`To create shared workspaces, please`}<br/>
                            {`subscribe to the Pro plan `}
                            <Typography
                                component="a"
                                href="/subscriptions"
                                target="_blank"
                                rel="noopener noreferrer"
                                display="inline"
                                sx={{
                                    color: '#fff',
                                    fontSize: '1rem',
                                    fontWeight: 'bold',
                                    textDecoration: 'underline',
                                    "&:hover": {
                                        textDecoration: 'underline !important',
                                    }
                                }}
                            >here</Typography>.
                        </Typography>}
                        componentsProps={{tooltip:{sx:{
                            display: 'flex', alignItems: 'center', borderRadius: '12px', 
                            backgroundColor: 'rgba(150, 150, 150)', width: '300px', height: '70px',  padding: 2,
                        }}}}>
                        <MenuItem sx={{ cursor: 'auto'}}> 
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                                <Box>
                                    <InvestorsIconFC color="#7bd4d4" />
                                </Box>
                                <Typography className={classes.label}> {'Create a shared workspace'} </Typography>
                            </Stack>
                        </MenuItem>
                    </Tooltip>
                ) : (
                    <MenuItem onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setAnchorEl(null);
                        setCreateSharedWorkspaceModalOpen(true);
                    }}> <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                            <Box>
                                <InvestorsIconFC color="#7bd4d4" />
                            </Box>
                            <Typography className={classes.label}> {'Create a shared workspace'} </Typography>
                        </Stack>
                    </MenuItem>
                )}
                <MenuItem onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setAnchorEl(null);
                    setPreferencesModalOpen(true);
                }}> <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                        <TuneIcon sx={{ color: "#7bd4d4" }} />
                        <Typography className={classes.label}> {'Update my deal preferences'} </Typography>
                    </Stack>
                </MenuItem>
                {/* <MenuItem className={classes.label}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setAnchorEl(null);
                        setBulkAddWorkspaceModalOpen(true);
                    }}> {'List of investors'}
                    <Typography className={classes.sublabel} display="inline">
                        {'(via CSV)'}
                    </Typography>
                </MenuItem> */}
            </Menu>
        </Box>
        {createInvestorModalOpen && (
            <CreateEditInvestorModal
                isOpen={createInvestorModalOpen}
                onClose={() => {
                    setCreateInvestorModalOpen(false);
                }}
            />
        )}
        {preferencesModalOpen &&  (
            <GlobalPreferencesModal
                isOpen={preferencesModalOpen}
                onClose={() => setPreferencesModalOpen(false)}
            />
        )}
        {createSharedWorkspaceModalOpen && (
            <CreateEditSharedWorkspaceModal
                isOpen={createSharedWorkspaceModalOpen}
                onClose={() => {
                    setCreateSharedWorkspaceModalOpen(false);
                }}
            />
        )}
        <BulkAddWorkspacesModal
            isOpen={bulkAddWorkspaceModalOpen}
            onClose={() => setBulkAddWorkspaceModalOpen(false)}
        />
    </>);
}

export default withLDConsumer()(AddInvestorPerson);