import { useMemo } from "react";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import useBulkDashboards from "../../../hooks/useBulkDashboards";
import { scrollbarStyle } from "../../../shared/dashboard";
import { Dashboard, Workspace } from "../../../types/files";
import configuredAsyncComponentLoader, { FallbackLoading } from "../../templates/loader";
import NoDashboardsFoundView from "../dashboards-empty/NoDashboardsFoundView";
import DashboardsTableHeader, { TableHeaders } from "../dashboards-table/DashboardsTableHeader";
import DashboardsTableRow from "../dashboards-table/DashboardsTableRow";
import useExportCopy from "../../../hooks/useExportCopy";

const DashboardCardMatchesList = configuredAsyncComponentLoader(
    () => import('../../atoms/dashboards/DashboardCardMatchesList'),
    { fallback: <FallbackLoading height={`calc(100vh - 250px)`} />}
);

const useStyles = makeStyles((theme) => ({
    scrollable: {
        width: '100%',
        height: '100%',
        marginBottom: '16px !important',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    table: {
        width: '100%',
        height: 'calc(100vh - 240px)',
        overflow: 'hidden',
        marginLeft: 12,
    },
    headers: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        ...scrollbarStyle,
    },
    rows: {
        minWidth: '100%',
        width: 'fit-content',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    loadingText: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 700,
        color: theme.colors.neutral['900'],
    },
    loadingTextSub: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: '#9e9e9e',
    },
    loading: {
        width: '100%',
        height: '100%',
        color: theme.palette.primary.main,
    },
    commonButton: {
        minWidth: 100,
        height: 36,
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
    },
}));

const MatchResult: React.FC<{
    workspace?: Workspace,
    dashboards: Dashboard[],
    collectionKey?: string,
    display?: boolean,
    loading?: boolean,
    searching?: boolean,
    bulkSelect?: boolean,
}> = ({ workspace, dashboards, collectionKey, display, loading, searching, bulkSelect }) => {
    const classes = useStyles();
    const { loading: copyLoading, getBulkDashboards, assignToWorkspaces } = useBulkDashboards();
    const { copyBulkDashboards } = useExportCopy();

    const height = `calc(100% - ${bulkSelect ? 140 : 100}px)`;

    const hasSelection = useMemo(() => !!getBulkDashboards(collectionKey || '').length, [collectionKey, getBulkDashboards]);

    if (!workspace)
        return (<></>);

    return (<>
        {loading ? (<FallbackLoading />) : (searching) ? (
            <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
                <Typography className={classes.loadingText}>
                    {`Finding top matches for ${workspace.name}`}
                </Typography>
                <Stack alignItems="center" justifyContent="center" mt={2} mb={6}>
                    <Typography className={classes.loadingTextSub}>
                        {'All scores may take up to a minute to calculate'}
                    </Typography>
                </Stack>
                <CircularProgress className={classes.loading} size={120} thickness={2} />
            </Stack>
        ) : (!!collectionKey) ? (<>
            <Stack width="100%" height={height}>
                <Stack direction="column" className={classes.scrollable} spacing={1} alignItems="flex-start" justifyContent="stretch">
                    <DashboardCardMatchesList
                        workspace={workspace}
                        dashboards={dashboards}
                        collectionKey={collectionKey}
                        display={display} />
                </Stack>
            </Stack>
            {bulkSelect && (
                <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" px={1}>
                    <Button variant="contained"
                        className={classes.commonButton}
                        disabled={!hasSelection}
                        onClick={async (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            await copyBulkDashboards(collectionKey || '', ['Description', 'Location']);
                    }}>{'Copy snippets'}</Button>
                    <LoadingButton variant="contained"
                        className={classes.commonButton}
                        loading={copyLoading}
                        disabled={!hasSelection}
                        onClick={async (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            await assignToWorkspaces(collectionKey || '', [workspace.id]);
                    }}>{'Save'}</LoadingButton>
                </Stack>
            )}
        </>) : (<>
            <Stack className={classes.table}>
                {(display && !!dashboards.length) ? (<>
                    <Stack className={classes.headers} alignItems="flex-start" justifyContent="flex-start">
                        <DashboardsTableHeader columns={TableHeaders} />
                        <Stack className={classes.rows} alignItems="flex-start" justifyContent="flex-start">
                            {dashboards.map(dashboard => (
                                <DashboardsTableRow
                                    dashboard={dashboard}
                                    workspace={workspace ?? undefined}
                                    columns={TableHeaders}
                                    isPublic
                                    key={'match-result-147-' + dashboard.id} />
                            ))}
                        </Stack>
                    </Stack>
                </>) : (<NoDashboardsFoundView noCompaniesFound />)}
            </Stack>
        </>)}
    </>);
}

export default MatchResult;