import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useMemo, useRef, useState } from "react";
import useBulkWorkspaces from '../../../hooks/useBulkWorkspaces';
import { Dashboard } from "../../../types/files";
import configuredAsyncComponentLoader from "../../templates/loader";

const FoundInvestors = configuredAsyncComponentLoader(() => import('./FoundInvestors'));
const CopyDealSnippets = configuredAsyncComponentLoader(() => import('./CopyDealSnippets'));

const useStyles = makeStyles((theme) => ({
    accordion: {
        width: '100%',
        height: 'fit-content',
        background: '#fff',
        borderBottom: `1px solid ${theme.colors.neutral['400']}`,
        "&.Mui-expanded": {
            margin: '8px 0',
        },
        "&:before": {
            display: 'none',
        },
        "& > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner": {
            height: 640,
            "& > .MuiAccordion-region": {
                height: '100% !important',
            }
        },
    },
    summary: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontFamily: 'Inter',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: 'black',
        padding: '0 12px',
        gap: 8,
        "&.Mui-expanded": {
            minHeight: 'unset',
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: 'unset',
        }
    },
    investors: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['500'],
        paddingLeft: 16,
    },
}));

const FindInvestorsShareDeal: React.FC<{
    dashboard: Dashboard,
    isExpandable?: boolean,
}> = ({ dashboard, isExpandable }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState<boolean>(false);
    const { getBulkWorkspaces } = useBulkWorkspaces();
    const accordionRef = useRef<HTMLDivElement | null>(null);

    const collectionKey = useMemo(() => `dashboard[${dashboard.id}]`, [dashboard]);

    const count = useMemo(() => getBulkWorkspaces(collectionKey).length, [collectionKey, getBulkWorkspaces]);

    useEffect(() => {
        setExpanded(!!isExpandable);
    }, [isExpandable]);

    useEffect(() => {
        if (expanded && !!accordionRef.current) {
          accordionRef.current?.scrollIntoView({
            behavior: "smooth", block: "nearest",
          });
        }
    }, [expanded]);

    if (!dashboard)
        return (<></>);

    return (<>
        <Accordion className={classes.accordion} elevation={0} expanded={expanded}
            ref={(el) => (accordionRef.current = el)}>
            <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon />}
                onClick={() => setExpanded(prev => isExpandable ? !prev : false)}
                disabled={!isExpandable}>
                <span>{dashboard.title}</span>
                {!!count && (
                    <Typography component="span" className={classes.investors} display="inline">
                        {`${count} investor${count > 1 ? 's': ''} selected`}
                    </Typography>
                )}
            </AccordionSummary>
            <AccordionDetails sx={{ height: '100%' }}>
                <Stack direction="row" alignItems="flex-start" justifyContent="space-between"
                    width="100%" height="100%" overflow="hidden">
                    <Stack width="50%" height="100%" px={2}>
                        <FoundInvestors dashboards={[dashboard]} collectionKey={collectionKey} />
                    </Stack>
                    <Divider orientation="vertical" sx={{ margin: '8px 0'}} />
                    <Stack width="50%" height="100%" px={2}>
                        <CopyDealSnippets dashboards={[dashboard]} />
                    </Stack>
                </Stack>
            </AccordionDetails>
        </Accordion>
    </>);
}

export default FindInvestorsShareDeal;