import FolderIcon from '@mui/icons-material/Folder';
import { Button, Divider, Tooltip, Typography, Box as MBox, Stack, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { debounce } from "lodash";
import queryString from 'query-string';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import awsConfig from "../../aws-exports";
import { AuthContext } from "../../contexts/AuthContext";
import { Box, Dropbox, Google, Microsoft } from '../../helpers/constants';
import { createIntegrationFunc, createOauth2CredsFunc, getIntegrationsFunc, runFileSyncFunc, updateIntegrationFunc } from '../../lib/helper';
import { DropboxFolderDrive, IntegrationSelectionOptions, IntegrationState, integration, integrationStatus } from "../../types/integrations";
import InfoIcon from '@mui/icons-material/Info';
import IntegrationSelectionModal from "../modals/integration/IntegrationSelectionModal";
import { useTheme } from '@mui/styles';
import { Info } from '@mui/icons-material';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { scrollbarStyle } from '../../shared/dashboard';
import { FallbackLoading } from '../templates/loader';
import ProPlanModal from '../modals/subscriptions/ProPlanModal';

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        height: '100%',
        maxWidth: 'unset !important',
        overflowY: 'auto',
        ...scrollbarStyle,
    }, header: {
        color: theme.palette.text.primary,
    },
    paperContainer: {
        padding: 20,
        borderRadius: 10,
        boxShadow: theme.shadows[4],
        position: "relative"
    },
    groupDriveIcon: {
        width: 25,
        height: 25,
        backgroundColor: "#3C78D8",
        color: "#fff",
        textAlign: "center"
    },
    driveConnectionCheck: {
        position: "absolute",
        top: 5,
        right: 5,
        color: "#00B96C",
        fontSize: 35
    },
    driveConnectionError: {
        position: "absolute",
        top: 5,
        right: 5,
        color: "#f00",
        fontSize: 35
    },
    reconnectButton: {
        backgroundColor: "#FE9801"
    },
    folderName: {
        /* Standard CSS ellipsis */
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        /* Beginning of string */
        direction: "rtl",
        textAlign: "left"
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        gap: 4
    },
    disconnectButton: {
        borderColor: theme.colors.primary["600"],
        borderRadius: "40px",
        color: theme.colors.primary["600"],
        height: "40px",
        marginLeft: "8px",
        textTransform: "capitalize",
        width: '127px',
        '&:hover': {
            colors: theme.colors.primary['500'],
        }
    },
    divider: {
        border: '1px solid',
        borderColor: theme.colors.neutral["200"],
        marginTop: '24px',
    },
    folderIcon: {
        color: theme.colors.neutral["600"],
        height: '20px',
        width: '20px',
    },
    folderURL: {
        color: theme.colors.neutral["700"],
        marginLeft: '5.67px'
    },
    iconStyle: {
        height: '64px',
        width: '64px',
    },
    infoIcon: {
        color: theme.colors.primary["400"],
        height: '24px',
        width: '24px',
    },
    fullWidthIntegrationCard: {
        backgroundColor: theme.colors.neutral['50'],
        borderRadius: '8px',
        width: '100%',
    },
    integrationCard: {
        backgroundColor: theme.colors.neutral['50'],
        borderRadius: '8px',
        height: '258px',
        padding: '24px',
        width: '472px',
    },
    subheader: {
        color: theme.colors.neutral["700"],
        margin: '8px 0',
    },
    connectButton: {
        background: theme.colors.primary['600'],
        color: 'white',
        borderRadius: '40px',
        textTransform: "capitalize",
        height: "40px",
        marginLeft: "8px",
        width: '127px',
        '&:hover': {
            background: theme.colors.primary['500']
        }
    },
    textHeader: {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '1rem',
        fontFamily: 'Inter',
    },
    textBody: {
        color: '#4A5056',
        fontSize: '0.9rem',
        fontFamily: 'Inter',
    },
}));

const features = {
    popup: "yes",
    width: 600,
    height: 700,
    top: "auto",
    left: "auto",
    toolbar: "no",
    menubar: "no",
};

const strWindowsFeatures = Object.entries(features)
    .reduce((str, [key, value]) => {
        if (value === "auto") {
            if (key === "top") {
                const v = Math.round(
                    window.innerHeight / 2 - features.height / 2
                );
                str += `top=${v},`;
            } else if (key === "left") {
                const v = Math.round(
                    window.innerWidth / 2 - features.width / 2
                );
                str += `left=${v},`;
            }
            return str;
        }

        str += `${key}=${value},`;
        return str;
    }, "")
    .slice(0, -1); // remove last ',' (comma)

const IntegrationPage: React.FC<{ flags?: any }> = ({ flags }) => {
    const classes = useStyles();
    const { search } = useLocation();
    const theme = useTheme();
    const { userGroup } = useContext(AuthContext);

    const [integrations, setIntegrations] = useState<IntegrationState[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [integrationSelectionOptions, setIntegrationSelectionOptions] = useState<IntegrationSelectionOptions | undefined>();
    const [integrationSelectionModalOpen, setIntegrationSelectionModalOpen] = useState<boolean>(false);
    const [integrationSelectionModalLoading, setIntegrationSelectionModalLoading] = useState<boolean>(false);
    const [integrationInModal, setIntegrationInModal] = useState<integration | undefined>();

    const parsedQueryString = queryString.parse(search);
    let oauthState = '';

    useEffect(() => {
        if (userGroup) {
            setLoading(true);
            getIntegrationsFunc(userGroup).then(
                (data: any[]) => {
                    setIntegrations(data);

                    setLoading(false);
                },
                () => {
                    setLoading(false);
                });
        }
    }, [userGroup]);

    useEffect(() => {
        if (parsedQueryString?.code) {
            const code = parsedQueryString?.code as string;
            const state = parsedQueryString?.state as string;
            window.opener.postMessage(JSON.stringify({ code, state }));
            window.close();
        } else if (parsedQueryString?.lc) {
            window.close();
        }
    }, [parsedQueryString.code, parsedQueryString.state, parsedQueryString.lc]);

    const receiveMessage = async (event: MessageEvent) => {
        if (event.origin !== window.location.origin) {
            console.warn(`Message received by ${event.origin}; IGNORED.`);
            return;
        }

        window.removeEventListener("message", receiveMessage);

        if (event.data) {
            let code, state;
            try {
                const parsed = JSON.parse(event.data);
                code = parsed.code;
                state = parsed.state;
            } catch (e) {
                /* Ignore event.data if it is not valid json string */
                return;
            }

            if (code && state && state !== decodeURIComponent(oauthState)) {
                console.warn('Possible cross-site forgery attempt. IGNORED.');
                return;
            }

            const { integration: integrationToConnect } = JSON.parse(decodeURIComponent(state));

            debounceInitiateIntegrationConnection(code, integrationToConnect);
        }
    };

    const initiateIntegrationConnection = async (code: string, integrationToConnect: integration) => {
        if (integrationSelectionModalOpen) {
            return;
        }

        setIntegrationInModal(integrationToConnect);
        setIntegrationSelectionModalOpen(true);
        setIntegrationSelectionModalLoading(true);

        let result: any;
        try {
            const params = Object.assign(
                { group: userGroup, code, integration: integrationToConnect },
                window.location.href.includes('localhost') && { redirectUri: window.location.href }
            );
            result = await createOauth2CredsFunc(params);
        } catch (err) {
            setIntegrationSelectionModalLoading(false);
            setIntegrationSelectionModalOpen(false);

            console.error('There was an error connecting the integration', { err, integrationToConnect });
            return;
        }

        setIntegrationSelectionModalLoading(false);
        setIntegrationSelectionOptions(result?.data?.createOauth2Creds);
        // setIntegrationSelectionOptions(result?.createOauth2Creds);
    }

    // eslint-disable-next-line
    const debounceInitiateIntegrationConnection = useCallback(
        debounce(initiateIntegrationConnection, 1000, { maxWait: 1500 }),
        // function required as dependency
        [initiateIntegrationConnection]
    );

    const getAuthorizeUrl = ({ integrationToConnect }: { integrationToConnect: integration }) => {
        oauthState = encodeURIComponent(JSON.stringify({
            id: uuidv4(),
            integration: integrationToConnect,
        }));

        const redirectUri = (awsConfig.app_domain ? `https://${awsConfig.app_domain}` :
            'http://localhost:3000') + '/integrations';

        // @ts-ignore
        if (integrationToConnect === 'Zapier') {
            return awsConfig.environment === 'app' ? 'https://zapier.com/developer/public-invite/193840/536f2027c563e0cdd950797432468df3/' :
                'https://zapier.com/developer/public-invite/191305/efe9f454a86b98f257bb0db81b871628/';
        }

        return {
            [integration.BOX]: `https://account.box.com/api/oauth2/authorize?response_type=code&client_id=${Box.clientId}&redirect_uri=${redirectUri}&state=${oauthState}`,
            [integration.DROPBOX]: `https://www.dropbox.com/oauth2/authorize?client_id=${Dropbox.clientId}&redirect_uri=${redirectUri}&response_type=code&token_access_type=offline&state=${oauthState}`,
            [integration.MICROSOFT]: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${Microsoft.clientId}&scope=${Microsoft.scope}&response_type=code&redirect_uri=${redirectUri}&state=${oauthState}`,
            [integration.GOOGLE]: `https://accounts.google.com/o/oauth2/auth?client_id=${Google.clientId}&redirect_uri=${redirectUri}&scope=${Google.scope}&response_type=code&access_type=offline&prompt=consent&state=${oauthState}`,

        }[integrationToConnect];
    };

    const authorizeIntegration = async ({ integrationToConnect }: { integrationToConnect: integration }) => {
        window.addEventListener("message", receiveMessage, false);
        window.open(
            getAuthorizeUrl({ integrationToConnect }),
            '_blank',
            strWindowsFeatures
        );
    };

    const disconnectIntegration = async ({ integrationToDisconnect }: { integrationToDisconnect: integration }) => {
        const existingIntegration = integrations.find(x => x.integration === integrationToDisconnect);

        if (existingIntegration) {
            await updateIntegrationFunc({
                ...existingIntegration,
                status: integrationStatus.DISCONNECTED,
            });

            setIntegrations(integrations.map(x => {
                if (x.integration === integrationToDisconnect) {
                    return {
                        ...x,
                        status: integrationStatus.DISCONNECTED,
                    };
                }

                return x;
            }));
        }
    };

    const disconnectOneDrive = async () => {
        const existingIntegration = integrations.find(x => x.integration === integration.MICROSOFT);

        if (existingIntegration) {
            await disconnectIntegration({ integrationToDisconnect: integration.MICROSOFT });

            //logout
            const redirectUri = (awsConfig.app_domain ? `https://${awsConfig.app_domain}` :
                'http://localhost:3000') + '/integrations';
            window.open(
                `https://login.live.com/oauth20_logout.srf?client_id=${Microsoft.clientId}&redirect_uri=${redirectUri}`,
                '_blank',
                strWindowsFeatures
            );
        }
    }

    const saveIntegrationConnection = async (value: DropboxFolderDrive, integrationToConnect: integration) => {
        setIntegrationSelectionModalOpen(false);

        const existingIntegration = integrations.find(x => x.integration === integrationToConnect);
        const params = {
            group: userGroup,
            integration: integrationToConnect,
            externalConfig: JSON.stringify(value),
            status: integrationStatus.CONNECTED,
        };

        if (existingIntegration) {
            await updateIntegrationFunc(params);

            setIntegrations(integrations.map(x => {
                if (x.integration === integrationToConnect) {
                    x = { ...x, ...params };
                }
                return x;
            }));
        } else {
            await createIntegrationFunc(params);

            setIntegrations([...integrations, params])
        }

        setIntegrationInModal(undefined);
        // intentional fire-and-forget. Syncs are a background process and we do not want to block the UI waiting for one to finish
        runFileSyncFunc(userGroup);
    }

    const getFolderDisplay = ({ integrationToConnect }: { integrationToConnect: integration }) => {
        const currIntegration = integrations.find(x => x.integration === integrationToConnect);

        if (!currIntegration || currIntegration.status === integrationStatus.DISCONNECTED) {
            return <></>;
        }

        const externalConfig = JSON.parse(currIntegration.externalConfig);
        let folderName = externalConfig.path.replace(/^\//, "").split("/").join(" / ");

        if (integrationToConnect === integration.MICROSOFT) {
            const driveIdEndsAt = externalConfig.path.indexOf('/root') + 5;
            folderName = (`${externalConfig.rootFolderName} / ` || '') + externalConfig.path.slice(driveIdEndsAt).replace(/^\//, "").split("/").join(" / ");
        }

        const folderNameWithEllipsis = `...${folderName}`;

        return (
            <MBox sx={{ alignItems: "center", display: "flex", marginTop: "8px" }}>
                <FolderIcon className={classes.folderIcon} />
                <Typography className={classes.folderURL} variant="b2-regular">
                    <Tooltip title={folderName} placement="top">
                        <Typography variant="b2-regular" className={classes.folderName}>
                            {folderName.length > 20 ? folderNameWithEllipsis : folderName}
                        </Typography>
                    </Tooltip>
                </Typography>
            </MBox>
        );
    };


    const integrationCards = [
        {
            icon: 'zapier',
            isConnected: integrations.some(x => x.integration === 'Zapier' as any && x.status === integrationStatus.CONNECTED),
            isError: integrations.some(x => x.integration === 'Zapier' as any && x.status === integrationStatus.ERROR),
            connect: () => { authorizeIntegration({ integrationToConnect: 'Zapier' as any }) },
            disconnect: () => { disconnectIntegration({ integrationToDisconnect: 'Zapier' as any }) },
            folderDisplay: () => getFolderDisplay({ integrationToConnect: 'Zapier' as any }),
            name: 'Zapier'
        },
        {
            icon: 'dropbox',
            isConnected: integrations.some(x => x.integration === integration.DROPBOX && x.status === integrationStatus.CONNECTED),
            isError: integrations.some(x => x.integration === integration.DROPBOX && x.status === integrationStatus.ERROR),
            connect: () => { authorizeIntegration({ integrationToConnect: integration.DROPBOX }) },
            disconnect: () => { disconnectIntegration({ integrationToDisconnect: integration.DROPBOX }) },
            folderDisplay: () => getFolderDisplay({ integrationToConnect: integration.DROPBOX }),
            name: 'Dropbox'
        },
        {
            icon: 'google-drive',
            isConnected: integrations.some(x => x.integration === integration.GOOGLE && x.status === integrationStatus.CONNECTED),
            isError: integrations.some(x => x.integration === integration.GOOGLE && x.status === integrationStatus.ERROR),
            connect: () => { authorizeIntegration({ integrationToConnect: integration.GOOGLE }) },
            disconnect: () => { disconnectIntegration({ integrationToDisconnect: integration.GOOGLE }) },
            folderDisplay: () => getFolderDisplay({ integrationToConnect: integration.GOOGLE }),
            name: 'Google Drive'
        },
        {
            icon: 'one-drive',
            isConnected: integrations.some(x => x.integration === integration.MICROSOFT && x.status === integrationStatus.CONNECTED),
            isError: integrations.some(x => x.integration === integration.MICROSOFT && x.status === integrationStatus.ERROR),
            connect: () => { authorizeIntegration({ integrationToConnect: integration.MICROSOFT }) },
            disconnect: disconnectOneDrive,
            folderDisplay: () => getFolderDisplay({ integrationToConnect: integration.MICROSOFT }),
            name: 'OneDrive'
        },
        {
            icon: 'box',
            isConnected: integrations.some(x => x.integration === integration.BOX && x.status === integrationStatus.CONNECTED),
            isError: integrations.some(x => x.integration === integration.BOX && x.status === integrationStatus.ERROR),
            connect: () => { authorizeIntegration({ integrationToConnect: integration.BOX }) },
            disconnect: () => { disconnectIntegration({ integrationToDisconnect: integration.BOX }) },
            folderDisplay: () => getFolderDisplay({ integrationToConnect: integration.BOX }),
            name: 'Box'
        },
    ];

    return (<>
        <Container className={classes.rootContainer}>
            <Stack direction="column" spacing={1} pt={4} pb={2}>
                <Typography variant="h4-bold">Connect Notissia to your workflow</Typography>
                <Divider sx={{ width: '100%' }} />
                {loading ? (
                    <FallbackLoading height={`calc(100vh - 200px)`} />
                ) : (
                    <Stack alignItems="flex-start" justifyContent="center">
                        <MBox display="grid" gap="24px">
                            {integrationCards.map((data, i) => (<Fragment key={'integration-page-revamped-466-' + i}>
                                {data.name === 'Zapier' ? (
                                    <MBox sx={{ gridColumnStart: 1, gridColumnEnd: 3, mt: '24px' }}>
                                        <Typography component="p" className={classes.subheader} variant="b1-regular">
                                            Connect Notissia to any app via Zapier to sync data from dashboards to your workflow.
                                        </Typography>
                                        <Stack className={classes.fullWidthIntegrationCard} direction="row">
                                            <MBox className={classes.integrationCard} style={{borderRadius: 'unset', backgroundColor: 'unset'}}>
                                                <Stack direction="row" justifyContent="space-between" mb="60px">
                                                    <Stack spacing={1}>
                                                        <img src={`/images/${data.icon}.png`} width="128px" alt={`${data.name} icon`} />
                                                        {flags.integrationsPlan && data.isConnected &&
                                                            <MBox sx={{ position: 'absolute', bottom: '16px', right: '-80px' }}>
                                                                <img src={`/images/check.png`} width="20" height="20" alt="check circle" />
                                                            </MBox>
                                                        }
                                                    </Stack>
                                                    {flags.integrationsPlan && (
                                                        <Button className={data.isConnected ? classes.disconnectButton : classes.connectButton} variant="outlined" onClick={data.isConnected ? data.disconnect : data.connect}>
                                                            <Typography variant="b2-bold"> {data.isConnected ? 'Disconnect' : 'Connect'} </Typography>
                                                        </Button>
                                                    )}
                                                </Stack>
                                                <Divider className={classes.divider}/>
                                                {flags.integrationsPlan && data.isConnected ? (<>
                                                    <Stack mt="25px">
                                                        <Typography variant="b2-semibold"> Notissia is uploading to:</Typography>
                                                        <InfoIcon className={classes.infoIcon} />
                                                    </Stack>
                                                    {data.folderDisplay()}
                                                </>) : (<>
                                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" color={theme.colors.neutral['800']} mt="25px">
                                                        <Info sx={{ color: theme.colors.neutral['600'] }} />
                                                        <Typography variant="b2-regular">Connect and sync data to any app including:</Typography>
                                                    </Stack>
                                                </>)}
                                            </MBox>
                                            <Divider className={classes.divider} orientation="vertical" style={{ height: 'unset', margin: '12px' }} />
                                            <MBox className={classes.integrationCard} style={{borderRadius: 'unset', backgroundColor: 'unset'}}>
                                                <Stack spacing={1}>
                                                    <Typography className={classes.textHeader}>
                                                        What is Zapier?
                                                    </Typography>
                                                    <Typography className={classes.textBody}>
                                                        Zapier is a third-party service used by some of the world's largest organizations to connect apps to each other to data can flow from one to the other.
                                                    </Typography>
                                                    <Typography className={classes.textHeader} mt="16px !important">
                                                        How do I set it up?
                                                    </Typography >
                                                    <Typography className={classes.textBody}>
                                                        {`If you don't have a Zapier account, you can create one `}
                                                        <Typography
                                                            component="a"
                                                            href="https://zapier.com/"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            display="inline"
                                                            sx={{ color: '#048290', fontSize: '0.9rem', }}
                                                        >here</Typography>
                                                        {`. Or reach to us at `}
                                                        <Typography
                                                            component="a"
                                                            href="mailto:tech@notissia.com"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            display="inline"
                                                            sx={{ color: '#048290', fontSize: '0.9rem', }}
                                                        >tech@notissia.com</Typography>
                                                        {` and we can set it up for free in just 5 minutes.`}
                                                    </Typography>
                                                </Stack>
                                            </MBox>
                                        </Stack>
                                        <Divider sx={{ padding: '24px 0',  }}>
                                            <Typography color="gray" variant="b1-regular">
                                                Cloud Storage
                                            </Typography>
                                        </Divider>
                                        <Typography className={classes.subheader} variant="b1-regular">
                                            Connect Notissia to your cloud storage to automatically sync files from dashboards to your shared drive.
                                        </Typography>
                                    </MBox>
                                ) : (
                                    <MBox className={classes.integrationCard}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Stack direction="column" spacing={1} alignItems="flex-start">
                                                <MBox sx={{ position: 'relative' }}>
                                                    <img src={`/images/${data.icon}.png`} width="64px" height="64px" alt={`${data.name} icon`} />
                                                    {flags.integrationsPlan && data.isConnected &&
                                                        <MBox sx={{ position: 'absolute', bottom: '-8px', right: '-8px' }}>
                                                            <img src={`/images/check.png`} width="20px" height="20px" alt="check circle" />
                                                        </MBox>
                                                    }
                                                </MBox>
                                                <Typography variant="b2-bold" fontSize="1.1rem"> {data.name} </Typography>
                                            </Stack>
                                            {flags.integrationsPlan && (
                                                <Button className={data.isConnected ? classes.disconnectButton : classes.connectButton} variant="outlined" onClick={data.isConnected ? data.disconnect : data.connect}>
                                                    <Typography variant="b2-bold"> {data.isConnected ? 'Disconnect' : 'Connect'} </Typography>
                                                </Button>
                                            )}
                                        </Stack>
                                        <Divider className={classes.divider}/>
                                        {flags.integrationsPlan && data.isConnected ? (<>
                                            <Stack mt="25px">
                                                <Typography variant="b2-semibold"> Notissia is uploading to:</Typography>
                                                <InfoIcon className={classes.infoIcon} />
                                            </Stack>
                                            {data.folderDisplay()}
                                        </>) : (
                                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start"  color={theme.colors.neutral['800']} mt="25px">
                                                <Info sx={{ color: theme.colors.neutral['600'] }} />
                                                <Typography variant="b2-regular">The selected file directory will be shown here.</Typography>
                                            </Stack>
                                        )}
                                    </MBox>
                                )}
                            </Fragment>))}
                        </MBox>
                    </Stack>
                )}
            </Stack>
        </Container>
        <IntegrationSelectionModal
            isOpen={integrationSelectionModalOpen}
            isLoading={integrationSelectionModalLoading}
            options={integrationSelectionOptions}
            integrationToConnect={integrationInModal}
            onClose={() => { setIntegrationSelectionModalOpen(false); }}
            onSelected={saveIntegrationConnection}
        />
        <ProPlanModal variant="upgrade" exceptPlan={['free']}  />
    </>);
}

export default withLDConsumer()(IntegrationPage);
