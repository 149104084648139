import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useBulkDashboards from "../../../../hooks/useBulkDashboards";
import { collectionKey as companiesKey } from "./ShareCompanies";
import { collectionKey as investorsKey} from "./ShareInvestors";
import useBulkWorkspaces from "../../../../hooks/useBulkWorkspaces";
import BulkShareDeals, { BulkShareCuratedTabs } from "../../../atoms/[legacy]/home-bulk-share-deals";

const useStyles = makeStyles((theme) => ({
    mainContent: {
        width: '45vw',
        height: 'max-content',
        maxHeight: '70vh',
        padding: 'unset',
        overflow: 'hidden',
    },
    commonButton: {
        width: 'auto',
        minWidth: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    nextButton: {
        width: 'auto',
        minWidth: 100,
        borderRadius: 32,
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
    },
}));

const ShareStepper: React.FC<{
    bulkContext: BulkShareCuratedTabs,
    onSelect?: React.Dispatch<React.SetStateAction<BulkShareCuratedTabs>>,
 }> = ({ bulkContext, onSelect }) => {
    const classes = useStyles();
    const { getBulkDashboards } = useBulkDashboards();
    const { getBulkWorkspaces } = useBulkWorkspaces();

    const [steps, setSteps] = useState<number>(0);
    const [activeStep, setActiveStep] = useState<number>(0);

    const workspaces = useMemo(() => getBulkWorkspaces(investorsKey), [getBulkWorkspaces]);
    const dashboards = useMemo(() => getBulkDashboards(companiesKey).map(exportable => exportable.dashboard), [getBulkDashboards]);

    const handleNavigate = useCallback((index: number) => {
        setActiveStep(prev => {
            const nextIndex = prev + index;

            if (nextIndex < 0) {
                onSelect?.(bulkContext);
                return prev;
            } else if (nextIndex >= steps) {
                onSelect?.(BulkShareCuratedTabs.ConfirmAll);
                return prev;
            }

            return nextIndex;
        });
    }, [bulkContext, onSelect, steps]);

    useEffect(() => {
        if (bulkContext === BulkShareCuratedTabs.Companies)
            setSteps(getBulkDashboards(companiesKey).length)
        else if (BulkShareCuratedTabs.Investors)
            setSteps(getBulkWorkspaces(investorsKey).length)
    }, [bulkContext, getBulkDashboards, getBulkWorkspaces]);

    return (<>
        <BulkShareDeals.Panel value={BulkShareCuratedTabs.EachStepper}
            content={(
                <Stack direction="column" className={classes.mainContent} alignItems="flex-start" justifyContent="stretch">
                    {(bulkContext === BulkShareCuratedTabs.Companies && !!dashboards.length) && (
                        <BulkShareDeals.EachInvestor dashboard={dashboards[activeStep]}
                            key={'share-stepper-83-' + dashboards[activeStep].id} />
                    )}
                    {(bulkContext === BulkShareCuratedTabs.Investors && !!workspaces.length) && (
                        <BulkShareDeals.EachCompany workspace={workspaces[activeStep]}
                            key={'share-stepper-87-' + workspaces[activeStep].id} />
                    )}
                </Stack>
            )}
            actions={(
                <Stack direction="row" justifyContent="space-between" width="100%">
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
                        <Button variant="outlined"
                            className={classes.commonButton}
                            onClick={() => handleNavigate(1)}>
                            {'Skip'}
                        </Button>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                        <Button className={classes.commonButton}
                            onClick={() => handleNavigate(-1)}>
                            {'Back'}
                        </Button>
                        <Button variant="contained"
                            className={classes.nextButton}
                            onClick={() => handleNavigate(1)}>
                            {'Next'}
                            <Typography component="span" fontWeight={300}>
                                {(steps > (activeStep + 1)) ? (<>
                                    <span>{(bulkContext === BulkShareCuratedTabs.Companies) && `: ${dashboards[activeStep + 1]?.title}`}</span>
                                    <span>{(bulkContext === BulkShareCuratedTabs.Investors) && `: ${workspaces[activeStep + 1]?.name}`}</span>
                                </>) : (steps >= 1) && (
                                    <span>{': confirm selection'}</span>
                                )}
                            </Typography>
                        </Button>
                    </Stack>
                </Stack>
            )} />
    </>);
}

export default ShareStepper;
