import React from "react";
import { ReactComponent as InvestorsIcon } from "../../assets/icons/investors.svg";

const InvestorsIconFC: React.FC<{
    width?: number,
    height?: number,
    color?: string
}> = ({ width, height, color }) => (
    <InvestorsIcon width={width ?? 24} height={height ?? 24} stroke={color ?? 'gray'} />
);

export default InvestorsIconFC;