import { Dashboard, DashboardDocument, DashboardScreenshot, DashboardSelection } from "../types/files";
import { SaveDashboardItemType } from "../types/common";
import React from "react";
import moment from "moment";

export const getDashboardItemType = (item: DashboardDocument | DashboardScreenshot | DashboardSelection): SaveDashboardItemType => {
    if ("searchTerms" in item) {
        return SaveDashboardItemType.Page;
    }
    if ("selectedText" in item) {
        return SaveDashboardItemType.Text;
    }

    return SaveDashboardItemType.ScreenCapture;
}


export const scaleUpCommentBullet = (commentIndex: number) => {
    const scaledUp = document.getElementsByClassName('image-marker-scale-up');
    for (let i = 0; i < scaledUp.length; i++) {
        scaledUp[i].classList.remove("image-marker-scale-up");
    }
    const bulletElem = document.querySelectorAll(`[data-bulletmarkerid='${commentIndex}']`);
    for (let i = 0; i < bulletElem.length; i++) {
        if (!bulletElem[i].classList.contains("image-marker-scale-up-selected")) {
            bulletElem[i].classList.add("image-marker-scale-up");
        }
    }
}

export const scrollDashboardElementIntoView = (
    scrollRef: React.MutableRefObject<HTMLDivElement | null>,
    orderNumber: number
) => {
    const scrollElement = scrollRef.current?.children[orderNumber];
    if (scrollElement) {
        window.scrollBy(1, 1);
        scrollElement.scrollIntoView({inline: "start", behavior: 'smooth'});
        setTimeout(()=>{
            if (scrollRef) {
                scrollRef!.current?.scrollBy({
                    top: 0,
                    left: -7,
                    behavior : "smooth"
                });
            }
        }, 1500);
    }
}

export const darkenScrollArrows = (dark: boolean) => {
    const scrollArrows = document.getElementsByClassName('MuiFab-root');
    Array.from(scrollArrows).forEach((element) => {
        (element as HTMLElement).style.opacity=dark ? "0.2": "1";
    });
}

export const isDashboardNew = (dashboard: Dashboard) => {
    const diffHours = moment(dashboard.createdAt).diff(moment.now(), 'hours');
    return diffHours > -6;
}


export const scrollbarStyle = {
    "&::-webkit-scrollbar-track": {
        background: "none"
    },
    "&::-webkit-scrollbar-thumb": {
        visibility: 'hidden',
    },
    "&:hover": {
        "&::-webkit-scrollbar-thumb": {
            visibility: "none"
        }
    }
}

export const ChromeExtensionLink = "https://chromewebstore.google.com/detail/notissia-screenshot-captu/hnhfbkifbdghfaomkfmopoefmmafhelb";

export const isTitleDefault = (title?: string | null) => !title || title.match(/Page \d+$/gi)?.[0];

export const defaultStages = [
    'Pre-Seed',
    'Seed',
    'Series A',
    'Series B',
    'Series C',
    'Series D',
    'Late Stage',
];

export const defaultGeographies = [
    'US - all',
    'Europe',
    'Latin America',
    'Asia',
    'Africa',
];

export const defaultTags = [
    'Advertising',
    'Aerospace',
    'AgTech',
    'AI',
    'Analytics',
    'AR/VR',
    'AutoTech',
    'B2B',
    'B2C',
    'BioTech',
    'Chemicals',
    'Climate',
    'ClimateTech/CleanTech',
    'Cloud Infrastructure',
    'ConstructionTech',
    'Consumer',
    'Consumer Health',
    'Consumer Internet',
    'Cosmetics',
    'Creator Economy',
    'Crypto',
    'Cyber',
    'Cybersecurity',
    'Data Services',
    'Defense',
    'DeepTech',
    'DevTools',
    'Digital Health',
    'Direct-to-consumer (DTC)',
    'E-Commerce',
    'Education',
    'EnergyTech',
    'Enterprise',
    'Enterprise Applications',
    'Enterprise Infrastructure',
    'Entertainment & Sports',
    'Fashion',
    'FinTech',
    'Food & Beverage',
    'Future of Work',
    'Games',
    'GovTech',
    'Hardware',
    'Health',
    'Hospitality',
    'HRTech',
    'Impact',
    'Infrastructure',
    'Insurance',
    'IoT',
    'LegalTech',
    'Logistics',
    'Manufacturing',
    'Marketplace',
    'MarketingTech',
    'Media/Content',
    'Medical Devices',
    'Payments',
    'Proptech',
    'Retail',
    'Robotics',
    'Security',
    'Space',
    'Supply Chain Tech',
    'Therapeutics',
    'Travel',
    'Web3',
    'Wellness & Fitness'
];