import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LinkIcon from '@mui/icons-material/Link';
import SyncIcon from '@mui/icons-material/Sync';
import { Button, CircularProgress, ClickAwayListener, Fade, Paper, Popper, Stack, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as ExportIcon } from "../../../assets/icons/download.svg";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import { ReactComponent as FileUploadIcon } from "../../../assets/icons/upload.svg";
import { DashboardContext } from '../../../contexts/DashboardContext';
import useDashboard from '../../../hooks/useDashboard';
import useDashboards from '../../../hooks/useDashboards';
import useRoute from '../../../hooks/useRoute';
import theme from '../../../theme';
import ConfirmDialog from '../../modals/ConfirmDialog';
import DashboardExportPDFModal from '../../modals/dashboard-details/DashboardExportPDFModal';
import FileUploaderModal from '../../modals/dashboard-details/FileUploaderModal';
import FindInvestorsShareDealModal from '../../modals/dashboard-details/FindInvestorsShareDealModal';

const useStyles = makeStyles((theme) => ({
    modal: {
        width: 'fit-content',
        height: 'fit-content',
        padding: '12px 8px 0px 8px',
        border: '1px solid lightgray',
        borderRadius: 12,
    },
    title: {
        fontFamily: 'Poppins',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: 'black',
        marginLeft: 8,
    },
    popupButton: {
        minWidth: 120,
        width: 'auto',
        height: 'fit-content',
        borderRadius: 32,
        border: `1px solid ${theme.colors.primary['200']}`,
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
        "& > .MuiButton-endIcon": {
            "& > .MuiSvgIcon-root": {
                width: 32,
                height: 32,
            }
        },
    },
    button: {
        width: '100%',
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        color: 'black',
        textTransform: 'none',
        justifyContent: 'flex-start',
        "& > .MuiButton-startIcon": {
            "& > .MuiSvgIcon-root": {
                width: 24,
                height: 24,
                color: theme.palette.primary.light,
            }
        },
    },
    switchButton: {
        width: 60,
        height: 27,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: '4px',
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(30px)',
                '& .MuiSwitch-thumb': {
                    backgroundColor: '#048290',
                },
                '& + .MuiSwitch-track': {
                    border: '2px solid #048290',
                    backgroundColor: '#fff',
                    opacity: 1,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 18,
            height: 18,
            backgroundColor: theme.colors.primary['200'],
        },
        '& .MuiSwitch-track': {
            borderRadius: '20px',
            border: `2px solid ${theme.colors.primary['200']}`,
            backgroundColor: '#fff',
            opacity: 1,
            '&:before, &:after': {
                position: 'absolute',
                fontFamily: 'Inter',
                fontSize: '0.5rem',
                fontWeight: 600,
            },
            '&:before': {
                color: '#048290',
                content: '"ON"',
                padding: '6px',
            },
            '&:after': {
                color: theme.colors.primary['200'],
                content: '"OFF"',
                padding: '6px 34px',
            },
        },
    },
    copyText: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: 'black',
        textDecoration: 'none',
        textTransform: 'none',
    },
    uploadIcon: {
        width: 24,
        height: 24,
        fill: theme.palette.primary.light,
    },
    downloadIcon: {
        width: 24,
        height: 24,
        stroke: theme.palette.primary.light,
    },
    icon: {
        width: 24,
        height: 24,
        color: theme.palette.primary.light,
    },
}));

export const DEF_VALID_FILE = { maxFileSize: 100 * 1024 * 1024, allowedFileTypes: ['application/pdf'], };

const CompanyMultiAction: React.FC<{ flags?: any }> = ({ flags }) => {
    const classes = useStyles();
    const { dashboard } = useContext(DashboardContext);
    const { redirectFromDashboard, generateDashboardSharingURL } = useRoute();
    const { deleteDashboard } = useDashboards();
    const { autoSync, sharePublicly } = useDashboard();

    const [popperOpen, setPopperOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<string>('');
    const [findShareModalOpen, setFindShareModalOpen] = useState<boolean>(false);
    const [exportPDFModalOpen, setExportPDFModalOpen] = useState<boolean>(false);
    const [fileUploadModalOpen, setFileUploadModalOpen] = useState<boolean>(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
    const [syncExternally, setSyncExternally] = useState<boolean>(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleShareLink = useCallback(() => {
        setLoading('copy-link');
        sharePublicly(true, dashboard!).finally(() => {
            setLoading('');
            setPopperOpen(false);
        });
    }, [dashboard, sharePublicly]);

    const handleSyncExternal = useCallback((checked: boolean) => {
        setLoading('sync');
        autoSync(checked).finally(() => {
            setLoading('');
            setSyncExternally(checked);
        });
    }, [autoSync]);

    const handleDelete = useCallback(() => {
        setLoading('delete');
        deleteDashboard(dashboard!.id).finally(() => {
            setLoading('');
            redirectFromDashboard();
        });
    }, [dashboard, deleteDashboard, redirectFromDashboard]);

    useEffect(() => {
        if (flags.zapierPlan === false) {
            if (dashboard?.shouldSyncExternally === true)
                handleSyncExternal(false);
        } else if (dashboard) {
            setSyncExternally(!!dashboard?.shouldSyncExternally);
        }
    }, [flags.zapierPlan, dashboard, handleSyncExternal]);

    return (<>
        <Button variant="outlined"
            className={classes.popupButton}
            endIcon={<ArrowDropDownIcon />}
            onClick={() => setPopperOpen(prev => !prev)}
            ref={anchorRef} >
            {'Actions'}
        </Button>
        <Popper open={popperOpen}
            placement="bottom-end"
            anchorEl={anchorRef.current}
            sx={{ zIndex: 1500 }}
            transition>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                    <Paper className={classes.modal} elevation={3} square={false}>
                        <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
                            <Stack direction="column" alignItems="flex-start" justifyContent="center" width="100%" mb={1}>
                                <Button className={classes.button}
                                    startIcon={<ShareIcon className={classes.icon} style={{ stroke: theme.palette.primary.light }} />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setFindShareModalOpen(true);
                                        setPopperOpen(false);
                                    }}
                                >{`Find investors & share`} </Button>
                                <CopyToClipboard
                                    text={generateDashboardSharingURL(dashboard!.id)}
                                    options={{ format: "text/plain" }}
                                    onCopy={handleShareLink}>
                                    <Button variant="text" component={Stack} width="100%" p="4px !important"
                                        direction="row" spacing={1} alignItems="center" justifyContent="flex-start !important">
                                        {(loading === 'copy-link') ? (
                                            <CircularProgress size="24px" sx={{ color: theme.palette.primary.light }} />
                                        ): (<LinkIcon className={classes.icon} />)}
                                        <Typography className={classes.copyText}>
                                            {'Copy link to dashboard'}
                                        </Typography>
                                    </Button>
                                </CopyToClipboard>
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" width="100%" p="4px !important">
                                    {(loading === 'sync') ? (
                                        <CircularProgress size="24px" sx={{ color: theme.palette.primary.light }} />
                                    ): (<SyncIcon className={classes.icon} />)}
                                    <Typography className={classes.copyText}>
                                        {'Sync to external tools'}
                                    </Typography>
                                    <Switch className={classes.switchButton} checked={syncExternally}
                                        onChange={(e) => handleSyncExternal(e.target.checked)}
                                        disableRipple/>
                                </Stack>
                                <Button className={classes.button}
                                    startIcon={<ExportIcon className={classes.downloadIcon} />}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setExportPDFModalOpen(true);
                                        setPopperOpen(false);
                                    }}
                                > {'Download as PDF'} </Button>
                                <Button className={classes.button}
                                    startIcon={<FileUploadIcon className={classes.uploadIcon} />}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setFileUploadModalOpen(true);
                                        setPopperOpen(false);
                                    }}
                                > {'Add files'} </Button>
                                <Button variant="text"
                                    className={classes.button}
                                    startIcon={<DeleteOutlineIcon
                                        sx={{ color: `${theme.colors.error['500']} !important` }} />}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setConfirmDeleteOpen(true);
                                        setPopperOpen(false);
                                    }}
                                    sx={{ color: `${theme.colors.error['500']} !important` }}
                                > {'Delete company'} </Button>
                            </Stack>
                        </ClickAwayListener>
                    </Paper>
                </Fade>
            )}
        </Popper>
        {findShareModalOpen && (
            <FindInvestorsShareDealModal
                isOpen={findShareModalOpen}
                onClose={() => setFindShareModalOpen(false)} />
        )}
        {fileUploadModalOpen && (
            <FileUploaderModal
                open={fileUploadModalOpen}
                validFile={DEF_VALID_FILE}
                validPath={!!dashboard ? [dashboard.id, dashboard.title].join('/') : undefined}
                onClose={() => setFileUploadModalOpen(false)}
            />
        )}
        {exportPDFModalOpen && (
            <DashboardExportPDFModal
                isOpen={exportPDFModalOpen}
                onClose={() => setExportPDFModalOpen(false)}
            />
        )}
        <ConfirmDialog
            title="Delete confirmation"
            content="Are you sure you want to delete the selected companies?"
            open={confirmDeleteOpen}
            loading={loading === 'delete'}
            confirmCallback={handleDelete}
            cancelCallback={() => setConfirmDeleteOpen(false)}
        />
    </>);
}

export default withLDConsumer()(CompanyMultiAction);