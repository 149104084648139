import { memo } from "react";
import BulkShareButton from "./BulkShareButton";
import BulkSharePanel from "./BulkSharePanel";
import BulkShareAssignCards from "./BulkShareAssignCards";
import ShareCompanies from "../../../molecules/[legacy]/home-bulk-share-deals/ShareCompanies";
import ShareConfirmAll from "../../../molecules/[legacy]/home-bulk-share-deals/ShareConfirmAll";
import ShareEachCompany from "../../../molecules/[legacy]/home-bulk-share-deals/ShareEachCompany";
import ShareEachInvestor from "../../../molecules/[legacy]/home-bulk-share-deals/ShareEachInvestor";
import ShareInvestors from "../../../molecules/[legacy]/home-bulk-share-deals/ShareInvestors";
import ShareMailingDone from "../../../molecules/[legacy]/home-bulk-share-deals/ShareMailingDone";
import ShareSelect from "../../../molecules/[legacy]/home-bulk-share-deals/ShareSelect";
import ShareStepper from "../../../molecules/[legacy]/home-bulk-share-deals/ShareStepper";

export enum BulkShareCuratedTabs {
    Selection = 'selection',
    Companies = 'companies',
    Investors = 'investors',
    EachStepper = 'stepper',
    ConfirmAll = 'confirm',
    Done = 'done',
    Close = '{close}',
}

export const BulkShareDeals = {
    Button: BulkShareButton,
    Panel: BulkSharePanel,
    AssignCards: BulkShareAssignCards,
    Select: memo(ShareSelect),
    Companies: memo(ShareCompanies),
    Investors: memo(ShareInvestors),
    Stepper: memo(ShareStepper),
    EachCompany: memo(ShareEachCompany),
    EachInvestor: memo(ShareEachInvestor),
    ConfirmAll: memo(ShareConfirmAll),
    Done: memo(ShareMailingDone),
};

export default BulkShareDeals;
