import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { ReactComponent as InvestorsIcon } from "../../../assets/icons/investors.svg";
import theme from "../../../theme";
import CreateEditSharedWorkspaceModal from "../../modals/investors/CreateEditSharedWorkspaceModal";
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles((theme) => ({
    mainText: {
        fontFamily: 'Inter',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        color: '#666666',
        textAlign: 'center',
    },
    otherText: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 400,
        color: theme.colors.neutral['600'],
        textAlign: 'center',
    },
    createButton: {
        width: 'auto',
        height: 42,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
}));

const InvestorsIconFC: React.FC<{ color: string}> = ({ color,  }) => (
    <InvestorsIcon width={150} height={120} stroke={color} />
);


const NoSharedWorkspacesYetView: React.FC<{ flags?: any }> = ({ flags }) => {
    const classes = useStyles();
    const [createSharedWorkspaceModalOpen, setCreateSharedWorkspaceModalOpen] = useState<boolean>(false);

    return (<>
        <Stack spacing={3} alignItems="center" justifyContent="center" width="100%" height="calc(100vh - 300px)">
        <InvestorsIconFC color={theme.colors.neutral['400']} />
            <Typography className={classes.mainText}>
                {'Notissia offers the first peer-to-peer,'}<br/>
                {'network-based deal sharing platform.'}
            </Typography>
            <Typography className={classes.otherText}>
                {'Invite investors and share curated deal flow'}<br />
                {'in a common workspace.'}
            </Typography>
            <Box />
            {!flags.sharedWorkspaces ? (
                <Tooltip placement="top-start" title={
                    <Typography component="span" fontSize="1rem" fontWeight={500}>
                        {`To create shared workspaces, please`}<br/>
                        {`subscribe to the Pro plan `}
                        <Typography
                            component="a"
                            href="/subscriptions"
                            target="_blank"
                            rel="noopener noreferrer"
                            display="inline"
                            sx={{
                                color: '#fff',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                textDecoration: 'underline',
                                "&:hover": {
                                    textDecoration: 'underline !important',
                                }
                            }}
                        >here</Typography>.
                    </Typography>}
                    componentsProps={{tooltip:{sx:{
                        display: 'flex', alignItems: 'center', borderRadius: '12px', transform: 'translate(-20px) !important',
                        backgroundColor: 'rgba(150, 150, 150)', width: '300px', height: '70px',  padding: 2,
                    }}}}>
                    <Button variant="outlined"
                        className={classes.createButton}
                        startIcon={<AddCircleOutlineIcon />}
                        sx={{ cursor: 'auto'}}> 
                        {'Create a shared workspace'} 
                        </Button>
                </Tooltip>
            ) : (
                <Button variant="outlined"
                    className={classes.createButton}
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setCreateSharedWorkspaceModalOpen(true);
                    }}> {'Create a shared workspace'} </Button>
            )}
        </Stack>
        {createSharedWorkspaceModalOpen && (
            <CreateEditSharedWorkspaceModal
                isOpen={createSharedWorkspaceModalOpen}
                onClose={() => {
                    setCreateSharedWorkspaceModalOpen(false);
                }}
            />
        )}
    </>);
}

export default withLDConsumer()(NoSharedWorkspacesYetView);