import { useCallback, useContext, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Button, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import BulkShareDeals, { BulkShareCuratedTabs } from "../../../atoms/[legacy]/home-bulk-share-deals";
import useBulkWorkspaces from "../../../../hooks/useBulkWorkspaces";
import useBulkDashboards from "../../../../hooks/useBulkDashboards";
import { Dashboard, Workspace } from "../../../../types/files";
import { updateWorkspaceFunc, sendShareWorkspaceEmailFunc, addWorkspaceToDashboardFunc } from "../../../../lib/helper";
import ArrayUtils from "../../../../utils/ArrayUtils";
import {AuthContext} from "../../../../contexts/AuthContext";
import {DashboardsContext} from "../../../../contexts/DashboardsContext";
import {GroupSettingsContext} from "../../../../contexts/GroupSettingsContext";
import { FallbackLoading } from "../../../templates/loader";
import { collectionKey as companiesKey } from "./ShareCompanies";
import { collectionKey as investorsKey} from "./ShareInvestors";
import useBackwardsCompatible from "../../../../hooks/useBackwardsCompatible";

const useStyles = makeStyles((theme) => ({
    mainContent: {
        width: '45vw',
        height: 'max-content',
        maxHeight: '70vh',
        padding: 'unset',
        overflow: 'hidden',
    },
    scrollable: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
    },
    mainTitle: {
        fontFamily: 'Inter',
        fontSize: '1.3rem !important',
        fontWeight: '700 !important',
        color: theme.palette.primary.main,
    },
    subtitle: {
        paddingLeft: 32,
        paddingRight: 32,
        fontSize: '1rem',
        fontWeight: 'bold',
        color: 'black',
    },
    mainText: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    subText: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 400,
        color: theme.colors.neutral['800'],
    },
    commonButton: {
        width: 'auto',
        minWidth: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    nextButton: {
        width: 'auto',
        minWidth: 100,
        borderRadius: 32,
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
    },
}));

const ShareConfirmAll: React.FC<{
    bulkContext: BulkShareCuratedTabs,
    onSelect?: React.Dispatch<React.SetStateAction<BulkShareCuratedTabs>>,
}> = ({ bulkContext, onSelect }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { user, userGroup } = useContext(AuthContext);
    const { setWorkspaces } = useContext(GroupSettingsContext);
    const { dashboards, setDashboards } = useContext(DashboardsContext);
    const {getBulkDashboards} = useBulkDashboards();
    const {getBulkWorkspaces} = useBulkWorkspaces();
    const { dashboardWorkspaces } = useBackwardsCompatible();

    const [loading, setLoading] = useState<boolean>(false);
    const [mailables, setMailables] = useState<{workspace: Workspace, dashboards: Dashboard[]}[]>([]);

    const handleShareEmails = useCallback(async () => {
        const assignPromises: Promise<Dashboard>[] = [];
        const updatePromises: Promise<Workspace>[] = [];
        const sharePromises: Promise<string | any>[] = [];

        setLoading(true);
        mailables.forEach(mailable => {
            mailable.dashboards.forEach(dashboard => {
                if (!dashboardWorkspaces(dashboard)?.includes(mailable.workspace.id)) {
                    assignPromises.push(addWorkspaceToDashboardFunc({
                        dashboardId: dashboard.id,
                        workspaceId: mailable.workspace.id,
                        group: userGroup,
                    }));
                }
            });

            if (!mailable.workspace.isPublic)
                updatePromises.push(updateWorkspaceFunc({...mailable.workspace, isPublic: true, }));

            sharePromises.push(sendShareWorkspaceEmailFunc({
                workspaceId: mailable.workspace.id,
                // toAddress: localWorkspaces.find(lWs => lWs.id === mailable.workspace.id)?.email ?? mailable.workspace.email,
                toAddress: mailable.workspace.email,
                replyToAddress: user.attributes.email,
                dashboardIds: mailable.dashboards.map(dashboard => dashboard.id),
            }));
        });

        try {
            const dashboardsData: Dashboard[] = await Promise.all(assignPromises);

            if (dashboardsData.length) {
                // force update dashboards list
                const updatedDashboards = [...dashboards].map(current => {
                    const updates = dashboardsData.filter(updated => updated.id === current.id);

                    if (updates.length)
                        return ArrayUtils.sortByDescending(updates, 'createdAt')[0];

                    return current;
                });

                setDashboards(ArrayUtils.sortByDescending(updatedDashboards, 'createdAt'));
            }

            const updatedWorkspaces: Workspace[] = await Promise.all(updatePromises);
            const workspaceIds = updatedWorkspaces.map(workspace => workspace.id);

            setDashboards(prev => prev.map(dashboard => ({
                ...dashboard,
                isPublic: dashboardWorkspaces(dashboard)?.some(wsId => workspaceIds.includes(wsId)) || dashboard.isPublic,
            })));

            setWorkspaces(prev => prev.map(ws => updatedWorkspaces.find(updated => updated.id === ws.id) ?? ws));

            await Promise.all(sharePromises);

            enqueueSnackbar('Workspaces updated successfully', {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                autoHideDuration: 4000,
            });
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
            onSelect?.(BulkShareCuratedTabs.Done);
        }
    // eslint-disable-next-line
    }, [mailables, dashboards, user, userGroup]);

    useEffect(() => {
        switch (bulkContext) {
            case BulkShareCuratedTabs.Companies: (() => {
                const collections = getBulkDashboards(companiesKey).map(exportable => ({
                    dashboard: exportable.dashboard,
                    workspaces: getBulkWorkspaces(`selected-investors:${exportable.dashboard.id}`)
                }));
                const workspaceMap: Map<string, Workspace> = new Map();
                const dashboardsMap: Map<string, Dashboard[]> = new Map();

                collections.forEach(collection => {
                    collection.workspaces.forEach(workspace => {
                        if (!workspaceMap.has(workspace.id))
                            workspaceMap.set(workspace.id, workspace);
                        if (!dashboardsMap.has(workspace.id))
                            dashboardsMap.set(workspace.id, []);
                        dashboardsMap.get(workspace.id)?.push(collection.dashboard);
                    });
                });

                setMailables(Array.from(dashboardsMap, ([workspaceId, dashboards]) => ({
                    workspace: workspaceMap.get(workspaceId)!,
                    dashboards,
                })));
            })(); break;
            case BulkShareCuratedTabs.Investors: (() => {
                const collections = getBulkWorkspaces(investorsKey).map(workspace => ({ workspace,
                    dashboards: getBulkDashboards(`selected-companies:${workspace.id}`)
                        .map(exportable => exportable.dashboard),
                }));

                setMailables(collections);
            })(); break;
            default:
                break;
        }
    }, [bulkContext, getBulkDashboards, getBulkWorkspaces]);

    return (<>
        <BulkShareDeals.Panel value={BulkShareCuratedTabs.ConfirmAll}
            content={(<>
                <Stack spacing={2} alignItems="stretch" justifyContent="center" mb={2} width="fit-content">
                    <Typography className={classes.mainText}>
                        {`You are about to share the following deals with these investors:`}
                    </Typography>
                    <Typography className={classes.subText}>
                        {'The recepient(s) will receive an email where they will be able to access their shared workspace'}<br/>
                        {'using their email, edit their investment preferences, and view deals you share.'}<br />
                    </Typography>
                </Stack>
                <Stack direction="column" className={classes.mainContent} spacing={1} alignItems="flex-start" justifyContent="stretch">
                    {!mailables.length ? (<FallbackLoading />) : (
                        <Stack direction="column" className={classes.scrollable}
                            spacing={1} alignItems="flex-start" justifyContent="stretch">
                            {mailables.map(mailable =>
                                <BulkShareDeals.AssignCards
                                    workspace={mailable.workspace}
                                    dashboards={mailable.dashboards}
                                    onWorkspace={(workspace) => mailable.workspace = workspace} />
                            )}
                        </Stack>
                    )}
                </Stack>
            </>)}
            actions={(
                <Stack direction="row" justifyContent="flex-end">
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Button
                            className={classes.commonButton}
                            onClick={() => onSelect?.(BulkShareCuratedTabs.EachStepper)}>
                            {'Back'}
                        </Button>
                        <LoadingButton variant="contained"
                            className={classes.nextButton}
                            loading={loading}
                            onClick={handleShareEmails}>
                            {'Send'}
                        </LoadingButton>
                    </Stack>
                </Stack>
            )}
            noActionDiv />
    </>);
}

export default ShareConfirmAll;