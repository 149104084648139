import { useCallback, useContext, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import awsExports from "../aws-exports";
import { AuthContext } from "../contexts/AuthContext";
import { DashboardContext } from "../contexts/DashboardContext";
import { DashboardsContext } from "../contexts/DashboardsContext";

const { app_domain } = awsExports;

export type FromRouteType = {
  origin?: string | null;
  originId?: string | null;
  originGroup?: string | null;
};

const useRoute = () => {
  const history = useHistory();
  const location = useLocation();
  const params: { [key: string]: string } = useParams();
  const { userGroup } = useContext(AuthContext);
  const { isPublicView: publicDashboard } = useContext(DashboardContext);
  const { isPublicView: publicWorkspace, isShared: sharedWorkspace  } = useContext(DashboardsContext);

  const isPublic = useMemo(() => publicDashboard || publicWorkspace, [publicDashboard, publicWorkspace]);

  const getURLFrom = useCallback(() => {
    const urlFrom = new URLSearchParams(location.search).get('from');

    if (!!urlFrom) {
      const parsedFrom = JSON.parse(atob(urlFrom)) as FromRouteType | null;

      if (!!parsedFrom) {
        return {
          ...parsedFrom,
          originGroup: !!parsedFrom?.originGroup ? atob(parsedFrom?.originGroup) : null,
        } as FromRouteType;
      }
    } else {
      return {
        origin: location.pathname.replace('/', ''),
        originId: null,
        originGroup: userGroup,
      } as FromRouteType;
    }

    return null;
  }, [location, userGroup]);

  const validateFrom = useCallback((workspaces: string[]) => {
    const from = getURLFrom();

    if (!!from) {
      if (!from.origin)
        return false;
      else if (['people', 'shared'].includes(from.origin) && workspaces.includes(from.originId || ''))
        return true;
    } else if (!isPublic) {
      return true;
    }

    return false;
  }, [getURLFrom, isPublic]);

  const generateDashboardSharingURL = useCallback((dashboardId: string) => {
    const from = getURLFrom();
    let route = `${app_domain}/dashboards/public/${dashboardId}`;

    if (!!from && !!from.originId) {
      route += `?from=${btoa(JSON.stringify({
        origin: from.origin,
        originId: from.originId,
        originGroup: btoa(from.originGroup || userGroup),
      }))}`;
    } else {
      route += `?from=${btoa(JSON.stringify({
        origin: !!params.workspaceId ? (sharedWorkspace ? 'shared' : 'people') : 'dashboards',
        originId: params.workspaceId ?? null,
        originGroup: btoa(from?.originGroup || userGroup),
      }))}`;
    }

    return route;
  }, [getURLFrom, params.workspaceId, sharedWorkspace, userGroup]);

  const redirectFromDashboard = useCallback(() => {
    const from = getURLFrom();

    if (!!from) {
      if (!!from.origin && ['home', 'dashboards', 'network'].includes(from.origin)) {
        history.push(`/${from.origin}`);
      } else if (!!from.origin && ['people', 'shared'].includes(from.origin)){
        let route = `/${from.origin}`;

        if (isPublic)
            route += '/public';

        if (!!from.originId) {
          route += `/${from.originId}`;

          route += `?from=${btoa(JSON.stringify({
            origin: from.origin,
            originId: null,
            originGroup: btoa(from.originGroup || userGroup),
          }))}`;

          history.push(route);
        }
      }
    } else if (!isPublic) {
      history.push('/dashboards');
    } else {
      history.goBack();
    }
  }, [getURLFrom, isPublic, userGroup, history]);

  const redirectToDashboard = useCallback((dashboardId: string, tab?: string, shared?: boolean) => {
    const from = getURLFrom();
    let route = '/dashboards';

    if (shared)
      route += '/shared';

    if (isPublic)
      route += '/public';

    route += `/${dashboardId}`;

    if (!!tab)
      route += `/${tab}`;

    if (!!from) {
      route += `?from=${btoa(JSON.stringify({
        origin: from.origin,
        originId: from.originId ?? params.workspaceId,
        originGroup: btoa(from.originGroup || userGroup),
      }))}`;
    } else {
      route += `?from=${btoa(JSON.stringify({
        origin: location.pathname.replace('/', ''),
        originId: null,
        originGroup: btoa(userGroup),
      }))}`;
    }

    history.push(route);
  }, [getURLFrom, isPublic, history, params.workspaceId, userGroup, location.pathname]);

  const generateWorkspaceSharingURL = useCallback((workspaceId: string, workspaceGroup?: string, shared?: boolean) => {
    let route = `${app_domain}/${shared ? 'shared': 'people'}/public/${workspaceId}`;

    route += `?from=${btoa(JSON.stringify({
      origin: shared ? 'shared': 'people',
      originId: null,
      originGroup: btoa(workspaceGroup || userGroup),
    }))}`;

    return route;
  }, [userGroup]);

  const redirectFromWorkspace = useCallback(() => {
    const from = getURLFrom();

    if (!!from && !!from.origin && from.originId === null)
      history.push(`/${from.origin}`);
    else
      history.push('/people');
  }, [getURLFrom, history]);

  const redirectFromWorkspaceGroup = useCallback(() => {
    const from = getURLFrom();

    if (!!from && !!from.origin && from.originId === null)
      history.push(`/${from.origin}`);
    else
      history.push('/shared');
  }, [getURLFrom, history]);

  const redirectToWorkspace = useCallback((workspaceId: string, workspaceGroup?: string, shared?: boolean) => {
    let route = shared ? '/shared' : '/people';

    if (isPublic)
      route += '/public';

    route += `/${workspaceId}`;

    route += `?from=${btoa(JSON.stringify({
      origin: location.pathname.replace('/', ''),
      originId: null,
      originGroup: btoa(workspaceGroup || userGroup),
    }))}`;

    history.push(route);
  }, [history, isPublic, location.pathname, userGroup]);

  return {
    getURLFrom,
    validateFrom,
    generateDashboardSharingURL,
    redirectFromDashboard,
    redirectToDashboard,
    generateWorkspaceSharingURL,
    redirectFromWorkspace,
    redirectFromWorkspaceGroup,
    redirectToWorkspace,
  };
};

export default useRoute;
