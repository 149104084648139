import { useInfiniteQuery } from "@tanstack/react-query";
import { useVirtualizer } from "@tanstack/react-virtual";
import { RefObject, useEffect, useMemo } from "react";

type PagingData = {
  rows: any[];
  nextOffset: number|null;
};

type PaginatorType = {
  key: string;
  source: any[];
  limit: number;
  itemHeight: number;
  virtualRowsRef: RefObject<HTMLDivElement>;
};

const fetchPaginated = async (
  source: any[],
  limit: number,
  offset: number = 0,
): Promise<PagingData> => {
  const startIndex = offset * limit;
  const endIndex = Math.min((offset + 1) * limit, source.length);

  await new Promise((r) => setTimeout(r, 500));
  console.log('fetchPaginated:', source.length, startIndex, endIndex);

  if (endIndex >= source.length) {
    return ({
      rows: source.slice(startIndex, endIndex),
      nextOffset: null,
    });
  }

  return ({
    rows: source.slice(startIndex, startIndex + limit),
    nextOffset: ((endIndex >= source.length) ? null : offset + 1) ?? offset,
  });
}

const usePaginator = ({key, source, limit, itemHeight, virtualRowsRef}: PaginatorType) => {
  const { status, data, error, isFetching, isFetchingNextPage, hasNextPage,
    fetchNextPage, refetch } = useInfiniteQuery<PagingData, Error>([key],
    ({ pageParam = 0 }) => fetchPaginated(source, limit, pageParam), {
      getNextPageParam: (lastPage) => lastPage.nextOffset,
    }
  );

  const allRows = useMemo(() => !!data ? data?.pages.flatMap(page => page.rows)
    ?.filter((item, index, self) => self?.findIndex(i => i?.id === item?.id) === index) : []
  , [data]);

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? allRows.length + 1 : allRows.length,
    getScrollElement: () => virtualRowsRef.current,
    estimateSize: () => itemHeight,
    overscan: 5,
  });

  useEffect(() => {
    refetch();
  }, [refetch, source])

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

    if (!lastItem || !hasNextPage || isFetchingNextPage)
      return;

    if (lastItem.index >= allRows.length - 1)
      fetchNextPage();

  // eslint-disable-next-line
  }, [hasNextPage, fetchNextPage, allRows.length, isFetchingNextPage, rowVirtualizer.getVirtualItems()]);


  return {
    status,
    allRows,
    rowVirtualizer,
    error,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
  };
}

export default usePaginator;