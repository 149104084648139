import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box, IconButton, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import React, { useContext, useMemo, useState } from 'react';
import { GroupSettingsContext } from '../../../contexts/GroupSettingsContext';
import useBackwardsCompatible from '../../../hooks/useBackwardsCompatible';
import useRoute from '../../../hooks/useRoute';
import theme from '../../../theme';
import { Dashboard } from '../../../types/files';
import ModifyDealSource from '../../modals/home-table-view/ModifyDealSource';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'inline-block',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        padding: 'unset',
        border: 'unset',
        background: 'transparent',
        transition: 'all 300ms ease',
        "&:hover": {
            border: `unset !important`,
            background: 'transparent !important',
            boxShadow: 'unset !important',
        },
    },
    answerContainer: {
        width: 'auto',
        height: 'auto',
        padding: 8,
        border: `1px transparent solid`,
        borderRadius: 12,
    },
    answerHover: {
        width: '96%',
        marginRight: 2,
        '&:hover': {
            border: `1px ${theme.colors.neutral['400']} solid`,
            borderRadius: 12,
        }
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: '#666666',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginLeft: 8,
    },
    label: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        wordBreak: 'break-all',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    sublabel: {
        fontWeight: '500',
        color: theme.colors.neutral['500'],
    },
    icon: {
        width: '24px !important',
        height: '24px !important',
    }
}));

const DealSource: React.FC<{ dashboard: Dashboard }> = ({ dashboard }) => {
    const classes = useStyles();
    const { workspaces } = useContext(GroupSettingsContext);
    const { dashboardSource } = useBackwardsCompatible();
    const { redirectToWorkspace } = useRoute();

    const [sourceModalOpen, setSourceModalOpen] = useState<boolean>(false);
    const [hoverSource, setHoverSource] = useState<boolean>(false);

    const source = useMemo(() => dashboardSource(dashboard), [dashboard, dashboardSource]);

    const workspace = useMemo(() => workspaces.find(ws =>
        ws?.name?.toLowerCase() === source?.name?.toLowerCase()
        && ws?.email?.toLowerCase() === source?.email?.toLowerCase()
    ), [source, workspaces]);

    return (<>
        <Box className={classes.masonryElement}>
            <Stack direction="column" spacing={1} alignItems="flex-start" width="100%" onMouseLeave={() => setHoverSource(false)}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                    <Typography className={classes.title}>{'Deal Source'}</Typography>
                </Stack>
                <Stack direction="row" className={classnames(classes.answerContainer, hoverSource && classes.answerHover)}
                    alignItems="center" justifyContent="space-between" width="100%"
                    onMouseEnter={() => setHoverSource(true)}>
                    <Stack direction="row" spacing={1} alignItems="stretch" justifyContent="flex-start">
                        {(!!source?.name || !!source?.email) ? (<>
                            <PersonOutlineOutlinedIcon sx={{ width: 24, height: 24, fill: "rgb(163, 167, 172)" }} />
                            <Stack direction="column" spacing="4px" alignItems="flex-start" justifyContent="center">
                                {!!source?.name && (
                                    <Typography className={classes.label}
                                        onClick={() => !!workspace ? redirectToWorkspace(workspace?.id) : undefined}
                                        sx={{ cursor: !!workspace ? 'pointer' : 'auto'}}>
                                        {source?.name}
                                    </Typography>
                                )}
                                {!!source?.email && (
                                    <Typography className={classnames(classes.label, classes.sublabel)}>
                                        {source?.email}
                                    </Typography>
                                )}
                            </Stack>
                        </>) : (
                            <Typography className={classnames(classes.label, classes.sublabel)} pl={4}>
                                {'No deal source'}
                            </Typography>
                        )}
                    </Stack>
                    {hoverSource && (
                        <Stack direction="row" alignItems="stretch" justifyContent="flex-end">
                            <IconButton disableRipple
                                onClick={() => setSourceModalOpen(true)}
                                sx={{ padding: 'unset !important'}}>
                                <EditOutlinedIcon className={classes.icon} sx={{ fill: theme.colors.neutral['500'] }} />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Box>
        {sourceModalOpen && (
            <ModifyDealSource
                isOpen={sourceModalOpen}
                dashboard={dashboard}
                onClose={() => setSourceModalOpen(false) } />
        )}
    </>);
}

export default DealSource;
