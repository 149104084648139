import { useCallback } from 'react';
import { Dashboard, DealSource } from '../types/files';

const useBackwardsCompatible = () => {
  const dashboardWorkspaces = useCallback((dashboard?: Dashboard): string[] =>
    dashboard?.workspaceMemberships?.map(ws => ws.workspaceId) ?? dashboard?.workspaces ?? []
  , []);

  const dashboardSource = useCallback((dashboard?: Dashboard): DealSource|undefined =>
    dashboard?.dealSource ?? (!!dashboard?.source ? ({email: null, name: dashboard?.source} as DealSource) : undefined)
  , []);

  return { dashboardWorkspaces, dashboardSource };
};

export default useBackwardsCompatible;
