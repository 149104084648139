import TuneIcon from '@mui/icons-material/Tune';
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fade, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import { defaultGeographies, defaultStages, scrollbarStyle } from "../../../shared/dashboard";
import { InvestmentThesis } from "../../../types/files";
import MultiSelector from "../../atoms/MultiSelector";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: '550px',
            height: 'fit-content',
            padding: '16px 24px',
            borderRadius: 24,
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.3rem',
        gap: '0.5rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'hidden',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 0,
    },
    scrollable: {
        width: '100%',
        height: '100%',
        marginBottom: '16px !important',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    label: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    caption: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        color: theme.colors.neutral['500'],
    },
    inputField: {
        width: '100%',
        "& .MuiOutlinedInput-root": {
            height: 40,
            borderRadius: 8,
            padding: '0 8px !important',
            background: '#fff',
            "& > input, > textarea": {
                fontFamily: 'Lato',
                fontSize: '1.2rem',
                color: '#048290',
                padding: 'unset !important',
            },
            "& fieldset": {
              	borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              	borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              	borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    },
    existingIcon: {
        width: 20,
        height: 20,
        color: theme.colors.error['700'],
    },
    existingCaption: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '0.9rem',
        color: theme.colors.error['700'],
    },
    commonButton: {
        minWidth: 80,
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
    },
    cancelButton: {
        width: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const GlobalPreferencesModal: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {
    const classes = useStyles();
    const { tags, investmentThesis, setInvestmentThesis } = useContext(GroupSettingsContext);

    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [selectedStages, setSelectedStages] = useState<string[]>([]);
    const [selectedGeography, setSelectedGeography] = useState<string[]>([]);
    const [addDetails, setAddDetails] = useState<string>();
    const [saving, setSaving] = useState<boolean>(false);

    const handleSelect = useCallback((selections: string[], type: string) => {
        const focusArea = { tags: type === 'focusArea' ? selections : selectedTags };
        const stage = { stages: type === 'stage' ? selections : selectedStages };
        const geography = { regions: type === 'geography' ? selections : selectedGeography };
        const additionalDetails = { freeText: type === 'additionalDetails' ? selections[0] : addDetails };

        switch (type) {
            case 'focusArea':
                setSelectedTags(focusArea.tags ?? []);
                break;
            case 'stage':
                setSelectedStages(stage.stages ?? []);
                break;
            case 'geography':
                setSelectedGeography(geography.regions ?? []);
                break;
            case 'additionalDetails':
                setAddDetails(additionalDetails.freeText || '');
                break;
        }
    }, [selectedTags, selectedStages, selectedGeography, addDetails]);

    const handleSave = useCallback(() => {
        setSaving(true);
        const timeOut = setTimeout(() => {
            clearTimeout(timeOut);
            setInvestmentThesis({ 
                focusArea: { tags: selectedTags }, 
                stage: { stages: selectedStages }, 
                geography: { regions: selectedGeography },
                additionalDetails: { freeText: addDetails },
            } as InvestmentThesis);
            setSaving(false);
            onClose();
        }, 1000);
    // eslint-disable-next-line
    }, [selectedTags, selectedStages, selectedGeography, addDetails]);

    useEffect(() => {
        if (!!investmentThesis) {
            setSelectedTags(investmentThesis?.focusArea?.tags ?? []);
            setSelectedStages(investmentThesis?.stage?.stages ?? []);
            setSelectedGeography(investmentThesis?.geography?.regions ?? []);
            setAddDetails(investmentThesis?.additionalDetails?.freeText || '');
        }
    // eslint-disable-next-line
    }, [investmentThesis]);
    
    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={() => onClose()}>
            <DialogTitle className={classes.dialogTitle}>
                <TuneIcon sx={{ color: "#7bd4d4" }} />
                {`Update my deal preferences`}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Fade in timeout={300}>
                    <Stack direction="row" className={classes.scrollable} alignItems="flex-start">
                        <Stack direction="column" spacing={2} alignItems="flex-start" justifyContent="flex-start"
                            width="100%" height="100%">
                            <MultiSelector
                                mainOptions={tags}
                                selections={selectedTags}
                                label="Sectors of interest"
                                onSelection={(selections) => handleSelect(selections as string[], 'focusArea')}
                                withSearch />
                            <MultiSelector
                                mainOptions={defaultStages}
                                selections={selectedStages}
                                label="Stage"
                                onSelection={(selections) => handleSelect(selections as string[], 'stage')}
                                withSearch />
                            <MultiSelector
                                mainOptions={defaultGeographies}
                                selections={selectedGeography}
                                label="Geography"
                                onSelection={(selections) => handleSelect(selections as string[], 'geography')}
                                withSearch />
                            <Box />
                        </Stack>
                    </Stack>
                </Fade>
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Button
                    variant="outlined"
                    className={classes.cancelButton}
                    onClick={() => onClose()}
                > {'Cancel'} </Button>
                <LoadingButton
                    variant="contained"
                    className={classes.commonButton}
                    loading={saving}
                    onClick={handleSave}
                > {'Save'} </LoadingButton>
            </DialogActions>
        </Dialog>
    </>);
}

export default GlobalPreferencesModal;