import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Container, Divider, Stack, Tab, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import theme from '../../theme';
import IntegrationIcons from "../atoms/IntegrationIcons";
import InvestorActionButton from '../atoms/InvestorActionButton';
import InvestorsIconFC from '../atoms/InvestorsIconFC';
import SharedWorkspaceInvite from '../atoms/SharedWorkspaceInvite';
import NoInvestorsYetView from "../molecules/workspaces-empty/NoInvestorsYetView";
import NoSharedWorkspacesYetView from '../molecules/workspaces-empty/NoSharedWorkspacesYetView';
import WorkspacesSearchOrCreate from "../molecules/workspaces-search-create/WorkspacesSearchOrCreate";
import configuredAsyncComponentLoader, { FallbackLoading } from '../templates/loader';
import {DashboardsContext} from '../../contexts/DashboardsContext';

const NetworkOverview = configuredAsyncComponentLoader(
    () => import('../molecules/network-overview/NetworkOverview'),
    { fallback: <FallbackLoading height={`calc(100vh - 250px)`} />}
);
const InvestorsTable = configuredAsyncComponentLoader(() => import('../organisms/investors/InvestorsTable'));

enum NetworkTabs {
    Overview = 'Overview',
    People = 'People',
    Shared = 'Shared Workspaces',
}

enum NetworkTabRoutes {
    Overview = '/network',
    People = '/people',
    Shared = '/shared',
}

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        height: '100%',
        maxWidth: 'unset !important',
        overflowY: 'hidden',
    },
    block: {
        width: '100%',
        height: '100%',
        transition: '0.5s ease-in-out',
    },
    panel: {
        height: '100%',
        width: '100%',
        minWidth: 'calc(100vw - 94px - 550px)',
        maxWidth: 'calc(100vw - 94px - 135px)',
        transition: 'width .3s ease',
        overflow: 'hidden',
    },
    expanded: {
        height: '100%',
        width: '100%',
        minWidth: 'calc(100vw - 94px - 156px)',
        maxWidth: '100%',
    },
    preferences: {
        overflow: 'hidden',
        zIndex: 1300,
    },
    tabPanel: {
        height: '100%',
        width: '100%',
        padding: 'unset',
    },
    tabs: {
        "& .MuiTabs-indicator": {
            height: 3,
            background: theme.colors.primary['700'],
        }
    },
    tab: {
        minWidth: 100,
        minHeight: 48,
        marginRight: 8,
        padding: '6px 8px',
    },
    tabText: {
        color: '#878E95',
        fontFamily: 'Lato',
        fontWeight: 'bold',
        textTransform: 'none',
    },
    selectedTabText: {
        color: 'black',
        fontFamily: 'Lato',
        fontWeight: 'bold',
    },
    tabIcon: {
        width: 24,
        height: 24,
        fill: theme.colors.neutral['500'],
    },
    selectedTabIcon: {
        fill: theme.palette.primary.main,
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.3rem !important',
        fontWeight: 'bold',
        color: theme.colors.neutral['700'],
    },
    subtitle: {
        fontFamily: 'Inter',
        fontSize: '1.2rem !important',
        fontWeight: 600,
        color: theme.colors.neutral['500'],
    },
    formContainer: {
        height: 'auto',
        width: 'auto',
        paddingTop: '8px',
        paddingBottom: '8px',
        marginLeft: '8px',
        "& > .MuiInputBase-root": {
            justifyContent: 'center',
            fontFamily: 'Inter',
            color: '#666666',
            "& > div": {
                backgroundColor: 'unset !important',
            },
            "&::before": {
                content: 'unset !important',
            },
            "&::after": {
                content: 'unset !important',
            }
        }
    },
}));

export const collectionKey = 'investors';

const InvestorsPage: React.FC<{}> = () => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const { setWorkspace } = useContext(DashboardsContext);
    const [search, setSearch] = useState<string>('');
    const [investorCount, setInvestorCount] = useState<number|null>(null);
    const [selectedTab, setSelectedTab] = useState<NetworkTabs>(NetworkTabs.Overview);
    const [inviteCount, setInviteCount] = useState<number>(0);

    const tableHeight = useMemo(() => `calc(100vh - 210px - ${inviteCount > 2 ? 300 : inviteCount > 1 ? 240 : inviteCount > 0 ? 180 : 0 }px)`, [inviteCount]);

    const getClassForIcon = useCallback((tab: NetworkTabs) =>
        (classNames(classes.tabIcon, selectedTab === tab && classes.selectedTabIcon))
        // eslint-disable-next-line
    , [selectedTab]);

    const getClassForText = useCallback((tab: NetworkTabs) =>
        (classNames(classes.tabText, selectedTab === tab && classes.selectedTabText))
        // eslint-disable-next-line
    , [selectedTab]);

    const handleRoute = useCallback((tab: NetworkTabs) => {
        switch(tab) {
            case NetworkTabs.Shared:
                history.replace(NetworkTabRoutes.Shared);
                break;
            case NetworkTabs.People:
                history.replace(NetworkTabRoutes.People);
                break;
            case NetworkTabs.Overview:
            default:
                history.replace(NetworkTabRoutes.Overview);
                break;
        }
    }, [history]);

    useEffect(() => {
        switch(location.pathname) {
            case NetworkTabRoutes.Shared:
                setSelectedTab(NetworkTabs.Shared);
                break;
            case NetworkTabRoutes.People:
                setSelectedTab(NetworkTabs.People);
                break;
            case NetworkTabRoutes.Overview:
            default:
                setSelectedTab(NetworkTabs.Overview);
                break;
        }
    }, [location.pathname]);

    useEffect(() => {
        setWorkspace(null);

        return () => {
            setWorkspace(null);
        }
    // eslint-disable-next-line
    }, []);

    return (<>
        <Container className={classes.rootContainer}>
            <Stack direction="column" spacing={2} pt={4} pb={2}>
                <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" width="100%">
                    <Typography variant="h4-bold">{`Network`}</Typography>
                    <Box width="600px">
                        <WorkspacesSearchOrCreate search
                            placeholder='Search people or shared workspaces...'
                            onSearch={(value) => setSearch(value)} />
                    </Box>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                        <InvestorActionButton />
                        <IntegrationIcons />
                    </Stack>
                </Stack>
                <TabContext value={selectedTab}>
                    <Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <TabList className={classes.tabs} onChange={(_, value) => handleRoute(value)}>
                                <Tab className={classes.tab} iconPosition="start"
                                    icon={<DashboardIcon className={getClassForIcon(NetworkTabs.Overview)} />}
                                    label={<Typography className={getClassForText(NetworkTabs.Overview)}>
                                            {NetworkTabs.Overview}
                                        </Typography>}
                                    value={NetworkTabs.Overview} />
                                <Tab className={classes.tab} iconPosition="start"
                                    icon={<PersonOutlineOutlinedIcon className={getClassForIcon(NetworkTabs.People)} />}
                                    label={<Typography className={getClassForText(NetworkTabs.People)}>
                                            {NetworkTabs.People}
                                        </Typography>}
                                    value={NetworkTabs.People} />
                                <Tab className={classes.tab} iconPosition="start"
                                    icon={<InvestorsIconFC width={32} height={24} color={selectedTab !== NetworkTabs.Shared
                                        ? theme.colors.neutral['500'] : theme.palette.primary.main} />}
                                    label={<Typography className={getClassForText(NetworkTabs.Shared)}>
                                            {NetworkTabs.Shared}
                                        </Typography>}
                                    value={NetworkTabs.Shared} />
                            </TabList>
                        </Stack>
                        <Divider sx={{ width: '100%' }} />
                    </Stack>
                    <Stack className={classes.block} alignItems="stretch" justifyContent="space-between">
                        <TabPanel className={classes.tabPanel} value={NetworkTabs.Overview}>
                            <NetworkOverview search={search} />
                        </TabPanel>
                        <TabPanel className={classes.tabPanel} value={NetworkTabs.People}>
                            <InvestorsTable
                                collectionKey={collectionKey}
                                search={search}
                                onInvestorCount={setInvestorCount}
                                withFilter overview />
                            {(investorCount === 0) && (<NoInvestorsYetView />)}
                        </TabPanel>
                        <TabPanel className={classes.tabPanel} value={NetworkTabs.Shared}>
                            <SharedWorkspaceInvite onInviteCount={setInviteCount} />
                            <Typography className={classes.subtitle} color={`${theme.colors.neutral['500']} !important`} pt={1}>
                                {'Shared workspaces you are in '}
                                <Typography component="span" fontFamily="Inter" fontSize="1.1rem" fontWeight="500" display="inline">
                                    {`(${investorCount ?? 0})`}
                                </Typography>
                            </Typography>
                            <InvestorsTable
                                collectionKey={collectionKey}
                                search={search}
                                onInvestorCount={setInvestorCount}
                                height={tableHeight}
                                sharedOnly overview />
                            {(investorCount === 0) && (<NoSharedWorkspacesYetView />)}
                        </TabPanel>
                    </Stack>
                </TabContext>
            </Stack>
        </Container>
    </>);
}

export default InvestorsPage;
