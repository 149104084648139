import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classnames from "classnames";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { ReactComponent as InvestorsIcon } from "../../../assets/icons/investors.svg";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import theme from "../../../theme";
import { User } from "../../../types/common";
import { Workspace } from "../../../types/files";
import CheckIcon from '@mui/icons-material/CheckCircle';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    optionPaper: {
        background: 'white',
        border: `1px solid ${theme.colors.neutral['100']}`,
        borderRadius: 12,
        boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12)',
    },
    optionContainer: {
        padding: '2px 12px',
        margin: '4px 8px 4px 16px',
        borderBottom: `1px solid ${theme.colors.neutral['400']}`,
        transition: '0.5s ease-in-out',
        "&:hover": {
            backgroundColor: '#EFF6FF',
            boxShadow: '0px 4px 10px -1px rgba(16, 24, 40, 0.06)',
        },
        cursor: 'pointer',
    },
    optionItem: {
        fontSize: '0.9rem !important',
        fontWeight: '600 !important',
        color: `${theme.colors.neutral['800']} !important`,
    },
    inputField: {
        minWidth: 'fit-content',
        "& .MuiOutlinedInput-root": {
            minWidth: 250,
            height: 45,
            padding: '0 8px !important',
            borderRadius: 32,
            background: '#fff',
            "& > input": {
                padding: 'unset !important',
                fontSize: '1rem',
            },
            "& fieldset": {
              	borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              	borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              	borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    },
    smallInputField: {
        "& .MuiOutlinedInput-root": {
            height: 32,
            borderRadius: 16,
        },
    },
    mediumInputField: {
        "& .MuiOutlinedInput-root": {
            height: 40,
            borderRadius: 24,
        },
    },
    label: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        wordBreak: 'break-all',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    disabledLabel: {
        fontWeight: 500,
        color: theme.colors.neutral['500'],
        cursor: 'auto',
    },
    sublabel: {
        fontWeight: '500',
        color: theme.colors.neutral['500'],
    },
    inviteButton: {
        height: 32,
        width: 40,
        borderRadius: '16px !important',
        borderWidth: 3,
        fontSize: '14px !important',
        textTransform: 'none !important' as any,
        fontWeight: 'bold !important',
        "&:hover": {
            borderWidth: 3,
            fontWeight: 'bold !important',
        },
    },
    smallButton: {
        height: 24,
    },
}));

const WorkspacesLookUpInvite: React.FC<{
    invitedUsers: User[],
    smallFit?: boolean,
    mediumFit?: boolean,
    onInvite?: (user: User) => Promise<any>,
}> = ({ invitedUsers, smallFit, mediumFit, onInvite }) => {
    const classes = useStyles();
    const { workspaces } = useContext(GroupSettingsContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [valid, setValid] = useState<boolean>(false);
    const [userEmail, setUserEmail] = useState<string>('');
    const [workspace, setWorkspace] = useState<Workspace|null>(null);
    const [selectedUser, setSelectedUser] = useState<User|null>(null);

    const options = useMemo(() => workspaces.filter(ws => !ws.isShared), [workspaces]);

    const checkInvitedUser = useCallback((search: string) =>
        invitedUsers?.find(({email}) => email.toLowerCase() === search?.trim()?.toLowerCase())
    , [invitedUsers]);

    const debouncedName = useDebouncedCallback((name) => {
        setUserEmail(name);
        setWorkspace(null);
    }, 100, { maxWait: 200 });

    const handleInvite = useCallback(() => {
        if (!selectedUser) return;
        setLoading(true);

        onInvite?.(selectedUser).then(() => {
            setUserEmail('');
            setLoading(false);
        });
    }, [selectedUser, onInvite]);

    useEffect(() => {
        setValid(!!userEmail);
        setSelectedUser({username: userEmail, email: userEmail} as User);
    }, [userEmail]);

    return (<>
        <Autocomplete
            open={!!userEmail && !workspace}
            openOnFocus
            disableClearable
            autoHighlight
            includeInputInList
            disablePortal
            freeSolo
            forcePopupIcon={false}
            value={userEmail}
            onChange={(_, option) => {
                if (typeof option !== 'string') {
                    setUserEmail(option.email);
                    setWorkspace(option as Workspace);
                }
            }}
            options={options}
            filterOptions={(options, { inputValue }) =>
                options.filter(ws =>
                    ws.name?.toLowerCase()?.includes(inputValue?.trim()?.toLowerCase()) ||
                    ws.email?.toLowerCase()?.includes(inputValue?.trim()?.toLowerCase())
                )
            }
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.email)}
            PaperComponent={(props) => (<Box {...props} className={classes.optionPaper} />)}
            ListboxProps={{style: { maxHeight: 215, padding: 8, }}}
            renderOption={(props: any, option) => (
                <Box {...props} className={classes.optionContainer}
                    onClick={!checkInvitedUser(option.email) ? props.onClick : undefined}
                    sx={!checkInvitedUser(option.email) ? { cursor: 'auto' } : undefined}>
                    <Stack direction="row" className={classes.optionItem} alignItems="center" justifyContent="space-between" width="100%">
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                            <PersonOutlineOutlinedIcon sx={{ width: 24, height: 24, fill: "rgb(163, 167, 172)" }} />
                            <Stack direction="column" alignItems="flex-start" justifyContent="center">
                                <Typography className={classNames(classes.label, !!checkInvitedUser(option.email) && classes.disabledLabel)}>
                                    {option.name}
                                </Typography>
                                {!!option.email && (
                                    <Typography className={classnames(classes.label, classes.sublabel)}>
                                        {option.email}
                                    </Typography>
                                )}
                            </Stack>
                        </Stack>
                        {checkInvitedUser(option.email) && (
                            <CheckIcon sx={{ fontSize: '1.5rem', color: theme.palette.primary.main }} />
                        )}
                    </Stack>
                </Box>
            )}
            sx={{ width: '100%', }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    className={classnames(
                        classes.inputField,
                        smallFit && classes.smallInputField,
                        mediumFit && classes.mediumInputField,
                    )}
                    placeholder={'Search or invite investors...'}
                    onChange={(e) => debouncedName(e.target.value)}
                    sx={{ width: '100%' }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start" sx={{ color: theme.palette.primary.main }}>
                                <InvestorsIcon height={24} width={24} fill={theme.palette.primary.main} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end" >
                                {valid && (
                                    <LoadingButton variant="contained"
                                        className={classnames(
                                            classes.inviteButton,
                                            smallFit && classes.smallButton,
                                        )}
                                        loading={loading}
                                        onClick={handleInvite}>
                                        {'Invite'}
                                    </LoadingButton>
                                )}
                            </InputAdornment>
                )}}/>
            )}
        />
    </>);
}

export default WorkspacesLookUpInvite;