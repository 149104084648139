import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { enqueueSnackbar } from "notistack";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AuthContext } from "../../../contexts/AuthContext";
import { addWorkspaceUserMembershipFunc, deleteWorkspaceUserMembershipFunc, getWorkspaceUserMembershipsFunc } from "../../../lib/helper";
import { scrollbarStyle } from "../../../shared/dashboard";
import theme from "../../../theme";
import { User, WorkspaceUserMembershipStatus } from "../../../types/common";
import { Workspace, WorkspaceUserMembership } from "../../../types/files";
import InvestorsIconFC from '../../atoms/InvestorsIconFC';
import WorkspacesLookUpInvite from "../../molecules/workspaces-search-create/WorkspacesLookUpInvite";
import { FallbackLoading } from "../../templates/loader";
import { GroupSettingsContext } from '../../../contexts/GroupSettingsContext';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: '600px',
            height: 'fit-content',
            padding: '16px 24px',
            borderRadius: 24,
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.3rem',
        gap: '0.5rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'hidden',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 0,
    },
    inputField: {
        width: '100%',
        "& .MuiOutlinedInput-root": {
            height: 40,
            borderRadius: 8,
            padding: '0 8px !important',
            background: '#fff',
            "& > input, > textarea": {
                fontFamily: 'Lato',
                fontSize: '1.2rem',
                color: '#048290',
                padding: 'unset !important',
            },
            "& fieldset": {
              	borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              	borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              	borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    },
    checkBox: {
        padding: 'unset !important',
        marginRight: '4px !important',
        "& > svg": {
            width: '20px !important',
            height: '20px !important',
        }
    },
    removeButton: {
        width: 'auto',
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.palette.warning.dark,
        textTransform: 'none !important' as any,
        transition: 'ease-in-out 300ms',
        "&:hover": {
            background: 'transparent',
        }
    },
    commonButton: {
        minWidth: 96,
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
    },
    label: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        wordBreak: 'break-all',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    sublabel: {
        fontWeight: '500',
        color: theme.colors.neutral['500'],
    },
    label1: {
        fontSize: '1rem',
        fontWeight: 500,
        color: theme.colors.orange['600'],
    },
    label2: {
        fontSize: '1rem',
        fontWeight: 500,
        color: theme.colors.neutral['600'],
    },
    iconButton: {
        minWidth: 32,
        padding: 'unset !important',
        fontFamily: 'Inter !important',
        fontSize: '0.95rem',
        fontWeight: 400,
        color: theme.palette.primary.main,
        textTransform: 'none',
        "& > .MuiButton-startIcon": {
            width: 'auto',
            height: 32,
            padding: 4,
            "& > .MuiSvgIcon-root": {
                fontSize: 24,
                color: theme.palette.primary.main,
            }
        },
        "&:hover": {
            backgroundColor: 'inherit',
        },
    },
}));

const InviteInvestorsModal: React.FC<{
    isOpen: boolean,
    sharedWorkspace: Workspace,
    onClose: () => void,
}> = ({ isOpen, sharedWorkspace, onClose }) => {
    const classes = useStyles();
    const { workspaces } = useContext(GroupSettingsContext);
    const { userGroup } = useContext(AuthContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [invitedUsers, setInvitedUsers] = useState<User[]>([]);
    const [pendingEmails, setPendingEmails] = useState<string[]>([]);

    const invitableUsers = useMemo(() => workspaces.map(ws => ({email: ws.email, username: ws.name}) as User), [workspaces]);

    const isInvitable = useCallback((email: string) => !!invitableUsers.find(invitable => invitable.email === email), [invitableUsers]);

    const getInvitableName = useCallback((email: string) => invitableUsers.find(invitable => invitable.email === email)?.username, [invitableUsers]);

    const checkPending = useCallback((email: string) => !!pendingEmails.find(pending => pending === email), [pendingEmails]);

    const getUsers = useCallback(async () => {
        if (!!sharedWorkspace.id) {
            const memberships = await getWorkspaceUserMembershipsFunc({workspaceId: sharedWorkspace.id});
            const members = memberships as {items: WorkspaceUserMembership[]};
            const invitedUsers = (members?.items?.map(user => ({email: user.email, username: user.name})) ?? []) as User[];
            const pendings = (members?.items?.filter(shared =>
                shared.status === WorkspaceUserMembershipStatus.PENDING
            ).map(user => user.email)) as string[];

            setInvitedUsers(invitedUsers);
            setPendingEmails(pendings);
            setLoading(false);
        }
    }, [sharedWorkspace]);

    const handleInvite = useCallback(async (user: User) => {
        await addWorkspaceUserMembershipFunc({workspaceId: sharedWorkspace.id, name: sharedWorkspace.name, email: user.email});

        setInvitedUsers(prev => [...prev, user]);
        setPendingEmails(prev => [...prev, user.email]);
    }, [sharedWorkspace]);

    const handleRemove = useCallback(async (user: User) => {
        await deleteWorkspaceUserMembershipFunc({workspaceId: sharedWorkspace.id, email: user.email});

        setInvitedUsers(prev => prev.filter(investor => investor.email !== user.email));
        setPendingEmails(prev => prev.filter(email => email !==  user.email));
    }, [sharedWorkspace.id]);

    useEffect(() => {
        if (!!userGroup)
            getUsers();
    // eslint-disable-next-line
    }, [userGroup]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={() => onClose()}>
            <DialogTitle className={classes.dialogTitle}>
                {`Investors in ${sharedWorkspace?.name}`}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <WorkspacesLookUpInvite
                    invitedUsers={invitedUsers}
                    onInvite={handleInvite}
                    mediumFit />
                <Stack spacing="2px" alignItems="flex-start" justifyContent="stretch" mt={2} pl={2}>
                    <Typography className={classes.label1}>
                        {'To join a shared workspace, investors must have a Notissia account.'}
                    </Typography>
                    <Typography className={classes.label2}>
                        {'Copy and share Notissia sign up form below to invite new users.'}
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" mt={2}>
                    <Box width={24} />
                    <Typography fontFamily="Inter" fontSize="1rem" fontWeight="bold" color="black">{'Members'}</Typography>
                </Stack>
                <Divider sx={{ margin: '8px 0', width: '100%' }} />
                {loading ? (<FallbackLoading height="300px" />) : (<>
                    {!!invitedUsers.length ? (
                        <Stack width="100%" minHeight={300} maxHeight={500} height="fit-content" overflow="auto" sx={{ ...scrollbarStyle }}>
                            {invitedUsers.map(user => (
                                <Stack spacing={1} width="100%" mt={1}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                        <Stack direction="row" spacing={1} alignItems="flex-start" justifyContent="flex-start">
                                            <PersonOutlineOutlinedIcon sx={{ width: 24, height: 24, fill: "rgb(163, 167, 172)" }} />
                                            {!!isInvitable(user.email) ? (<>
                                                <Stack direction="column" alignItems="flex-start" justifyContent="center">
                                                    <Typography className={classes.label}>
                                                        {getInvitableName(user.email)}
                                                    </Typography>
                                                    <Typography className={classNames(classes.label, classes.sublabel)}>
                                                        {user.email}
                                                    </Typography>
                                                </Stack>
                                            </>) : (<>
                                                <Typography
                                                    fontFamily="Inter"
                                                    fontSize="1rem"
                                                    fontWeight="bold"
                                                    color="#666666"
                                                    textAlign="center">
                                                    {user.email}
                                                </Typography>
                                            </>)}
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                                            {checkPending(user.email) && (
                                                <Typography
                                                    fontFamily="Inter"
                                                    fontSize="0.95rem"
                                                    fontWeight={500}
                                                    fontStyle="italic"
                                                    color={theme.colors.neutral['500']}
                                                    textAlign="center">
                                                    {'Invite pending'}
                                                </Typography>
                                            )}
                                            <Button variant="text"
                                                className={classes.removeButton}
                                                onClick={() => handleRemove(user)}>
                                                {'Remove'}
                                            </Button>
                                        </Stack>
                                    </Stack>
                                    <Divider sx={{ margin: '8px 0', width: '100%' }} />
                                </Stack>
                            ))}
                        </Stack>
                    ) : (
                        <Stack spacing={1} alignItems="center" justifyContent="center" height={300}>
                            <InvestorsIconFC width={60} height={60} color="lightgray" />
                            <Typography fontFamily="Inter" fontSize="1.3rem" fontWeight="bold" color="black" textAlign="center">
                                {'No investors found'}<br/>
                                {'in this workspace.'}
                            </Typography>
                        </Stack>
                    )}
                </>)}
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <CopyToClipboard text={`https://form.typeform.com/to/xV9TrSLL`}
                    options={{ format: "text/plain" }}
                    onCopy={() => {
                        enqueueSnackbar(`Successfully copied to clipboard.`,
                            { anchorOrigin: { vertical: "bottom", horizontal: "right" }, });
                    }}>
                    <Button variant="text"
                        className={classes.iconButton}
                        startIcon={<ContentCopyIcon />}
                    >{`Copy sign up form here`} </Button>
                </CopyToClipboard>
                <Button
                    variant="contained"
                    className={classes.commonButton}
                    onClick={() => onClose()}
                > {'Close'} </Button>
            </DialogActions>
        </Dialog>
    </>);
}

export default InviteInvestorsModal;
