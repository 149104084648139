import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import { VirtualItem } from "@tanstack/react-virtual";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { DashboardsContext, OverviewQueryTitles } from "../../../contexts/DashboardsContext";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import useBackwardsCompatible from "../../../hooks/useBackwardsCompatible";
import useBulkDashboards from "../../../hooks/useBulkDashboards";
import useBulkWorkspaces from "../../../hooks/useBulkWorkspaces";
import useDashboards from '../../../hooks/useDashboards';
import usePaginator from "../../../hooks/usePaginator";
import { getGroupDashboardQueriesFunc, refreshWorkspaceScoreFunc, sendShareWorkspaceEmailFunc, updateWorkspaceFunc } from "../../../lib/helper";
import { scrollbarStyle } from "../../../shared/dashboard";
import { Dashboard, DashboardQuery, Workspace } from "../../../types/files";
import ArrayUtils from "../../../utils/ArrayUtils";
import NoDashboardsFoundView from "../../molecules/dashboards-empty/NoDashboardsFoundView";
import DashboardsSearchOrCreate from '../../molecules/dashboards-search-create/DashboardsSearchOrCreate';
import DashboardsTableHeader from "../../molecules/dashboards-table/DashboardsTableHeader";
import DashboardsTableRow from "../../molecules/dashboards-table/DashboardsTableRow";
import { FallbackLoading } from "../../templates/loader";

const useSuccessStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: 'unset',
            maxHeight: 'unset',
            width: 'fit-content',
            height: 'fit-content',
            padding: '16px 24px',
            borderRadius: 24,
        },
    },
    dialogTitle: {
        display: 'flex',
        width: 'auto',
        height: 'auto',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.3rem',
    },
    dialogContent: {
        display: 'flex',
        width: 'auto',
        height: 'auto',
        flexDirection: 'column',
        padding: '8px 0',
        overflowY: 'hidden',
    },
    dialogActions: {
        display: 'flex',
        width: 'auto',
        height: 'auto',
        flexDirection: 'column',
        alignItems: 'center',
    },
    mailingList: {
        maxHeight: 240,
        height: '100%',
        width: '100%',
        overflowY: 'auto',
    },
    sharedIcon: {
        width: 40,
        height: 40,
        color: theme.palette.primary.main,
    },
    successText : {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    linkText: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['600'],
    },
    commonButton: {
        minWidth: 80,
        width: 'fit-content',
        borderRadius: 20,
        background: theme.palette.primary.main,
        color: 'white',
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const useMainStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            minWidth: 700,
            minHeight: 800,
            maxHeight: 800,
            width: 'fit-content',
            height: 'fit-content',
            padding: 20,
            borderRadius: 24,
        },
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.5rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: '8px 0',
        height: '100%',
        overflowY: 'hidden',
        gap: 8,
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    selected :{
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1rem',
        color: 'black',
    },
    headers: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        scrollBehavior: 'smooth',
        ...scrollbarStyle,
    },
    //
    table: {
        width: '100%',
        height: 'calc(100vh - 120px)',
    },
    header: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        scrollBehavior: 'auto',
        borderBottom: `1px solid ${theme.colors.neutral['400']}`,
        ...scrollbarStyle,
    },
    rows: {
        position: 'relative',
        minWidth: '100%',
        width: 'fit-content',
        height: '100%',
        overflow: 'auto',
        scrollBehavior: 'smooth',
        ...scrollbarStyle,
    },
    row: {
        position: 'absolute',
        top: 0,
        left: 0,
        minWidth: '100%',
        width: 'fit-content',
        height: 'auto',
        borderTop: `1px solid ${theme.colors.neutral['400']}`,
        background: `#fff`,
        "&:hover": {
            background: `rgb(246, 251, 251, 0.8)`,
        },
    },
    cancelButton: {
        width: 100,
        borderRadius: 40,
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    saveButton: {
        width: 100,
        borderRadius: 20,
        background: theme.palette.primary.main,
        color: 'white',
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    loadingText: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 700,
        color: theme.colors.neutral['900'],
    },
    loadingTextSub: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: '#9e9e9e',
    },
    loading: {
        width: '100%',
        height: '100%',
        color: theme.palette.primary.main,
    },
    checkBox: {
        padding: 'unset !important',
        marginRight: '4px !important',
        "& > svg": {
            width: '20px !important',
            height: '20px !important',
        }
    },
    checkBoxText: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['700'],
    },
}));

const collectionKey = 'dashboards:select';
const TableHeaders = ['Name', 'Fit score', '', 'Added'];
const CountDownSec = 3;

const FindInvestorsShareDealSuccessModal: React.FC<{
    isOpen: boolean,
    onClose: () => void,
}> = ({ isOpen, onClose }) => {
    const classes = useSuccessStyles();
    const [countDown, setCountDown] = useState<number>(CountDownSec);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    useEffect(() => {
        timerRef.current = setInterval(() => {
            setCountDown(prev => {
                if (prev > 1) {
                    return prev - 1;
                } else {
                    clearInterval(timerRef.current);
                    onClose();
                    return prev;
                }
            });
        }, 1000);

        return () => {
            clearInterval(timerRef.current);
        }
    }, [onClose]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={onClose}>
            <DialogContent className={classes.dialogContent}>
                <Stack spacing={2} alignItems="center" justifyContent="center" width="100%" py={3}>
                    <CheckCircleIcon className={classes.sharedIcon} />
                    <Typography className={classes.successText}>
                        {'Deals successfully sent'}
                    </Typography>
                </Stack>
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" alignItems="center" justifyContent="center" width="100%">
                    <Button variant="contained"
                        className={classes.commonButton}
                        onClick={onClose}> {`Close (${countDown})`} </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    </>);
}

const FindCompaniesToShareSuccessModal: React.FC<{
    isOpen: boolean,
    workspace?: Workspace,
    onClose: () => void,
}> = ({ isOpen, workspace, onClose }) => {
    const classes = useMainStyles();
    const { user, userGroup } = useContext(AuthContext);
    const { setWorkspaces } = useContext(GroupSettingsContext);
    const { mappedOverviewQueries, workspace: globalWorkspace, setWorkspace, dashboards, setDashboards, setDashboardsQueries } = useContext(DashboardsContext);
    const { assignToDashboards } = useBulkWorkspaces();
    const { getBulkDashboards, clearBulk} = useBulkDashboards();
    const { filterDashboardList } = useDashboards();
    const { dashboardWorkspaces } = useBackwardsCompatible();

    const [loading, setLoading] = useState<boolean>(true);
    const [shared, setShared] = useState<boolean>(false);
    const [mailables, setMailables] = useState<{workspace: Workspace, dashboards: Dashboard[]}[]>([]);
    const [searching, setSearching] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [dashboardArray, setDashboardArray] = useState<Dashboard[]|null>(null);
    const [sortBy, setSortBy] = useState<string>('Added');
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);
    const modalWrapperRef = useRef<HTMLDivElement>(null);
    const virtualRowsRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);

    const syncScroll = (e?: Event) => {
        e?.preventDefault();

        if (headerRef.current && virtualRowsRef.current)
            headerRef.current.scrollLeft = virtualRowsRef.current.scrollLeft;
    };

    const preventManualScroll = (e: Event) => {
        e.preventDefault();
        if (headerRef.current && virtualRowsRef.current)
            headerRef.current.scrollLeft = virtualRowsRef.current.scrollLeft;
    };

    const attachListeners = () => {
        if (!headerRef.current || !virtualRowsRef.current) return;

        virtualRowsRef.current.addEventListener("scroll", syncScroll);
        headerRef.current.addEventListener("wheel", preventManualScroll, { passive: false });
        headerRef.current.addEventListener("touchmove", preventManualScroll, { passive: false });
    };

    const detachListeners = () => {
        if (!headerRef.current || !virtualRowsRef.current) return;

        virtualRowsRef.current.removeEventListener("scroll", syncScroll);
        headerRef.current.removeEventListener("wheel", preventManualScroll);
        headerRef.current.removeEventListener("touchmove", preventManualScroll);
    };

    const localWorkspace = useMemo(() => workspace ?? globalWorkspace, [globalWorkspace, workspace]);

    const bulkDashboards = useMemo(() =>
        getBulkDashboards(collectionKey).map(bulk => bulk.dashboard)
    , [getBulkDashboards]);

    const sortedDashboards = useMemo(() => {
        if (sortBy === 'Fit score') {
            return Array.from(new Map(
                [...dashboardArray ?? []].map(dashboard => {
                    const investmentThesis: { workspaceId: string, score: number}[]
                        = mappedOverviewQueries.get(`${dashboard.id}:Investment Thesis`) || [];
                    const score = investmentThesis?.find(iT => iT.workspaceId === localWorkspace!.id)?.score ?? 0;

                    return [dashboard, score];
                }))).sort((a, b) => b[1] - a[1]).map(entry => entry[0]);
        }

        // else sort by 'Added'
        return [...dashboardArray ?? []].sort((prev: Dashboard, next: Dashboard) => {
            const prevDate = prev.createdAt;
            const nextDate = next.createdAt;

            return nextDate.localeCompare(prevDate);
        });
    // eslint-disable-next-line
    }, [dashboardArray, sortBy]);

    const { allRows, rowVirtualizer, hasNextPage, isFetching } = usePaginator({
        key: 'add-companies-to-investor-modal-166',
        source: sortedDashboards,
        limit: 20,
        itemHeight: 60,
        virtualRowsRef,
    });

    const handleShareEmails = useCallback(async () => {
        return new Promise<Dashboard[] | any>(async (resolve) => {
            const updatePromises: Promise<Workspace>[] = [];
            const assignPromises: Promise<Dashboard[]>[] = [];
            const sharePromises: Promise<string | any>[] = [];

            setLoading(true);
            mailables.forEach(({workspace, dashboards}) => {
                if (!workspace.isPublic)
                    updatePromises.push(updateWorkspaceFunc({...workspace, isPublic: true, }));

                assignPromises.push(new Promise<Dashboard[]>(async (resolve) => {
                    const unassignedIds = dashboards.filter(dashboard =>
                        !dashboardWorkspaces(dashboard).includes(workspace.id)
                    ).map(dashboard => dashboard.id);
                    const dashboardsData: Dashboard[] = await assignToDashboards(collectionKey, unassignedIds) ?? [];

                    resolve(dashboardsData);
                }));

                sharePromises.push(sendShareWorkspaceEmailFunc({
                    workspaceId: workspace.id,
                    toAddress: workspace.email,
                    replyToAddress: user.attributes.email,
                    dashboardIds: dashboards.map(dashboard => dashboard.id),
                }));
            });

            Promise.all([Promise.all(updatePromises), Promise.all(assignPromises)])
                .then(async ([updatedWorkspaces, multiUpdatedDashboards]) => {
                const updatedDashboards = multiUpdatedDashboards.flat();

                setWorkspaces(prev => prev.map(ws => updatedWorkspaces?.find(updated => updated.id === ws.id) ?? ws));
                setDashboards(prev => ArrayUtils.sortByDescending(prev.map(current => {
                    const updates = updatedDashboards.filter(updated => updated.id === current.id);

                    if (!!updates.length) {
                        const dashboardUpdate = ArrayUtils.sortByDescending(updates, 'updatedAt')[0];

                        return dashboardUpdate;
                    }

                    return current;
                }), 'createdAt'));

                await Promise.all(sharePromises);
                resolve(true);
            }).finally(() => setShared(true));
        });
    // eslint-disable-next-line
    }, [mailables, user]);

    const handleRefresh = useCallback((refresh?: boolean) => {
        if (!!localWorkspace) {
            const promises: Promise<any>[] = [];

            if (refresh)
                updateWorkspaceFunc(localWorkspace).then((updatedWorkspace?: Workspace) => setWorkspace({...updatedWorkspace!}));

            dashboards.forEach(dashboard => promises.push(
                refreshWorkspaceScoreFunc({
                    dashboardId: dashboard.id,
                    workspaceId: localWorkspace.id,
                    group: userGroup,
                })
            ));

            Promise.all(promises).then(() => {
                setSearching(true);
            });
        }
    // eslint-disable-next-line
    }, [localWorkspace, dashboards, userGroup]);
    
    useEffect(() => {
        setMailables([{ workspace: localWorkspace!, dashboards: bulkDashboards }]);
    }, [bulkDashboards, localWorkspace]);

    useEffect(() => {
        if (!loading) {
            const observer = new MutationObserver(() => {
                if (headerRef.current && virtualRowsRef.current) {
                    clearTimeout(timerRef.current);
                    timerRef.current = setTimeout(() => attachListeners(), 500);
                }
            });

            if (modalWrapperRef.current)
                observer.observe(modalWrapperRef.current, { childList: true, subtree: true });
            timerRef.current = setTimeout(() => attachListeners(), 500);

            return () => {
                observer.disconnect();
                detachListeners();
                clearTimeout(timerRef.current);
            };
        }
    // eslint-disable-next-line
    }, [isOpen, loading]);

    useEffect(() => {
        setSearching(false);

        return () => {
            clearBulk(collectionKey);
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (searching) {
            timerRef.current = setTimeout(() => {
                clearInterval(timerRef.current);
                getGroupDashboardQueriesFunc(userGroup).then((dashboardQueriesData) => {
                    setDashboardsQueries(dashboardQueriesData.filter((query: DashboardQuery) =>
                        OverviewQueryTitles.includes(query.title)
                    ));
                    setSearching(false);
                    setSortBy('Fit score');
                });
                timerRef.current = setTimeout(() => {
                    clearInterval(timerRef.current);
                    setSearching(false);
                    setSortBy('Fit score');
                }, 5000);
            }, 10000);
        }

        return () => {
            clearInterval(timerRef.current);
        }
    // eslint-disable-next-line
    }, [searching]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setDashboardArray(filterDashboardList(search || ''));
            clearTimeout(timerRef.current);
        }, 500);

        return () => {
            clearTimeout(timerRef.current);
        }
    }, [filterDashboardList, search]);

    useEffect(() => {
        setLoading((!!timerRef.current && !dashboardArray)|| isFetching);
    }, [dashboardArray, isFetching]);

    return (<>
        {shared ? (<>
            <FindInvestorsShareDealSuccessModal isOpen={shared} onClose={onClose} />
        </>) : (<>
            <Dialog className={classes.dialog} open={isOpen} onClose={!loading && !searching ? onClose : undefined} ref={modalWrapperRef}>
                <DialogTitle className={classes.dialogTitle}>
                    <span>{`Find companies to share with ${localWorkspace?.name}`}</span>
                </DialogTitle>
                <Divider sx={{ margin: '8px 0'}} />
                <DialogContent className={classes.dialogContent}>
                    <DashboardsSearchOrCreate search mediumFit onSearch={(value) => setSearch(value)} />
                    <Stack className={classes.table}>
                        {loading ? (<FallbackLoading />) : !!sortedDashboards?.length ? (<>
                            <Stack alignItems="flex-start" justifyContent="flex-start" height="100%">
                                <Box width="100%" height="fit-content" overflow="hidden">
                                    <Box className={classes.header} ref={headerRef}>
                                        <DashboardsTableHeader
                                            columns={TableHeaders}
                                            columnSorted={sortBy}
                                            onColumnSort={setSortBy}
                                            onRefreshScores={handleRefresh}
                                            noStatus />
                                    </Box>
                                </Box>
                                <Box width="100%" height="100%" overflow="hidden">
                                    <Box className={classes.rows} ref={virtualRowsRef}
                                        sx={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                                        {rowVirtualizer.getVirtualItems().map((virtualRow: VirtualItem) => (
                                            <Box className={classes.row}
                                                sx={{  height: `${virtualRow.size}px`, transform: `translateY(${virtualRow.start}px)`, }}
                                                key={'add-companies-to-investor-399-' + virtualRow.index} >
                                                {(virtualRow.index > allRows.length - 1) && hasNextPage
                                                    ? (<FallbackLoading />)
                                                    : (<DashboardsTableRow
                                                            dashboard={allRows[virtualRow.index]}
                                                            workspace={workspace ?? undefined}
                                                            columns={TableHeaders}
                                                            collectionKey={collectionKey}
                                                            refreshingScore={searching}
                                                            isPublic noStatus />)}
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </Stack>
                        </>) : (
                            <Stack className={classes.headers} alignItems="flex-start" justifyContent="flex-start">
                                <DashboardsTableHeader columns={TableHeaders} />
                                <Stack className={classes.rows} alignItems="flex-start" justifyContent="flex-start">
                                    <NoDashboardsFoundView
                                        noDataYet={!dashboards.length}
                                        noCompaniesYet={!search}
                                        noCompaniesFound />
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Stack direction="row" alignItems="center" justifyContent="flex-start" width="100%" ml={1}>
                        <Typography className={classes.selected}>
                            {`${bulkDashboards.length} compan${bulkDashboards.length > 1 ? 'ies': 'y'} selected`}
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" width="100%">
                        <Button className={classes.cancelButton}
                            disabled={searching}
                            onClick={onClose}> {'Cancel'} </Button>
                        <LoadingButton variant="contained"
                            className={classes.saveButton}
                            loading={loading}
                            disabled={searching}
                            onClick={handleShareEmails}
                        > {'Send'} </LoadingButton>
                    </Stack>
                </DialogActions>
            </Dialog>
    </>)}</>);
}

export default FindCompaniesToShareSuccessModal;