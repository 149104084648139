import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LinkIcon from '@mui/icons-material/Link';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Button, ClickAwayListener, Fade, Paper, Popper, Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as NotesIcon } from "../../../../public/svgs/edit.svg";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import { DashboardsContext } from '../../../contexts/DashboardsContext';
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import useBulkDashboards from '../../../hooks/useBulkDashboards';
import useRoute from "../../../hooks/useRoute";
import useWorkspace from "../../../hooks/useWorkspace";
import { deleteWorkspaceFunc } from "../../../lib/helper";
import theme from '../../../theme';
import ConfirmDialog from '../../modals/ConfirmDialog';
import CreateEditInvestorModal from "../../modals/investors/CreateEditInvestorModal";
import PreferencesModal from "../../modals/investors/PreferencesModal";
import AddCompaniesToInvestorModal from '../../modals/multi-action/AddCompaniesToInvestorModal';
import FindCompaniesToShareModal from "../../modals/multi-action/FindCompaniesToShareModal";
import WorkspaceShareEmailModal from "../../modals/workspaces/WorkspaceShareEmailModal";
import WorkspaceShareModal from "../../modals/workspaces/WorkspaceShareModal";
import { collectionKey } from "../../organisms/dashboards/DashboardsView";

const useStyles = makeStyles((theme) => ({
    popup: {
        zIndex: 1500,
    },
    modal: {
        width: 270,
        height: 'fit-content',
        padding: '12px 8px 0px 8px',
        border: '1px solid lightgray',
        borderRadius: 12,
    },
    title: {
        fontFamily: 'Poppins',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: 'black',
        marginLeft: 8,
    },
    actionButton: {
        width: 'auto',
        height: 40,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    button: {
        width: '100%',
        padding: '6px 8px !important',
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: 'black',
        textTransform: 'none',
        justifyContent: 'flex-start',
        "& > .MuiButton-startIcon": {
            "& > .MuiSvgIcon-root": {
                width: 24,
                height: 24,
                color: theme.palette.primary.light,
            }
        },
    },
}));

const WorkspaceActions: React.FC<{}> = () => {
    const classes = useStyles();
    const { workspace } = useContext(DashboardsContext);
    const { setWorkspaces } = useContext(GroupSettingsContext);
    const { clearBulk} = useBulkDashboards();
    const { redirectFromWorkspace } = useRoute();
    const { sharePublicly } = useWorkspace();

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<string>('');
    const [shareEmailModalOpen, setShareEmailModalOpen] = useState<boolean>(false);
    const [addToInvestorModalOpen, setAddToInvestorModalOpen] = useState<boolean>(false);
    const [preferencesModalOpen, setPreferencesModalOpen] = useState<boolean>(false);
    const [shareCompaniesModalOpen, setShareCompaniesModalOpen] = useState<boolean>(false);
    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
    const [editInvestorOpen, setEditInvestorOpen] = useState<boolean>(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleSharePublic = useCallback(async () => {
        if (!!workspace) {
            setShareModalOpen(false);
            await sharePublicly(true, workspace);
        }
    // eslint-disable-next-line
    }, [workspace]);

    const handleDelete = useCallback(() => {
        setLoading('delete');
        deleteWorkspaceFunc({ id: workspace!.id }).then(() => {
            setLoading('');
            setConfirmDeleteOpen(false);
            setWorkspaces(prev => prev.filter(ws => ws.id !== workspace!.id));
        }).finally(() => {
            redirectFromWorkspace();
        });
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setLoading('');
        clearBulk(collectionKey);

        return () => {
            setLoading('');
            clearBulk(collectionKey);
        };
    // eslint-disable-next-line
    }, []);

    return (<>
        <Button variant="outlined"
            className={classes.actionButton}
            endIcon={<ArrowDropDownIcon sx={{ transform: 'scale(1.25, 2.25)'}} />}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setModalOpen(prev => !prev);
            }} ref={anchorRef}
        >{`Actions`} </Button>
        <Popper open={modalOpen}
            anchorEl={anchorRef.current}
            className={classes.popup}
            placement="bottom-start" transition>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                    <Box width="fit-content" height="fit-content">
                        <ClickAwayListener onClickAway={() => setModalOpen(false)}>
                            <Paper className={classes.modal} elevation={3} square={false}>
                                <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="center" width="100%" mb={1}>
                                    <Stack direction="column" alignItems="flex-start" justifyContent="center">
                                        {/* <Button className={classes.button}
                                            startIcon={<ShareIcon width={24} height={24} stroke={theme.palette.primary.light} />}
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setModalOpen(false);
                                                setShareEmailModalOpen(true);
                                            }}> {'Send workspace'} </Button> */}
                                        {/* <Button className={classes.button}
                                            startIcon={<AddCircleOutlineIcon />}
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setModalOpen(false);
                                                setAddToInvestorModalOpen(true);
                                            }} > {'Add companies to share'} </Button> */}
                                        <Button className={classes.button}
                                            startIcon={<ShareIcon width={24} height={24} stroke={theme.palette.primary.light} />}
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setModalOpen(false);
                                                setShareCompaniesModalOpen(true);
                                            }} > {'Find companies to share'} </Button>
                                        <Button className={classes.button}
                                            startIcon={<TuneIcon sx={{ color: "#7bd4d4" }} />}
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setModalOpen(false);
                                                setPreferencesModalOpen(true);
                                            }} > {'Update deal preferences'} </Button>
                                        <Button className={classes.button}
                                            startIcon={<LinkIcon sx={{ width: 20, height: 20, color: theme.palette.primary.light }} />}
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setModalOpen(false);
                                                setShareModalOpen(true);
                                            }} > {'Copy link to workspace'} </Button>
                                        <Button className={classes.button}
                                            startIcon={<NotesIcon width={24} height={20} fill={theme.palette.primary.light} />}
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setModalOpen(false);
                                                setEditInvestorOpen(true);
                                            }} > {'Update workspace'} </Button>
                                        <Button variant="text"
                                            className={classes.button}
                                            startIcon={<DeleteOutlineIcon
                                                sx={{ color: `${theme.colors.error['500']} !important` }} />}
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setModalOpen(false);
                                                setConfirmDeleteOpen(true);
                                            }}
                                            sx={{ color: `${theme.colors.error['500']} !important` }}
                                        > {'Delete workspace'} </Button>
                                    </Stack>
                                </Stack>
                            </Paper>
                        </ClickAwayListener>
                    </Box>
                </Fade>
            )}
        </Popper>
        {shareEmailModalOpen && (
            <WorkspaceShareEmailModal
                isOpen={shareEmailModalOpen}
                workspace={workspace!}
                onClose={() => setShareEmailModalOpen(false)}
            />
        )}
        {addToInvestorModalOpen && (
            <AddCompaniesToInvestorModal
                isOpen={addToInvestorModalOpen}
                workspace={workspace!}
                onClose={() => setAddToInvestorModalOpen(false)}/>
        )}
        {shareCompaniesModalOpen && (
            <FindCompaniesToShareModal
                isOpen={shareCompaniesModalOpen}
                workspace={workspace!}
                onClose={() => setShareCompaniesModalOpen(false)}/>
        )}
        {preferencesModalOpen &&  (
            <PreferencesModal
                isOpen={preferencesModalOpen}
                workspace={workspace!}
                onClose={() => setPreferencesModalOpen(false)}
            />
        )}
        {shareModalOpen &&  (
            <WorkspaceShareModal
                isOpen={shareModalOpen}
                workspace={workspace!}
                onShare={handleSharePublic}
                onClose={() => setShareModalOpen(false)}
            />
        )}
        {editInvestorOpen && (
            <CreateEditInvestorModal
                isOpen={editInvestorOpen}
                workspaceId={workspace!.id}
                onClose={() => setEditInvestorOpen(false)}
            />
        )}
        <ConfirmDialog
            title="Delete confirmation"
            content="Are you sure you want to delete this investor?"
            open={confirmDeleteOpen}
            loading={loading === 'delete'}
            confirmCallback={handleDelete}
            cancelCallback={() => setConfirmDeleteOpen(false)}
        />
    </>);
}

export default WorkspaceActions;
