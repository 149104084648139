import { Box, Stack } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import KeyMetricsColumnQueries from './KeyMetricsColumnQueries';
import PeopleColumnQueries from './PeopleColumnQueries';
import { useContext, useState, useCallback } from "react";
import {DashboardContext} from "../../../contexts/DashboardContext";
import {GroupSettingsContext} from "../../../contexts/GroupSettingsContext";
import { updateDashboardFunc } from "../../../lib/helper";
import ObjectUtils from "../../../utils/ObjectUtils";
import MultiSelector from "../../atoms/MultiSelector";
import Commentary from "../../molecules/dashboard-notes/Commentary";
import DealOwner from "../../molecules/dashboard-props/DealOwner";
import DealSource from "../../molecules/dashboard-props/DealSource";
import DealStage from "../../molecules/dashboard-props/DealStage";
import DeckLink from "../../molecules/dashboard-props/DeckLink";
import DigestQuery from "../../molecules/dashboard-queries/DigestQuery";
import FoundedQuery from "../../molecules/dashboard-queries/FoundedQuery";
import FundingQuery from "../../molecules/dashboard-queries/FundingQuery";
import HeadcountQuery from "../../molecules/dashboard-queries/HeadcountQuery";
import LocationQuery from "../../molecules/dashboard-queries/LocationQuery";
import MarketSizeOverviewQuery from "../../molecules/dashboard-queries/MarketSizeOverviewQuery";
import UrlQuery from "../../molecules/dashboard-queries/UrlQuery";

const useStyles = makeStyles((theme) => ({
    queriesContainer: {
        marginBottom: 24,
        overflowX: 'hidden',
        position: 'relative',
    },
    masonryElement: {
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        paddingTop: 16,
        paddingBottom: 16,
        borderRadius: 16,
        border: `2px solid ${theme.colors.primary['100']}`,
        background: theme.colors.primary['50'],
        transition: 'all 0.5s ease',
    },
}));

const DashboardQueries: React.FC<{}> = () => {
    const classes = useStyles();
    const { tags } = useContext(GroupSettingsContext);
    const { dashboard, isPublicView } = useContext(DashboardContext);
    const [updating, setUpdating] = useState<boolean>(false);
    const [hidePeopleColumn, setHidePeopleColumn] = useState<boolean>(false);

    const handleSelectTags = useCallback(async (selections: string[]) => {
        if (!ObjectUtils.equalObjects(dashboard!.tags, selections)) {
            dashboard!.tags = selections;
            setUpdating(true);
            updateDashboardFunc(dashboard).then(() => setUpdating(false));
        }
    }, [dashboard]);

    return (<>
        <Box className={classes.queriesContainer}>
            <Stack direction="row" className={classes.masonryElement}>
                <Stack direction="column" spacing={2} justifyContent="flex-start" width={hidePeopleColumn ? '38%': '30%'}>
                    <DigestQuery />
                    <Stack px={2} width="100%">
                        <MultiSelector
                            mainOptions={tags}
                            selections={dashboard!.tags || []}
                            onSelection={handleSelectTags}
                            readOnly={isPublicView}
                            loading={updating}
                            limit={3}
                            fullSpan />
                    </Stack>
                    <Stack direction="column" spacing={1} alignItems="flex-start" width="95%" pl={2} pt={1}>
                        <UrlQuery queryTitle="Website" />
                        <UrlQuery queryTitle="LinkedIn" />
                    </Stack>
                    <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="flex-start" p={1} width="100%">
                        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" width="100%">
                            <LocationQuery />
                            <DeckLink dashboard={dashboard!} />
                        </Stack>
                        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" width="100%">
                            <FoundedQuery />
                            <DealOwner dashboard={dashboard!} />
                        </Stack>
                        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" width="100%">
                            <HeadcountQuery />
                            <DealSource dashboard={dashboard!} />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction="column" spacing={2} justifyContent="flex-start" width={hidePeopleColumn ? '0%': '25%'} px={1}>
                    <PeopleColumnQueries onHidePeopleColumn={setHidePeopleColumn} />
                </Stack>
                <Stack direction="column" spacing={3} justifyContent="flex-start" width={hidePeopleColumn ? '28%': '20%'}>
                    <DealStage />
                    <KeyMetricsColumnQueries />
                </Stack>
                <Stack direction="column" spacing={2} justifyContent="flex-start" width={hidePeopleColumn ? '33%': '25%'}>
                    <Stack px={2} width="100%">
                        <MarketSizeOverviewQuery />
                    </Stack>
                    <Stack px={2} width="100%">
                        <FundingQuery />
                    </Stack>
                    <Stack px={2} width="100%">
                        <Commentary />
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    </>);
}

export default DashboardQueries;
