import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getDashboardById, getPublicDashboardById, getPublicFilesForDashboardFunc, getPublicWorkspaceFunc } from "../../lib/helper";
import { TabContext, TabPanel } from "@mui/lab";
import CrunchbaseModal from "../modals/dashboard-details/CrunchbaseModal";
// import DashboardClipboard from "../organisms/dashboard-details/DashboardClipboard";
import DashboardNotes from "../organisms/dashboard-details/DashboardNotes";
import DashboardFiles from "../organisms/dashboard-details/DashboardFiles";
import useCrunchbaseLoader from "../../hooks/useCrunchbaseLoader";
import DashboardLoading from "../molecules/dashboard-loading/DashboardLoading";
import DashboardOverview from "../organisms/dashboard-details/DashboardOverview";
import DashboardHeader from "../organisms/dashboard-details/DashboardHeader";
import { CompanyFile, UnassignedContentType, Workspace } from "../../types/files";
import {DashboardContext} from "../../contexts/DashboardContext";
import PublicDashboardNotFound from "../organisms/dashboard-details/DashboardNotFound";
import { ViewModes } from "../molecules/dashboard-header/DashboardTabs";
import DashboardContent from "../organisms/dashboard-details/DashboardContent";
import { FallbackLoading } from "../templates/loader";
import useRoute from "../../hooks/useRoute";
import {GroupSettingsContext} from "../../contexts/GroupSettingsContext";
import {DashboardsContext} from "../../contexts/DashboardsContext";
import DashboardAllowAccess from "../organisms/dashboard-details/DashboardAllowAccess";
import {AuthContext} from "../../contexts/AuthContext";
import storage from "../../utils/storage";
import useBackwardsCompatible from "../../hooks/useBackwardsCompatible";
import {FileStructureContext} from "../../contexts/FileStructureContext";

type DashboardAccessType = {
    iss?: string | null,
    sub: string,
    exp: number | null,
};

const useStyles = makeStyles(() => ({
    rootContainer: {
        height: '100%',
        maxWidth: 'unset !important',
        overflowY: 'hidden',
    },
    tabsContainer: {
        '-moz-transition': 'height .3s',
        '-ms-transition': 'height .3s',
        '-o-transition': 'height .3s',
        '-webkit-transition': 'height .3s',
        transition: 'height .3s ease-out',
        background: 'white',
        zIndex: 1,
    },
    tab: {
        height: '100%',
        width: '100%',
        padding: 'unset',
    },
}));

const DashboardDetailsPage: React.FC<{
    publicDashboard?: boolean,
    sharedDashboard?: boolean
}> = ({ publicDashboard, sharedDashboard }) => {
    const classes = useStyles();
    const params: {[key: string]: string} = useParams();
    const { user, userGroup } = useContext(AuthContext);
    const { workspaces } = useContext(GroupSettingsContext);
    const { fileStructure, initialLoading } = useContext(FileStructureContext);
    const { workspace, setWorkspace } = useContext(DashboardsContext);
    const { dashboard, setDashboard, setPublicView, setShared } = useContext(DashboardContext);
    const { queriesLoaded, crunchbaseLoaded, crunchbaseLookup, saveCrunchbase } = useCrunchbaseLoader(false);
    const { getURLFrom } = useRoute();
    const { dashboardWorkspaces } = useBackwardsCompatible();

    const [mode, setMode] = useState<string>(ViewModes.Overview);
    const [selectedFile, setSelectedFile] = useState<CompanyFile | undefined>(undefined);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [checkedAccess, setCheckedAccess] = useState<boolean>(false);
    const [hasPublicAccess, setWithPublicAccess] = useState<boolean>(false);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const isInvalidPublicDashboard = useMemo(() =>
        loaded && publicDashboard && (!dashboard || dashboard?.isPublic === false)
    , [loaded, publicDashboard, dashboard]);

    const isValidatingPublicDashboard = useMemo(() =>
        loaded && (!userGroup && publicDashboard) && (dashboard?.isPublic) && (!checkedAccess || !hasPublicAccess)
    , [checkedAccess, hasPublicAccess, loaded, publicDashboard, userGroup, dashboard?.isPublic]);

    const handleStoreAccess = useCallback((workspace?: Workspace) => {
        if (!workspace)
            return;

        const stream = storage.getItem('config.workspace-public');
        const issuer = workspace.email || '';
        const accessData: DashboardAccessType = {
            iss: issuer,
            sub: workspace.id,
            exp: null,
        };

        if (!!stream) {
            const accessList = (JSON.parse(atob(stream)) ?? []) as DashboardAccessType[];
            const lookUpAccess = accessList.find(access => (access.iss === issuer && access.sub === workspace.id));

            if (!lookUpAccess) {
                accessList.push(accessData);
                storage.setItem('config.workspace-public', btoa(JSON.stringify(accessList)));
            }
        } else {
            storage.setItem('config.workspace-public', btoa(JSON.stringify([accessData])));
        }
    }, []);

    const handleVerifyAccess = useCallback((workspace?: Workspace) => {
        if (!workspace)
            return;

        timerRef.current = setTimeout(() => {
            const stream = storage.getItem('config.workspace-public');
            const issuer = workspace.email || '';

            if (!!stream) {
                const accessList = (JSON.parse(atob(stream)) ?? []) as DashboardAccessType[];
                const lookUpAccess = accessList.find(access => (access.iss === issuer && access.sub === workspace.id));

                if (!!lookUpAccess) {
                    if (!lookUpAccess.iss || (Number(lookUpAccess.exp) > Math.floor(Date.now() / 1000)))
                        setWithPublicAccess(true);
                    if (!lookUpAccess.iss || !!lookUpAccess.exp)
                        setCheckedAccess(true);
                }
            }

            setLoaded(true);
            clearTimeout(timerRef.current);
        }, 500);
    }, []);

    const handleConfirmAccess = useCallback((email: string) => {
        timerRef.current = setTimeout(() => {
            const stream = storage.getItem('config.workspace-public');

            if (!!stream) {
                const accessList = (JSON.parse(atob(stream)) ?? []) as DashboardAccessType[];
                const lookUpAccess = accessList.find(access => (access.iss === email && access.sub === workspace?.id));

                if (!!lookUpAccess) {
                    lookUpAccess.exp = Math.floor(Date.now() / 1000) + (24 * 60 * 60);
                    storage.setItem('config.workspace-public', btoa(JSON.stringify(accessList)));
                    setWithPublicAccess(true);
                }
                setCheckedAccess(true);
            }
            clearTimeout(timerRef.current);
        }, 1000);
    }, [workspace]);

    useEffect(() => {
        setDashboard(null);
        setPublicView(publicDashboard || false);
        setShared(sharedDashboard || false);

        return () => {
            setDashboard(null);
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const dashboardId = params.dashboardId;
        const from = getURLFrom();

        if (!initialLoading && dashboardId) {
            ((publicDashboard || sharedDashboard)
                ? getPublicDashboardById(dashboardId)
                : getDashboardById(dashboardId))
                .then((dashboardData) => {
                    setDashboard(dashboardData);
                    if (publicDashboard || sharedDashboard) {
                        if (!!user.attributes.email) {
                            setSelectedFile(fileStructure.find(file => !file.isDirectory && file.dashboardId === dashboardId && file.isDeck));
                        } else {
                            getPublicFilesForDashboardFunc(dashboardData.id).then(files => {
                                files?.forEach((file: CompanyFile) => {
                                    if (file.isDeck)
                                        setSelectedFile(file);
                                })
                            });
                        }
                    }
                    if ((!!from && from.origin === 'workspaces' && !!from.originId)
                        && dashboardWorkspaces(dashboardData)?.includes(from.originId)) {
                        if (publicDashboard || sharedDashboard) {
                            getPublicWorkspaceFunc({
                                id: from.originId,
                                group: getURLFrom()?.originGroup || userGroup,
                            }).then((data) => {
                                setWorkspace(data?.workspace || null);
                                if (!!data?.workspace) {
                                    handleStoreAccess(data?.workspace);
                                    handleVerifyAccess(data?.workspace);
                                } else {
                                    setLoaded(true);
                                }
                            });
                        } else {
                            const lookUpWorkspace = workspaces.find(workspace => workspace.id === from.originId);

                            if (!!lookUpWorkspace)
                                setWorkspace(prev => !prev ? lookUpWorkspace : prev);
                            setLoaded(true);
                        }
                    } else {
                        if (publicDashboard || sharedDashboard) {
                            setWithPublicAccess(true);
                            setCheckedAccess(true);
                        }
                        setLoaded(true);
                    }
                });
        }
    // eslint-disable-next-line
    }, [params.dashboardId, publicDashboard, initialLoading]);

    useEffect(() => {
        const viewMode = (params.viewMode || '').trim();

        if ((Object.values(ViewModes) as string[]).includes(viewMode))
            setMode(viewMode);
    }, [params.viewMode]);

    if (!loaded)
        return (<FallbackLoading />);

    if (isInvalidPublicDashboard)
        return (<PublicDashboardNotFound />);

    if (isValidatingPublicDashboard)
        return (<DashboardAllowAccess invalid={checkedAccess && !hasPublicAccess} onConfirm={handleConfirmAccess} />);

    return (<>
        <Container className={classes.rootContainer}>
            <TabContext value={mode}>
                {!queriesLoaded ? (
                    crunchbaseLoaded && (<DashboardLoading />)
                ): (<>
                    <DashboardHeader viewMode={mode} onSetViewMode={setMode} selectedFile={selectedFile} />
                    <Stack className={classes.tabsContainer} height={(mode !== ViewModes.Files) ? "calc(100% - 160px)" : "calc(100% - 60px)"}>
                        <TabPanel className={classes.tab} value={ViewModes.Overview}>
                            <DashboardOverview />
                        </TabPanel>
                        <TabPanel className={classes.tab} value={ViewModes.Files}>
                            <DashboardFiles selectedFile={selectedFile} onSetSelectedFile={setSelectedFile} />
                        </TabPanel>
                        <TabPanel className={classes.tab} value={ViewModes.Emails}>
                            <DashboardContent type={UnassignedContentType.EMAIL} />
                        </TabPanel>
                        <TabPanel className={classes.tab} value={ViewModes.Notes}>
                            <DashboardNotes />
                        </TabPanel>
                        {/* <TabPanel className={classes.tab} value={ViewModes.Clipboard}>
                            <DashboardClipboard />
                        </TabPanel> */}
                        <TabPanel className={classes.tab} value={ViewModes.Calls}>
                            <DashboardContent type={UnassignedContentType.CALL} />
                        </TabPanel>
                        {/* <TabPanel className={classes.tab} value={ViewModes.Others}>
                            <DashboardContent type={UnassignedContentType.OTHER} />
                        </TabPanel> */}
                    </Stack>
                </>)}
            </TabContext>
        </Container>
        {!publicDashboard && (
            <CrunchbaseModal
                open={crunchbaseLookup}
                onSave={saveCrunchbase}
            />
        )}
    </>);
}

export default DashboardDetailsPage;
