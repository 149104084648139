import { Box, Button, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import { defaultStages } from "../../../shared/dashboard";
import MultiSelector from "../../atoms/MultiSelector";

const useStyles = makeStyles((theme) => ({
    title: {
        minWidth: 70,
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['700'],
    },
    label: {
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 600,
        color: theme.palette.primary.main,
    },
    clear: {
        minWidth: 80,
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 600,
        color: theme.palette.warning.dark,
        textTransform: 'none !important' as any,
        transition: 'ease-in-out 300ms',
        "&:hover": {
            background: 'transparent',
        }
    },
    count: {
        minWidth: 100,
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    cropped: {
        maxWidth: 300,
        minHeight: 35,
        maxHeight: 70,
        overflowX: 'hidden',
        overflowY: 'auto',
    },
}));

const DashboardsTableFilter: React.FC<{
    count?: number,
    onEnd?: boolean,
    onFilter?: ({ tags, stage } : { tags: string[],  stage: string[], }) => void,
}> = ({ count, onEnd, onFilter }) => {
    const classes = useStyles();
    const { tags } = useContext(GroupSettingsContext);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [selectedStages, setSelectedStages] = useState<string[]>([]);

    const hasSelection = useMemo(() =>
        !![...selectedTags, ...selectedStages].length
    , [selectedStages, selectedTags]);

    const handleFilter = useCallback((selections: string[], type?: string) => {
        switch (type) {
            case 'tags':
                setSelectedTags(selections);
                onFilter?.({ tags: selections, stage: selectedStages });
                break;
            case 'stage':
                setSelectedStages(selections);
                onFilter?.({ tags: selectedTags, stage: selections });
                break;
            default:
                setSelectedTags([]);
                setSelectedStages([]);
                onFilter?.({ tags: [], stage: [] });
                break;
        }
    }, [onFilter, selectedStages, selectedTags]);

    return (<>
        <Stack direction="row" alignItems="center" 
            justifyContent={!!count ? 'space-between' : onEnd ? 'flex-end': 'flex-start'} width="100%">
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
                <Typography className={classes.title}>{'View by:'}</Typography>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography className={classes.label}>
                            {'sector: '}
                        </Typography>
                        <Box className={classes.cropped}>
                            <MultiSelector
                                mainOptions={tags}
                                selections={selectedTags}
                                onSelection={(selections) => handleFilter(selections as string[], 'tags')}
                                fullSpan
                                withSearch />
                        </Box>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography className={classes.label}>
                            {'stage: '}
                        </Typography>
                        <Box className={classes.cropped}>
                            <MultiSelector
                                mainOptions={defaultStages}
                                selections={selectedStages}
                                onSelection={(selections) => handleFilter(selections as string[], 'stage')}
                                fullSpan />
                        </Box>
                    </Stack>
                    {hasSelection && (
                        <Button variant="text"
                            className={classes.clear}
                            onClick={() => handleFilter([])}>
                            {'clear all'}
                        </Button>
                    )}
                </Stack>
            </Stack>
            {!!count && (
                <Stack direction="row" alignItems="center" justifyContent="flex-end">
                    <Typography className={classes.count}>
                        {`${count} compan${count > 1 ? 'ies' : 'y'}`}
                    </Typography>
                </Stack>
            )}
        </Stack>
    </>);
}

export default DashboardsTableFilter;