import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    image: {
        width: 80,
        height: 'auto',
    },
    mainText: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 500,
        color: theme.colors.neutral['600'],
    },
    mainLabel: {
        fontFamily: 'Inter',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    secondText: {
        fontFamily: 'Lato',
        fontSize: '1.2rem',
        fontWeight: 400,
        color: theme.colors.neutral['600'],
        textAlign: 'center',
    },
}));

const NoDashboardsFoundView: React.FC<{
    noDataYet?: boolean,
    noCompaniesFound?: boolean,
    noCompaniesYet?: boolean,
}> = ({ noDataYet, noCompaniesYet, noCompaniesFound, }) => {
    const classes = useStyles();

    return (<>
        {(noDataYet) ? (<>
            <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
                <img src="../../images/missing-dashboard.png" alt="missing-dashboard" className={classes.image} />
                <Typography className={classes.mainText} mt={4}>{'No data yet.'}</Typography>
            </Stack>
        </>) : (noCompaniesYet) ? (<>
            <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
                <img src="../../images/missing-dashboard.png" alt="missing-dashboard" className={classes.image} />
                <Typography className={classes.mainText} mt={4}>{'No companies yet!'}</Typography>
            </Stack>
        </>) : (noCompaniesFound) ? (<>
            <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
                <img src="../../images/missing-dashboard.png" alt="missing-dashboard" className={classes.image} />
                <Typography className={classes.secondText} mt={4}>{'No companies found!'}</Typography>
            </Stack>
        </>) : (<>
            <Stack alignItems="center" spacing={1} justifyContent="flex-start" width="100%" height="100%" mt={5}>
                <img src="../../images/missing-dashboard.png" alt="missing-dashboard" className={classes.image} />
            </Stack>
        </>)}
    </>);
}

export default NoDashboardsFoundView;
