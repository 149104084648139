import { memo, useContext, useMemo } from "react";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import useBulkDashboards from "../../../hooks/useBulkDashboards";
import { Dashboard } from "../../../types/files";
import CheckSelect from "../CheckSelect";

const DashboardSelect: React.FC<{
    collection: string,
    dashboard?: Dashboard,
    hidden?: boolean,
}> = ({ collection, dashboard, hidden }) => {
    const { dashboardsQueries } = useContext(DashboardsContext);
    const { getBulkDashboards, addToBulk, removeFromBulk } = useBulkDashboards();

    const queries = useMemo(() =>
        dashboardsQueries.filter(query => query.dashboardId === dashboard?.id)
    , [dashboardsQueries, dashboard?.id]);

    const cleared = useMemo(() => !getBulkDashboards(collection).length, [getBulkDashboards, collection]);

    const selected = useMemo(() =>
        !!getBulkDashboards(collection).find(exportable => exportable.dashboard.id === dashboard?.id)
    , [getBulkDashboards, collection, dashboard?.id]);

    if (hidden || !dashboard)
        return (<></>);

    return (
        <CheckSelect
            selected={selected}
            cleared={cleared}
            onSelect={() => addToBulk(collection, dashboard, queries)}
            onClear={() => removeFromBulk(collection, dashboard)} />
    );
}

export default memo(DashboardSelect);