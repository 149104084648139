import { Stack } from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import { basicLogger } from 'launchdarkly-js-client-sdk';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ReactComponent as DropboxIcon } from "../assets/icons/dropbox.svg";
import { ReactComponent as OneDriveIcon } from "../assets/icons/onedrive.svg";
import awsExports from '../aws-exports';
import Sidebar from "../components/templates/Sidebar";
import { AuthContext } from "../contexts/AuthContext";
import { FileStructureContext } from '../contexts/FileStructureContext';
import { SubscriptionContext } from "../contexts/SubscriptionContext";
import { onFileAltered, onFileDeleted, onIntegrationUpdate } from "../graphql/subscriptions";
import { getUserEmail } from "../helpers/authUser";
import { LaunchDarkly } from "../helpers/constants";
import { getUserData } from "../lib/helper";
import { integration, integrationFriendlyNames, integrationStatus, } from "../types/integrations";
import IntegrationErrorSnackbar from "./atoms/IntegrationErrorSnackbar";
import AdminPage from "./pages/AdminPage";
import DashboardDetailsPage from "./pages/DashboardDetailsPage";
import DashboardsPage from "./pages/DashboardsPage";
import FolderTabsPage from "./pages/FolderTabsPage";
import HomePage from "./pages/HomePage";
import IntegrationPage from "./pages/IntegrationPage";
import InvestorsPage from "./pages/InvestorsPage";
import MarketInsightsPage from "./pages/MarketInsightsPage";
import RecentActivityPage from "./pages/RecentActivityPage";
import SearchPage from "./pages/SearchPage";
import SubscriptionsPage from "./pages/SubscriptionsPage";
import WorkspacePage from "./pages/WorkspacePage";
import SharedWorkspacePage from "./pages/SharedWorkspacePage";

const { environment } = awsExports;

export const mobileWidth = 800;

const App: React.FC<{}> = () => {
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user, userGroup, isNotissiaAdmin } = useContext(AuthContext);
  const { subscription } = useContext(SubscriptionContext);
  const { reloadFileStructure } = useContext(FileStructureContext);

  const [userLoaded, setUserLoaded] = useState<boolean>(false);
  const [snackbarNotifications, setSnackbarNotifications] = useState<{ provider: integration; key: string | number }[]>([]);
  const [closeNotificationProvider, setCloseNotificationProvider] = useState<string>("");

  const subscribeUpdateFileRef = useRef<any>(null);
  const subscribeDeleteFileRef = useRef<any>(null);
  const integrationSubscriptionRef = useRef<any>(null);

const getIntegrationIcon = (i: integration) => {
  switch (i) {
    case integration.MICROSOFT:
      return <OneDriveIcon style={{ width: 40, height: 40 }} />;
    case integration.DROPBOX:
      return <DropboxIcon style={{ width: 40, height: 40 }} />;
    default:
      return <div />;
  }
};

  const userEmail = getUserEmail(user);
  // TODO, do something with email
  // TODO, create comment subscription using same pattern
  // const emailSubscriptionRef = useRef<any>(null);
  // const subscribeEmail = useCallback(async () => {
  //   if (emailSubscriptionRef.current) {
  //     console.log("already defined");
  //     return;
  //   }
  //   console.log("starting subscription for email");
  //   if (userGroup) {
  //     emailSubscriptionRef.current = await (API.graphql(graphqlOperation(
  //       onEmail, { group: userGroup }
  //     )) as any).subscribe({
  //       next: async (e: any) => {
  //         console.log(e);
  //       }
  //     });
  //   }
  // }, [userGroup]);

  const delayedReload = useMemo(
    () => debounce(reloadFileStructure, 500, { maxWait: 1500 }),
    // userGroup required
    // eslint-disable-next-line
    [userGroup, reloadFileStructure]
  );

  const subscribeDeleteFile = useCallback(async () => {
    subscribeDeleteFileRef.current = await (API.graphql(graphqlOperation(
      onFileDeleted, { group: userGroup }
    )) as any).subscribe({
      next: async (e: any) => {
        delayedReload();
      }
    });
  }, [userGroup, delayedReload]);

  const subscribeUpdateFile = useCallback(async () => {
    subscribeUpdateFileRef.current = await (API.graphql(graphqlOperation(
      onFileAltered, { group: userGroup }
    )) as any).subscribe({
      next: async (e: any) => {
        if (e.value.data.onFileAltered.status === "processed_by_backend") {
          delayedReload();
        }
      }
    });
  }, [userGroup, delayedReload]);

  useEffect(() => {
    if (userGroup) {
      subscribeUpdateFile();
      subscribeDeleteFile();

      return () => { //this cleans up
        subscribeUpdateFileRef.current?.unsubscribe();
        subscribeDeleteFileRef.current?.unsubscribe()
        delayedReload.cancel();
      }
    }
  }, [userGroup, subscribeUpdateFile, subscribeDeleteFile, delayedReload]);

  const subscribeUpdateIntegration = useCallback(
    async (
      localSnackbarNotifications: {
        provider: integration;
        key: string | number;
      }[]
    ) => {
      if (integrationSubscriptionRef.current) {
        integrationSubscriptionRef.current.unsubscribe();
      }

      if (userGroup) {
        integrationSubscriptionRef.current = await (
          API.graphql(
            graphqlOperation(onIntegrationUpdate, { group: userGroup })
          ) as any
        ).subscribe({
          next: async (e: any) => {
            if (
              e?.value?.data?.onIntegrationUpdate?.status ===
              integrationStatus.ERROR
            ) {
              if (
                localSnackbarNotifications.find(
                  (x) =>
                    x.provider ===
                    e?.value?.data?.onIntegrationUpdate?.integration
                )
              ) {
                return;
              }
              const currentIntegration = e?.value?.data?.onIntegrationUpdate
                ?.integration as integration;
              const integrationName =
                integrationFriendlyNames[currentIntegration] ||
                "an integration";
              const notif = enqueueSnackbar(
                <IntegrationErrorSnackbar
                  Icon={getIntegrationIcon(currentIntegration)}
                  message={`There seems to be an error with the connection to ${integrationName}.`}
                  onResolve={() => {
                    setCloseNotificationProvider(
                      e?.value?.data?.onIntegrationUpdate
                        ?.integration
                    );
                    history.push("/integrations");
                  }} onClose={() => {
                    setCloseNotificationProvider(
                      e?.value?.data?.onIntegrationUpdate?.integration
                    );
                  }} />, {
                  variant: 'error', persist: true,
                  anchorOrigin: { vertical: 'top', horizontal: 'right', },
              });
              setSnackbarNotifications([
                ...localSnackbarNotifications,
                {
                  provider: e?.value?.data?.onIntegrationUpdate?.integration,
                  key: notif,
                },
              ]);
            } else if (
              e?.value?.data?.onIntegrationUpdate?.status ===
              integrationStatus.CONNECTED
            ) {
              setSnackbarNotifications(
                localSnackbarNotifications.filter(
                  (x) =>
                    x.provider !==
                    e?.value?.data?.onIntegrationUpdate?.integration
                )
              );
            }
          },
        });
      }
    },
    // eslint-disable-next-line
    [userGroup]
  );

  const ldClient = useLDClient();
  useEffect(() => {
    if (subscription?.planName) {
      ldClient?.identify({ kind: 'tenant', key: userGroup, plan: subscription.planName });
    }
  }, [userGroup, subscription, ldClient]);

  useEffect(() => {
    // subscribeEmail();
    // return () => {
    //   emailSubscriptionRef.current?.unsubscribe();
    // }
  }, [
    userGroup,
    // subscribeEmail,
  ]);

  useEffect(() => {
    subscribeUpdateIntegration(snackbarNotifications);

    return () => {
      integrationSubscriptionRef.current?.unsubscribe();
    };
  }, [snackbarNotifications, subscribeUpdateIntegration]);

  useEffect(() => {
    if (closeNotificationProvider) {
      const notif = snackbarNotifications.find(
        (x) => x.provider === closeNotificationProvider
      );
      if (notif) {
        closeSnackbar(notif.key);
        setSnackbarNotifications(
          snackbarNotifications.filter(
            (x) => x.provider !== closeNotificationProvider
          )
        );
        setCloseNotificationProvider("");
      }
    }
    // eslint-disable-next-line
  }, [closeNotificationProvider]);

  useEffect(() => {
    if (!userEmail || !userGroup) {
      return;
    }
    getUserData(userEmail, (result: any) => {
      if (!result) {
        console.error('User not found');
      } else {
        setUserLoaded(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail, userGroup, user.username]);

  return (<>
    <Stack direction="row" width="100vw" minHeight="100vh" maxHeight="100vh">
      <Sidebar />
      <Stack width="calc(100vw - 94px)" maxHeight="100%">
        {userLoaded && (
          <Switch>
            <Route exact path="/search">
              <SearchPage />
            </Route>

            <Route exact path="/folders">
              <FolderTabsPage />
            </Route>

            <Route exact path="/recent-activity">
              <RecentActivityPage />
            </Route>

            <Route exact path="/shared/public/:workspaceId">
              <SharedWorkspacePage publicWorkspace />
            </Route>

            <Route exact path="/shared/:workspaceId">
              <SharedWorkspacePage />
            </Route>

            <Route exact path="/people/public/:workspaceId">
              <WorkspacePage publicWorkspace />
            </Route>

            <Route exact path="/people/:workspaceId">
              <WorkspacePage />
            </Route>

            <Route exact path={[
              "/dashboards/shared/public/:dashboardId/:viewMode/:fileId",
              "/dashboards/shared/public/:dashboardId",
              "/dashboards/public/:dashboardId/:viewMode/:fileId",
              "/dashboards/public/:dashboardId",
            ]}>
              <DashboardDetailsPage publicDashboard />
            </Route>

            <Route exact path={[
              "/dashboards/shared/:dashboardId/:viewMode/:fileId",
              "/dashboards/shared/:dashboardId",
            ]}>
              <DashboardDetailsPage sharedDashboard />
            </Route>

            <Route exact path={[
              "/dashboards/:dashboardId/:viewMode/:fileId",
              "/dashboards/:dashboardId/:viewMode",
              "/dashboards/:dashboardId",
            ]}>
              <DashboardDetailsPage />
            </Route>

            <Route exact path="/dashboards">
              <DashboardsPage />
            </Route>

            <Route exact path={[
              "/network",
              "/people",
              "/shared",
            ]}>
              <InvestorsPage />
            </Route>

            <Route exact path="/market">
              <MarketInsightsPage />
            </Route>

            <Route exact path="/integrations">
              <IntegrationPage />
            </Route>

            {isNotissiaAdmin &&
              <Route exact path="/groups">
                <AdminPage />
              </Route>
            }

            <Route exact path="/groups/:group">
              <AdminPage />
            </Route>

            <Route exact path="/subscriptions">
              <SubscriptionsPage />
            </Route>

            <Route exact path={[
              "/home/:viewMode",
              "/home",
            ]}>
              <HomePage />
            </Route>

            <Route exact path={["/", "*"]}>
              <Redirect to="/home" />
            </Route>
          </Switch>
        )}
      </Stack>
    </Stack>
  </>);
}

export default withLDProvider({
  clientSideID: LaunchDarkly(environment).clientId,
  options: {
    logger: basicLogger({ level: 'error' })
  }
})(App);
