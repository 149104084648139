import { Button, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckIcon from '@mui/icons-material/CheckCircle';
import BulkShareDeals, { BulkShareCuratedTabs } from "../../../atoms/[legacy]/home-bulk-share-deals";

const useStyles = makeStyles((theme) => ({
    mainContent: {
        width: 500,
        height: 400,
        padding: 'unset',
        overflow: 'hidden',
    },
    mainText: {
        fontFamily: 'Inter',
        fontSize: '1.2rem !important',
        fontWeight: '700 !important',
        color: 'black',
        textAlign: 'center',
    },
    icon: {
        fontSize: '3rem',
        color: theme.palette.primary.main
    },
    nextButton: {
        width: 'auto',
        minWidth: 100,
        borderRadius: 32,
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
    },
}));

const ShareMailingDone: React.FC<{
    onSelect?: (selection: BulkShareCuratedTabs) => void,
}> = ({ onSelect }) => {
    const classes = useStyles();

    return (<>
        <BulkShareDeals.Panel value={BulkShareCuratedTabs.Done}
            content={(
                <Stack direction="column" className={classes.mainContent}
                    spacing={5} alignItems="center" justifyContent="center">
                    <CheckIcon className={classes.icon} />
                    <Typography className={classes.mainText}>
                        {'Companies successfully shared'}<br/>
                        {'with selected investors '}
                    </Typography>
                </Stack>
            )}
            actions={(
                <Stack direction="row" justifyContent="flex-end" width="100%">
                    <Button variant="contained"
                        className={classes.nextButton}
                        onClick={() => onSelect?.(BulkShareCuratedTabs.Close)}>
                        {'Close'}
                    </Button>
                </Stack>
            )}
            noActionDiv />
    </>);
}

export default ShareMailingDone;