import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext, useState } from "react";
import { DashboardContext } from "../../../contexts/DashboardContext";
import AddMetrics from "../../atoms/AddMetrics";
import MetricsQuery from "../../molecules/dashboard-queries/MetricsQuery";

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: '#666666',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));

const KeyMetricsColumnQueries: React.FC<{}> = () => {
    const classes = useStyles();
    const { isPublicView } = useContext(DashboardContext);
    const [metricsCount, setMetricsCount] = useState<number>(0);

    return (<>
        <Stack spacing={2} width="100%">
            <Typography className={classes.title}>
                {'Round Details'}
            </Typography>
            <MetricsQuery roundDetails />
            {(!!metricsCount || !isPublicView) && (<>
                <Typography className={classes.title}>
                    {'Metrics'}
                </Typography>
            </>)}
            <MetricsQuery onMetricsCount={setMetricsCount} />
            {!isPublicView && (<AddMetrics />)}
        </Stack>
    </>);
}

export default KeyMetricsColumnQueries;