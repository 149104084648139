import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import WestIcon from '@mui/icons-material/West';
import { Box, Breadcrumbs, Container, Divider, Link, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { DashboardContext } from "../../contexts/DashboardContext";
import { DashboardsContext } from "../../contexts/DashboardsContext";
import { GroupSettingsContext } from "../../contexts/GroupSettingsContext";
import useRoute from "../../hooks/useRoute";
import { getPublicWorkspaceFunc, getWorkspaceScoreFunc } from "../../lib/helper";
import theme from "../../theme";
import { Workspace, WorkspaceScore } from "../../types/files";
import storage from "../../utils/storage";
import CampainSection from "../atoms/CampainSection";
import HoverEditable from "../atoms/HoverEditable";
import InvestorsIconFC from '../atoms/InvestorsIconFC';
import CreateEditInvestorModal from "../modals/investors/CreateEditInvestorModal";
import DashboardsSearchOrCreate from "../molecules/dashboards-search-create/DashboardsSearchOrCreate";
import SharedWorkspaceActions from '../molecules/multi-action/SharedWorkspaceActions';
import InvestmentThesisV2 from '../organisms/workspace/InvestmentThesisV2';
import WorkspaceAllowAccess from "../organisms/workspace/WorkspaceAllowAccess";
import PublicWorkspaceNotFound from "../organisms/workspace/WorkspaceNotFound";
import { FallbackLoading } from "../templates/loader";

type WorkspaceAccessType = {
    iss?: string | null,
    sub: string,
    exp: number | null,
};

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        height: '100%',
        maxWidth: 'unset !important',
        overflowY: 'hidden',
    },
    header: {
        width: '100%',
        height: 'fit-content',
        paddingTop: 16,
        opacity: 1,
        transition: 'height .3s ease-in-out, opacity .3s ease-in-out',
    },
    title: {
        fontFamily: 'Poppins',
        fontSize: '2.2rem',
        fontWeight: 'bolder',
    },
    link: {
        "&:hover": {
            textDecoration: 'underline',
            cursor: 'pointer',
        }
    },
    next: {
        width: 14,
        height: 14,
    },
    breadcrumbs: {
        "& .MuiBreadcrumbs-separator, & a": {
            fontFamily: 'Inter',
            fontSize: '1rem !important',
            fontWeight: 600,
            color: theme.palette.primary.main,
            textDecoration: 'none !important',
            cursor: 'auto',
            '&:hover': {
                textDecoration: 'underline !important',
                cursor: 'pointer',
            },
        },
    },
    inputField: {
        "& .MuiOutlinedInput-root": {
            minWidth: '120px',
            height: '50px',
            borderRadius: 20,
            "& > input": {
                fontSize: 16,
            },
            "&:hover fieldset": {
              borderColor: 'darkgray !important',
            },
            "&.Mui-focused fieldset": {
              borderColor: 'black !important',
            },
        },
    },
    mainLabel: {
        fontFamily: 'Inter',
        fontSize: '2rem',
        fontWeight: 700,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineClamp: 1,
        whiteSpace: 'nowrap',
    },
    email: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.neutral['500'],
    },
    layoutIcons: {
        minWidth: '52px',
        height: '48px',
        borderRadius: '4px',
    },
    selectedIcon: {
        backgroundColor: '#007681',
    },
    layoutIcon: {
        height: 20,
        width: 20,
    },
    campain: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.neutral['600'],
    },
    signUpButton: {
        minWidth: 180,
        width: 'fit-content',
        height: 40,
        padding: '6px 10px',
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
}));

export const RowActions = ['Add companies', 'Invite investors', 'Copy workspace link', 'Update my deal preferences', 'Delete workspace'];
export const SharedRowActions = ['Update my deal preferences'];

const SharedWorkspacePage: React.FC<{ publicWorkspace?: boolean }> = ({publicWorkspace}) => {
    const classes = useStyles();
    const history = useHistory();
    const params: {[key: string]: string} = useParams();
    const { userGroup } = useContext(AuthContext);
    const { loaded: settingsLoaded, workspaces } = useContext(GroupSettingsContext);
    const { workspace, isPublicView,
        setPublicView, setWorkspace, setExternalDashboards, setWorkspaceScores, setDashboardsLoaded } = useContext(DashboardsContext);
    const { setPublicView: setPublicDashboardView } = useContext(DashboardContext);
    const { getURLFrom, redirectFromWorkspaceGroup } = useRoute();

    const from = getURLFrom();

    const [search, setSearch] = useState<string>("");
    const [loaded, setLoaded] = useState<boolean>(false);
    const [modifyFields, setModifyFields] = useState<string[] | undefined>(undefined);
    const [checkedAccess, setCheckedAccess] = useState<boolean>(false);
    const [hasPublicAccess, setWithPublicAccess] = useState<boolean>(false);
    const [createInvestorOpen, setCreateInvestorOpen] = useState<boolean>(false);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const convertToWorkspaceScores = (scores?: any) => {
        if (typeof scores === 'string') {
            const parsedScores = JSON.parse(scores?.replace('scores', '') || '{}') || {};

            return Object.keys(parsedScores).map(dashboardId => ({
                dashboardId,
                score: parsedScores[dashboardId]
            }));
        } else if (Array.isArray(scores)) {
            return scores;
        }

        return [];
    };

    const isUserOwnedSharedWorkspace = useMemo(() => !!workspaces.find(ws => ws.id === workspace?.id),[workspace, workspaces]);

    const isInvalidPublicWorkspace = useMemo(() =>
        loaded && publicWorkspace && (!workspace || workspace?.isPublic === false)
    , [loaded, publicWorkspace, workspace]);

    const isValidatingPublicWorkspace = useMemo(() =>
        loaded && (!userGroup && publicWorkspace) && (workspace?.isPublic) && (!checkedAccess || !hasPublicAccess)
    , [checkedAccess, hasPublicAccess, loaded, publicWorkspace, userGroup, workspace?.isPublic]);

    const handleStoreAccess = useCallback((workspace?: Workspace) => {
        if (!workspace)
            return;

        const stream = storage.getItem('config.workspace-public');
        const issuer = workspace.email || '';
        const accessData: WorkspaceAccessType = {
            iss: issuer,
            sub: workspace.id,
            exp: null,
        };

        if (!!stream) {
            const accessList = (JSON.parse(atob(stream)) ?? []) as WorkspaceAccessType[];
            const lookUpAccess = accessList.find(access => (access.iss === issuer && access.sub === workspace.id));

            if (!lookUpAccess) {
                accessList.push(accessData);
                storage.setItem('config.workspace-public', btoa(JSON.stringify(accessList)));
            }
        } else {
            storage.setItem('config.workspace-public', btoa(JSON.stringify([accessData])));
        }
    }, []);

    const handleVerifyAccess = useCallback((workspace?: Workspace) => {
        if (!workspace)
            return;

        timerRef.current = setTimeout(() => {
            const stream = storage.getItem('config.workspace-public');
            const issuer = workspace.email || '';

            if (!!stream) {
                const accessList = (JSON.parse(atob(stream)) ?? []) as WorkspaceAccessType[];
                const lookUpAccess = accessList.find(access => (access.iss === issuer && access.sub === workspace.id));

                if (!!lookUpAccess) {
                    if (!lookUpAccess.iss || (Number(lookUpAccess.exp) > Math.floor(Date.now() / 1000)))
                        setWithPublicAccess(true);
                    if (!lookUpAccess.iss || !!lookUpAccess.exp)
                        setCheckedAccess(true);
                }
            }

            setLoaded(true);
            clearTimeout(timerRef.current);
        }, 500);
    }, []);

    const handleConfirmAccess = useCallback((email: string) => {
        timerRef.current = setTimeout(() => {
            const stream = storage.getItem('config.workspace-public');

            if (!!stream) {
                const accessList = (JSON.parse(atob(stream)) ?? []) as WorkspaceAccessType[];
                const lookUpAccess = accessList.find(access => (access.iss === email && access.sub === workspace?.id));

                if (!!lookUpAccess) {
                    lookUpAccess.exp = Math.floor(Date.now() / 1000) + (24 * 60 * 60);
                    storage.setItem('config.workspace-public', btoa(JSON.stringify(accessList)));
                    setWithPublicAccess(true);
                }
                setCheckedAccess(true);
            }
            clearTimeout(timerRef.current);
        }, 1000);
    }, [workspace]);

    useEffect(() => {
        setWorkspace(null);
        setPublicView(!!publicWorkspace);
        setPublicDashboardView(false);
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const workspaceId = params.workspaceId;
        const lookUpWorkspace = workspaces.find(workspace => workspace.id === workspaceId);

        if (publicWorkspace) {
            if (!!lookUpWorkspace) {
                setPublicView(false);
                history.push(`${history.location.pathname.replace('/public/', '/')}${history.location.search}`);
            } else {
                getPublicWorkspaceFunc({
                    id: workspaceId,
                    group: getURLFrom()?.originGroup || userGroup,
                }).then((data) => {
                    setExternalDashboards(data?.dashboards ?? []);
                    setDashboardsLoaded(true);
                    setWorkspace(data?.workspace || null);
                    if (!!data?.workspace) {
                        handleStoreAccess(data?.workspace);
                        handleVerifyAccess(data?.workspace);
                    } else {
                        setLoaded(true);
                    }
                });
            }
        } else if (settingsLoaded) {
            if (!!workspaceId) {
                if (!!lookUpWorkspace) {
                    setWorkspace(lookUpWorkspace);
                    setLoaded(true);
                } else {
                    getPublicWorkspaceFunc({
                        id: workspaceId,
                        group: getURLFrom()?.originGroup || userGroup,
                    }).then((data) => {
                        setExternalDashboards(data?.dashboards ?? []);
                        setDashboardsLoaded(true);
                        setWorkspace(data?.workspace || null);
                        if (!!data?.workspace) {
                            handleStoreAccess(data?.workspace);
                            handleVerifyAccess(data?.workspace);
                        } else {
                            setLoaded(true);
                        }
                    });
                }
            } else {
                setWorkspace(null);
                setLoaded(true);
            }
        }
    // eslint-disable-next-line
    }, [publicWorkspace, settingsLoaded, params.workspaceId]);

    useEffect(() => {
        if (loaded && !!workspace) {
            if (workspace.isPublic && workspace.isShared) {
                getWorkspaceScoreFunc({workspaceId: workspace!.id, group: userGroup})
                    .then((workspaceScores?: WorkspaceScore) => {
                        if (!!workspaceScores && !!workspaceScores?.scores) {
                            const updatedScores = {...workspaceScores, scores: convertToWorkspaceScores(workspaceScores?.scores)};

                            setWorkspaceScores(prev => !prev.find(ws => ws.workspaceId === workspace?.id)
                                ? [...prev, updatedScores]
                                : prev.map(ws => ws.workspaceId === workspace?.id ? updatedScores : ws));
                        }
                    });
            }
        }
    // eslint-disable-next-line
    }, [loaded, userGroup, workspace]);

    if (!loaded)
        return (<FallbackLoading />);

    if (isInvalidPublicWorkspace)
        return (<PublicWorkspaceNotFound />);

    if (isValidatingPublicWorkspace)
        return (<WorkspaceAllowAccess invalid={checkedAccess && !hasPublicAccess} onConfirm={handleConfirmAccess} />);

    return (<>
        <Container className={classes.rootContainer}>
            <Stack direction="column" spacing={1} width="100%" height="100%">
                <Stack direction="row" className={classes.header} spacing={3} alignItems="center" justifyContent="space-between"
                    my={isPublicView && !userGroup ? '12px' : undefined}>
                    <Stack direction="column" alignItems="flex-start" minWidth="15%">
                        <Breadcrumbs className={classes.breadcrumbs}
                            separator={<ArrowForwardIosIcon className={classes.next} />}>
                            {isPublicView ? (!!userGroup) && (
                                <Link onClick={redirectFromWorkspaceGroup}>
                                    {'Back to my workspace'}
                                </Link>
                            ) : (
                                <Link onClick={redirectFromWorkspaceGroup}>
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                                        <WestIcon />
                                        <span style={{ textTransform: 'capitalize' }}>
                                            {(from?.origin !== 'shared' ? from?.origin : null) ?? 'All shared workspaces'}
                                        </span>
                                    </Stack>
                                </Link>
                            )}
                        </Breadcrumbs>
                        <HoverEditable variant="large"
                            onClick={() => {
                                setCreateInvestorOpen(true);
                                setModifyFields(['investor name']);
                            }} disableEdit={publicWorkspace}>
                            <Stack direction="row" spacing="4px" alignItems="center" justifyContent="flex-start">
                                <InvestorsIconFC width={28} height={28} color={theme.colors.neutral['500']} />
                                <Typography className={classes.mainLabel} fontSize="2rem">
                                    {workspace?.name}
                                </Typography>
                            </Stack>
                        </HoverEditable>
                    </Stack>
                    {!isPublicView && (
                        <Box width="600px">
                            <DashboardsSearchOrCreate search
                                onSearch={(value) => setSearch(value)} />
                        </Box>
                    )}
                    {isPublicView ? (!userGroup ? (<CampainSection />) : (
                        <Stack alignItems="flex-end" width="auto" pr={2}>
                            <Typography fontFamily="Inter" fontSize="1rem" fontWeight={500} color={theme.palette.primary.main}>
                                {'This workspace was shared by an external user.'}
                            </Typography>
                        </Stack>
                    )) : (
                        <Stack direction="row" spacing={2} alignItems="center">
                            {/* <WorkspaceShare /> */}
                            {/* <WorkspaceShareEmail /> */}
                            <SharedWorkspaceActions actions={isUserOwnedSharedWorkspace ? RowActions : SharedRowActions} />
                        </Stack>
                    )}
                </Stack>
                <Divider />
                <InvestmentThesisV2 search={search} />
            </Stack>
        </Container>
        {createInvestorOpen && (
            <CreateEditInvestorModal
                isOpen={createInvestorOpen}
                workspaceId={workspace?.id}
                fields={modifyFields}
                onClose={() => setCreateInvestorOpen(false)}
            />
        )}
    </>);
}

export default SharedWorkspacePage;