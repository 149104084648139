import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, Button, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Divider, InputAdornment, Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classnames from "classnames";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import useBackwardsCompatible from "../../../hooks/useBackwardsCompatible";
import { createWorkspaceFunc, updateDashboardFunc } from "../../../lib/helper";
import theme from '../../../theme';
import { Dashboard, Workspace } from "../../../types/files";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: 500,
            height: 'fit-content',
            padding: '16px 24px',
            borderRadius: 24,
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.25rem',
    },
    dialogContent: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '16px 8px 32px 8px',
        height: '100%',
        overflowY: 'hidden',
        gap: 8,
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 0,
        gap: 8,
    },
    label: {
        width: 85,
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 500,
        color: theme.colors.neutral['500'],
    },
    source: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        wordBreak: 'break-all',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    email: {
        fontWeight: '500',
        color: theme.colors.neutral['500'],
    },
    emailField: {
        width: '100%',
        border: `1px solid ${theme.colors.neutral[400]}`,
        borderRadius: 8,
        padding: 8,
        "& .MuiOutlinedInput-root": {
            height: 28,
            padding: 'unset',
            "& > input, > textarea": {
                fontSize: '1rem',
                padding: 'unset',
            },
        },
    },
    disabledField: {
        "& .MuiOutlinedInput-root": {
            "& .Mui-disabled": {
                color: `${theme.colors.neutral[500]} !important`,
                '-webkit-text-fill-color': `${theme.colors.neutral[500]} !important`,
            },
        },
    },
    datePicker: {
        width: '100%',
        border: `1px solid ${theme.colors.neutral[400]}`,
        borderRadius: 8,
        padding: 8,
        "& > .MuiInputBase-root":{
            "& > input": {
                fontFamily: 'Lato',
                fontSize: '1.2rem',
                padding: 'unset',
            },
            "& fieldset": {
              border: 0,
            },
        }
    },
    unitSelectorForm: {
        width: '100%',
        border: `1px solid ${theme.colors.neutral[400]}`,
        borderRadius: 8,
        padding: 8,
        "& > .MuiInputBase-root":{
            padding: 'unset',
            "& > div": {
                fontFamily: 'Lato',
                fontSize: '1.2rem',
                padding: 'unset',
            },
            "& fieldset": {
              border: 0,
            },
        }
    },
    deleteButton: {
        width: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        textTransform: 'none',
    },
    commonButton: {
        width: 100,
        borderRadius: 32,
        fontWeight: 'bold',
        textTransform: 'none',
    },
    cancelButton: {
        width: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        fontWeight: 'bold',
        textTransform: 'none',
    },



    optionPaper: {
        background: 'white',
        border: `1px solid ${theme.colors.neutral['100']}`,
        borderRadius: 12,
        boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12)',
    },
    optionContainer: {
        padding: '2px 12px',
        margin: '4px 8px 4px 16px',
        borderBottom: `1px solid ${theme.colors.neutral['400']}`,
        transition: '0.5s ease-in-out',
        "&:hover": {
            backgroundColor: '#EFF6FF',
            boxShadow: '0px 4px 10px -1px rgba(16, 24, 40, 0.06)',
        },
        cursor: 'pointer',
    },
    optionItem: {
        fontSize: '0.9rem !important',
        fontWeight: '600 !important',
        color: `${theme.colors.neutral['800']} !important`,
    },
    disabledInputField: {
        "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
                borderWidth: '1px !important',
                borderColor: 'rgba(0, 0, 0, 0.38) !important',
            },
        },
    },
    smallInputField: {
        "& .MuiOutlinedInput-root": {
            height: 32,
            borderRadius: 16,
        },
    },
    mediumInputField: {
        "& .MuiOutlinedInput-root": {
            height: 40,
            borderRadius: 24,
        },
    },
    inviteButton: {
        height: 32,
        width: 40,
        borderRadius: '16px !important',
        borderWidth: 3,
        fontSize: '14px !important',
        textTransform: 'none !important' as any,
        fontWeight: 'bold !important',
        "&:hover": {
            borderWidth: 3,
            fontWeight: 'bold !important',
        },
    },
    smallButton: {
        height: 24,
    },
    nameField: {
        minWidth: 'fit-content',
        "& .MuiOutlinedInput-root": {
            minWidth: 250,
            height: 45,
            padding: '0 8px !important',
            borderRadius: 8,
            background: '#fff',
            "& > input": {
                padding: 'unset !important',
                fontSize: '1rem',
            },
            "& fieldset": {
              	borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              	borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              	borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    },
}));

const ModifyDealSource: React.FC<{
    isOpen: boolean,
    dashboard: Dashboard,
    onClose: () => void,
}> = ({ isOpen, dashboard, onClose }) => {
    const classes = useStyles();
    const { workspaces, setWorkspaces } = useContext(GroupSettingsContext);
    const { dashboards } = useContext(DashboardsContext);
    const { dashboardSource } = useBackwardsCompatible();

    const [openOptions, setOpenOptions] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [sourceName, setSourceName] = useState<string|undefined >(undefined);
    const [sourceEmail, setSourceEmail] = useState<string|undefined >(undefined);
    const [isExistingWorkspace, setExistingWorkspace] = useState<boolean>(false);
    const nameValRef = useRef<TextFieldProps>(null);
    const emailValRef = useRef<TextFieldProps>(null);

    const options = useMemo(() => workspaces.filter(ws => !ws.isShared), [workspaces]);

    const isSaveReady = useMemo(() => !!sourceName, [sourceName]);

    const debouncedEmail = useDebouncedCallback(email => setSourceEmail(email.trim()), 100, { maxWait: 200 });

    const handleLookUp = useCallback((workspace?: Workspace) => {
        const searchName = nameValRef.current?.value as string;
        const lookUpWorkspace = workspace ?? workspaces.find(ws => ws.name.toLowerCase() === searchName.trim().toLowerCase());

        if (!!lookUpWorkspace) {
            setSourceName(lookUpWorkspace.name);
            setSourceEmail(lookUpWorkspace.email);
            setExistingWorkspace(true);
            setOpenOptions(false);
            if (emailValRef.current)
                emailValRef.current.value = lookUpWorkspace.email;
        } else {
            setSourceName(searchName);
            setSourceEmail(undefined);
            setExistingWorkspace(false);
            if (emailValRef.current)
                emailValRef.current.value = '';
            if (!!searchName)
                setOpenOptions(true);
        }
    }, [workspaces]);

    const handleSave = useCallback(async () => {
        if (!dashboard)
            return;

        setLoading(true);

        if (!isExistingWorkspace && !!sourceName) {
            const newWorkspace: Workspace = await createWorkspaceFunc({ name: sourceName, email: sourceEmail, isPublic: false, isShared: false });

            setWorkspaces(prev => [...prev, newWorkspace]);
        }

        dashboard.dealSource = {name: sourceName, email: sourceEmail};
        dashboard.source = sourceName ?? dashboard.dealSource?.name;

        updateDashboardFunc(dashboard).then(() => {
            if (!!dashboards.length) {
                const found = dashboards.find(d => d.id === dashboard.id);

                if (!!found)
                    found.source = sourceName ?? found.dealSource?.name;
            }
            setLoading(false);
        }).finally(onClose);
    }, [dashboard, dashboards, isExistingWorkspace, onClose, setWorkspaces, sourceEmail, sourceName]);

    useEffect(() => {
        if (!!dashboard) {
            const dealSource = dashboardSource(dashboard);

            if (!!dealSource) {
                const lookUpWorkspace = workspaces.find(ws =>
                    ws.name?.toLowerCase() === dealSource.name?.trim()?.toLowerCase());

                setSourceName(lookUpWorkspace?.name ?? dealSource.name ?? undefined);
                setSourceEmail(lookUpWorkspace?.email ?? dealSource.email ?? undefined);
                setExistingWorkspace(true);
                if (emailValRef.current)
                    emailValRef.current.value = lookUpWorkspace?.email ?? dealSource.email;
            } else {
                setSourceName('');
                setSourceEmail(undefined);
                setExistingWorkspace(false);
                if (emailValRef.current)
                    emailValRef.current.value = '';
            }
        }
    }, [dashboardSource, dashboard, workspaces]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={() => onClose()}>
            <DialogTitle className={classes.dialogTitle}>
                {`Update deal source for ${dashboard.title}`}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Stack direction="column" spacing={1} alignItems="flex-start" width="100%" mt={2}>
                    <Typography className={classes.label}>{'Name:'}</Typography>
                    <ClickAwayListener onClickAway={() => setOpenOptions(false)}>
                        <Autocomplete
                            open={openOptions}
                            disableClearable
                            autoHighlight
                            includeInputInList
                            disablePortal
                            freeSolo
                            forcePopupIcon={false}
                            onChange={(_, option) => handleLookUp(option as Workspace)}
                            value={sourceName}
                            options={options}
                            filterOptions={(options, { inputValue }) =>
                                options.filter(ws =>
                                    ws.name?.toLowerCase()?.includes(inputValue?.trim()?.toLowerCase()) ||
                                    ws.email?.toLowerCase()?.includes(inputValue?.trim()?.toLowerCase())
                                )
                            }
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                            PaperComponent={(props) => (<Box {...props} className={classes.optionPaper} />)}
                            ListboxProps={{style: { maxHeight: 165, padding: 8, }}}
                            renderOption={(props: any, option) => (
                                <Box {...props} className={classes.optionContainer}>
                                    <Stack direction="row" className={classes.optionItem} spacing={1}
                                        alignItems="flex-start" justifyContent="flex-start" width="100%">
                                        <PersonOutlineOutlinedIcon sx={{ width: 24, height: 24, fill: "rgb(163, 167, 172)" }} />
                                        <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                                            <Typography className={classes.source}>
                                                {option.name}
                                            </Typography>
                                            {!!option.email && (
                                                <Typography className={classnames(classes.source, classes.email)}>
                                                    {option.email}
                                                </Typography>
                                            )}
                                        </Stack>
                                    </Stack>
                                </Box>
                            )}
                            sx={{ width: '100%', }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    className={classnames(
                                        classes.nameField,
                                    )}
                                    placeholder={'Search or invite investors...'}
                                    onChange={() => handleLookUp()}
                                    onFocus={() => setOpenOptions(!isExistingWorkspace || !sourceName)}
                                    inputRef={nameValRef}
                                    sx={{ width: '100%' }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start" sx={{ color: theme.palette.primary.main }}>
                                                <PersonOutlineOutlinedIcon sx={{ width: 24, height: 24,
                                                    fill: isExistingWorkspace ? theme.palette.primary.main : "rgb(163, 167, 172)" }} />
                                            </InputAdornment>
                                        )
                                }}/>
                            )}
                        />
                    </ClickAwayListener>
                </Stack>
                <Box height={32} />
                <Stack direction="column" spacing={1} alignItems="flex-start" width="100%" mb={3}>
                    <Typography className={classes.label}>{'Email:'}</Typography>
                    <TextField variant="outlined"
                        className={classnames('no-border', classes.emailField)}
                        inputProps={{ style: { height: '100%' }, className: 'no-fade' }}
                        placeholder={'Email address ...'}
                        defaultValue={sourceEmail}
                        inputRef={emailValRef}
                        onChange={(e) => debouncedEmail(e.target.value as string)}
                        rows={1}
                    />
                </Stack>
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" width="100%">
                    <Button variant="outlined"
                        className={classes.cancelButton}
                        onClick={() => onClose()}
                    > {'Cancel'} </Button>
                    <LoadingButton variant="contained"
                        className={classes.commonButton}
                        loading={loading}
                        disabled={!isSaveReady}
                        onClick={handleSave}
                    > {'Save'} </LoadingButton>
                </Stack>
            </DialogActions>
        </Dialog>
    </>);
}

export default ModifyDealSource;