import { Box, Divider, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { VirtualItem } from "@tanstack/react-virtual";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import useBackwardsCompatible from "../../../hooks/useBackwardsCompatible";
import useDashboards from "../../../hooks/useDashboards";
import usePaginator from "../../../hooks/usePaginator";
import { getWorkspaceScoreFunc, refreshWorkspaceScoreFunc, updateWorkspaceFunc } from "../../../lib/helper";
import { scrollbarStyle } from "../../../shared/dashboard";
import { Dashboard, Workspace, WorkspaceScore, WorkspaceScoreItem } from "../../../types/files";
import { collectionKey } from "../../organisms/dashboards/DashboardsView";
import { FallbackLoading } from "../../templates/loader";
import NoDashboardsFoundView from "../dashboards-empty/NoDashboardsFoundView";
import DashboardsTableFilter from "../dashboards-table/DashboardsTableFilter";
import DashboardsTableHeader from "../dashboards-table/DashboardsTableHeader";
import DashboardsTableRow from "../dashboards-table/DashboardsTableRow";

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    scrollable: {
        width: '100%',
        height: '100%',
        marginBottom: '16px !important',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    headers: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        scrollBehavior: 'smooth',
        ...scrollbarStyle,
    },
    //
    table: {
        width: '100%',
        height: 'calc(100vh - 180px)',
        marginLeft: 12,
    },
    header: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        scrollBehavior: 'auto',
        borderBottom: `1px solid ${theme.colors.neutral['400']}`,
        ...scrollbarStyle,
    },
    rows: {
        position: 'relative',
        minWidth: '100%',
        width: 'fit-content',
        height: '100%',
        overflow: 'auto',
        scrollBehavior: 'smooth',
        ...scrollbarStyle,
    },
    row: {
        position: 'absolute',
        top: 0,
        left: 0,
        minWidth: '100%',
        width: 'fit-content',
        height: 'auto',
        borderTop: `1px solid ${theme.colors.neutral['400']}`,
        background: `#fff`,
        "&:hover": {
            background: `rgb(246, 251, 251, 0.8)`,
        },
    },
    section: {
        width: '100%',
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 600,
        color: '#3ABDC6',
        "&::before, &::after": {
            borderTop: 'thin solid #3ABDC6',
        }
    },
    loadingText: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 700,
        color: theme.colors.neutral['900'],
    },
    loadingTextSub: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: '#9e9e9e',
    },
    loading: {
        width: '100%',
        height: '100%',
        color: theme.palette.primary.main,
    },
    sharedTitle: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: '600',
        color: theme.colors.neutral['700'],
    },
}));

export const TableHeaders = ['Name', 'Fit score', 'Stage', 'Sectors', 'Location', 'Deal source', 'Request Intro', '', 'Added', ''];

const SharedByAll: React.FC<{
    workspace: Workspace,
    sectors: string[],
    stages: string[],
    search?: string,
    withFilter?: boolean,
    onFilter?: ({ tags, stage } : { tags: string[],  stage: string[], }) => void,
    onCount?: (count: number) => void,
}> = ({ workspace, sectors, stages, search, withFilter, onFilter, onCount }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { workspaces } = useContext(GroupSettingsContext);
    const { mappedOverviewQueries, isPublicView, dashboards, externalDashboards,
        setWorkspaceScores, setWorkspace } = useContext(DashboardsContext);
    const { filterDashboardList } = useDashboards();
    const { dashboardWorkspaces } = useBackwardsCompatible();

    const [loading, setLoading] = useState<boolean>(true);
    const [searching, setSearching] = useState<boolean>(false);
    const [dashboardArray, setDashboardArray] = useState<Dashboard[]|null>(null);
    const [sortBy, setSortBy] = useState<string>('Added');
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);
    const virtualRowsRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);

    const syncScroll = (e?: Event) => {
        e?.preventDefault();

        if (headerRef.current && virtualRowsRef.current)
            headerRef.current.scrollLeft = virtualRowsRef.current.scrollLeft;
    };

    const convertToWorkspaceScores = (scores?: any) => {
        if (typeof scores === 'string') {
            const parsedScores = JSON.parse(scores?.replace('scores', '') || '{}') || {};

            return Object.keys(parsedScores).map(dashboardId => ({
                dashboardId,
                score: parsedScores[dashboardId]
            }));
        } else if (Array.isArray(scores)) {
            return scores;
        }

        return [];
    };

    const extSharedWorkspace = useMemo(() =>
        !workspaces?.find(ws => ws?.id === workspace?.id) && workspace?.isPublic && workspace?.isShared
    , [workspace, workspaces]);

    const getMembership = useCallback((dashboard: Dashboard) =>
        dashboard?.workspaceMemberships?.find(wsMem => wsMem.workspaceId === workspace?.id)
    , [workspace?.id]);

    const handleRefresh = useCallback((refresh?: boolean) => {
        if (!!workspace) {
            const promises: Promise<any>[] = [];

            if (refresh)
                updateWorkspaceFunc(workspace).then((updatedWorkspace?: Workspace) => setWorkspace({...updatedWorkspace!}));

            externalDashboards.forEach(dashboard => promises.push(
                refreshWorkspaceScoreFunc({
                    dashboardId: dashboard.id,
                    workspaceId: workspace.id,
                    group: userGroup,
                })
            ));

            Promise.all(promises).then(() => {
                setSearching(true);
            });
        }
    // eslint-disable-next-line
    }, [workspace, externalDashboards, dashboards, userGroup]);

    const sortedDashboards = useMemo(() => {
        if (sortBy === 'Fit score') {
            return Array.from(new Map(
                [...dashboardArray ?? []].filter(dashboard => dashboardWorkspaces(dashboard)?.includes(workspace!.id)).map(dashboard => {
                    const investmentThesis: { workspaceId: string, score: number}[]
                        = mappedOverviewQueries.get(`${dashboard.id}:Investment Thesis`) || [];
                    const score = investmentThesis?.find(iT => iT.workspaceId === workspace!.id)?.score ?? 0;

                    return [dashboard, score];
                }))).sort((a, b) => b[1] - a[1]).map(entry => entry[0]);
        }

        // else sort by 'Added'
        return [...dashboardArray ?? []].sort((prev: Dashboard, next: Dashboard) => {
            const prevDate = getMembership(prev)?.updatedAt || prev.createdAt;
            const nextDate = getMembership(next)?.updatedAt || next.createdAt;

            return nextDate.localeCompare(prevDate);
        });
    // eslint-disable-next-line
    }, [dashboardArray, mappedOverviewQueries, sortBy, workspace]);

    const filteredDashboards = useMemo(() => {
        let filtered: Dashboard[] = sortedDashboards;

        if (!!sectors.length){
            filtered = filtered.filter(dashboard =>
                sectors.every(key => (dashboard.tags || []).includes(key))
            );
        }

        if (!!stages.length){
            filtered = filtered.filter(dashboard => {
                const stage = dashboard.investmentStage || '';

                return stages.some(key => key === stage);
            });
        }

        return filtered;
    }, [sectors, sortedDashboards, stages]);

    const { allRows, rowVirtualizer, hasNextPage } = usePaginator({
        key: 'shared-by-all-216',
        source: filteredDashboards,
        limit: 20,
        itemHeight: 60,
        virtualRowsRef,
    });

    useEffect(() => {
        const timeout = setTimeout(() => {
            clearTimeout(timeout);
            if (!headerRef.current || !virtualRowsRef.current) return;

            virtualRowsRef.current.addEventListener("scroll", syncScroll);
            headerRef.current.addEventListener("wheel", syncScroll, { passive: false });
            headerRef.current.addEventListener("touchmove", syncScroll, { passive: false });
        }, 1000);

        return () => {
            clearTimeout(timeout);
            if (!headerRef.current || !virtualRowsRef.current) return;

            // eslint-disable-next-line
            virtualRowsRef?.current?.removeEventListener("scroll", syncScroll);
            headerRef?.current?.removeEventListener("wheel", syncScroll);
            // eslint-disable-next-line
            headerRef?.current?.removeEventListener("touchmove", syncScroll);
        };
    }, []);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setDashboardArray(filterDashboardList(search || '', workspace?.id));
            clearTimeout(timerRef.current);
        }, 500);

        return () => {
            clearTimeout(timerRef.current);
        }
    }, [filterDashboardList, workspace?.id, search]);

    useEffect(() => {
        if (searching) {
            getWorkspaceScoreFunc({workspaceId: workspace?.id || '', group: userGroup})
                .then((workspaceScores: any) => setWorkspaceScores(prev => {
                    if (!workspaceScores || !workspaceScores?.scores)
                        return prev;

                    const newScores: WorkspaceScoreItem[] = convertToWorkspaceScores(workspaceScores?.scores as string);
                    const updatedScores: WorkspaceScore[] = prev.map((prevScore: any) => {
                        if (prevScore.workspaceId === workspaceScores?.workspaceId) {
                            const mergedScores: WorkspaceScoreItem[] = [...convertToWorkspaceScores(prevScore.scores) ?? []];

                            newScores.forEach(newScore => {
                                const existingScoreIndex = mergedScores.findIndex(score => score.dashboardId === newScore.dashboardId);

                                if (existingScoreIndex !== -1) {
                                    mergedScores[existingScoreIndex] = newScore;
                                } else {
                                    mergedScores.push(newScore);
                                }
                            });

                            const updatedAt = moment(workspaceScores?.updatedAt);
                            const prevUpdatedAt = moment(prevScore.updatedAt);

                            return updatedAt.isAfter(prevUpdatedAt)
                                ? { ...prevScore, ...workspaceScores, scores: mergedScores }
                                : { ...prevScore, scores: mergedScores };
                        }

                        return prevScore;
                    });

                    if (!prev.some(prevScore => prevScore.workspaceId === workspaceScores?.workspaceId))
                        updatedScores.push({...workspaceScores, scores: newScores});

                    console.log('trigger', updatedScores);
                    return updatedScores;
                }));
            timerRef.current = setTimeout(() => {
                clearInterval(timerRef.current);
                setSearching(false);
                setSortBy('Fit score');
            }, 3000);
        }

        return () => {
            clearInterval(timerRef.current);
        }
    // eslint-disable-next-line
    }, [searching]);

    useEffect(() => {
        setLoading(!!timerRef.current && !dashboardArray && !search && !searching);
    }, [dashboardArray, search, searching]);

    useEffect(() => {
        onCount?.(filteredDashboards.length);
    // eslint-disable-next-line
    }, [filteredDashboards]);

    if (!workspace)
        return (<></>);

    return (<>
         {loading ? (<FallbackLoading />) : (<>
            {isPublicView && (<>
                <Typography className={classes.sharedTitle} pl={1} py={1}>
                    {`Deals shared:`}
                </Typography>
                <Divider sx={{ width: '100%' }} />
            </>)}
            <Stack className={classes.table}>
                {loading ? (<FallbackLoading />) : (<>
                    {withFilter && ( <DashboardsTableFilter count={filteredDashboards.length} onFilter={onFilter} /> )}
                        <Stack alignItems="flex-start" justifyContent="flex-start" height="100%">
                            <Box width="100%" height="fit-content" overflow="hidden">
                                <Box className={classes.header} ref={headerRef}>
                                    <DashboardsTableHeader
                                        columns={TableHeaders}
                                        columnSorted={sortBy}
                                        onColumnSort={setSortBy}
                                        onRefreshScores={() => handleRefresh()}
                                        actions={['Remove from workspace']}
                                        xAdjust={80}
                                        noStatus multiAction reducedWidth />
                                </Box>
                            </Box>
                            {(!!filteredDashboards.length) ? (<>
                                <Box width="100%" height="100%" overflow="hidden">
                                    <Box className={classes.rows} ref={virtualRowsRef}
                                        sx={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                                        {rowVirtualizer.getVirtualItems().map((virtualRow: VirtualItem) => (
                                            <Box className={classes.row}
                                                sx={{  height: `${virtualRow.size}px`, transform: `translateY(${virtualRow.start}px)`, }}
                                                key={'shared-by-all-372-' + virtualRow.index} >
                                                {(virtualRow.index > allRows.length - 1) && hasNextPage
                                                    ? (<FallbackLoading />)
                                                    : (<DashboardsTableRow
                                                            dashboard={allRows[virtualRow.index]}
                                                            workspace={workspace ?? undefined}
                                                            columns={TableHeaders}
                                                            collectionKey={collectionKey}
                                                            timestamp={getMembership(allRows[virtualRow.index])?.updatedAt}
                                                            refreshingScore={searching}
                                                            overview={extSharedWorkspace}
                                                            noStatus isPublic reducedWidth />)}
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </>) : (<NoDashboardsFoundView noCompaniesYet={!search && !dashboardArray?.length} noCompaniesFound />)}
                    </Stack>
                </>)}
            </Stack>
        </>)}
    </>);
}

export default SharedByAll;