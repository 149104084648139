import { Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { DashboardsContext } from "../../contexts/DashboardsContext";
import { getCurrentUserWorkspaceUserMembershipsFunc, getPublicWorkspaceFunc, getWorkspaceUserMembershipsFunc } from "../../lib/helper";
import { scrollbarStyle } from "../../shared/dashboard";
import { WorkspaceUserMembershipStatus } from "../../types/common";
import { Dashboard, Workspace, WorkspaceUserMembership } from "../../types/files";
import WorkspacesTableHeader, { InvitationTableHeaders } from "../molecules/workspaces-table/WorkspacesTableHeader";
import WorkspacesTableRow from "../molecules/workspaces-table/WorkspacesTableRow";
import { collectionKey } from "../pages/InvestorsPage";
import { GroupSettingsContext } from "../../contexts/GroupSettingsContext";

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 600,
        color: theme.colors.neutral['500'],
        textAlign: 'left',
    },
    table: {
        width: '100%',
        height: 'fit-content',
        minHeight: '126px',
        maxHeight: '246px',
        overflow: 'hidden',
    },
    headers: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        ...scrollbarStyle,
    },
    rows: {
        minWidth: '100%',
        width: 'fit-content',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
}));
const SharedWorkspaceInvite: React.FC<{
    flags?: any,
    onInviteCount?: (count: number) => void,
 }> = ({flags, onInviteCount}) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { setSharedWorkspaces } = useContext(GroupSettingsContext);
    const { setExternalDashboards } = useContext(DashboardsContext);

    const [pendingWorkspaces, setPendingWorkspaces] = useState<(Workspace & {group: string})[]>([]);
    const [investorCounts, setInvestorCounts] = useState<number[]>([]);

    const getSharedWorkspaces = useCallback(async () => {
        const invitations = await getCurrentUserWorkspaceUserMembershipsFunc();
        const invites = invitations as {items: WorkspaceUserMembership[]};
        const invitedWorkspaces = (invites?.items?.filter(shared =>
            shared.status === WorkspaceUserMembershipStatus.PENDING
        ).map(workspace => ({
            id: workspace.workspaceId,
            name: workspace.name,
            group: workspace.workspaceGroup,
            isPublic: true,
            isShared: true,
        })) ?? []) as (Workspace & {group: string})[];

        if (!!invitedWorkspaces.length) {
            const promises: any[] = [];

            invitedWorkspaces.forEach(ws => promises.push(
                getPublicWorkspaceFunc({ id: ws.id, group: ws.group, })
            ));

            const collection = await Promise.all(promises);

            collection.filter(col => !!col).forEach(data =>
                setExternalDashboards(prev =>  {
                    const allExternals = [...prev, ...(data?.dashboards as Dashboard[])];

                    return allExternals.filter((dashboard, i, self) =>
                        i === self.findIndex(d => d.id === dashboard.id)
                    );
                })
            );
        }

        setPendingWorkspaces(invitedWorkspaces);
    }, [setExternalDashboards]);

    const handleInvite = useCallback((workspace: Workspace) => {
        setPendingWorkspaces(prev => prev.filter(ws => ws.id !== workspace.id));
        setSharedWorkspaces(prev => [workspace, ...prev]);
    }, [setSharedWorkspaces]);

    useEffect(() => {
        if (!!userGroup)
            getSharedWorkspaces();
    // eslint-disable-next-line
    }, [userGroup]);

    useEffect(() => {
        if (!!pendingWorkspaces.length)  {
            Promise.all(pendingWorkspaces.map(ws => getWorkspaceUserMembershipsFunc({workspaceId: ws.id})))
                .then(memberships => {
                    setInvestorCounts(memberships.map(membership => {
                        const members = membership as {items: WorkspaceUserMembership[]};

                        return members?.items?.length ?? 0;
                    }));
                });
        }
    // eslint-disable-next-line
    }, [pendingWorkspaces]);

    useEffect(() => onInviteCount?.(pendingWorkspaces.length)
        // eslint-disable-next-line
    , [pendingWorkspaces]);

    if (!pendingWorkspaces.length)
        return (<></>);

    return (<>
        <Stack width="100%" height="fit-content" my={2}>
            <Typography className={classes.title}>
                {'Invites to deal sharing workspaces '}
                <Typography component="span" fontFamily="Inter" fontSize="1.1rem" fontWeight="500" display="inline">
                    {`(${pendingWorkspaces.length ?? 0})`}
                </Typography>
            </Typography>
            <Stack className={classes.table}>
                <Stack className={classes.headers} alignItems="flex-start" justifyContent="flex-start">
                    <WorkspacesTableHeader columns={InvitationTableHeaders} />
                    <Stack className={classes.rows} alignItems="flex-start" justifyContent="flex-start">
                        {pendingWorkspaces.map((workspace, i) => (
                            <WorkspacesTableRow
                                workspace={workspace}
                                columns={InvitationTableHeaders}
                                collectionKey={collectionKey}
                                investors={investorCounts[i]}
                                onInvite={handleInvite}
                                noMailing noMenu noSharing overview invite
                                key={'shared-workspace-invite-125-' + workspace.id} />
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    </>);
}

export default withLDConsumer()(SharedWorkspaceInvite);