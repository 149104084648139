import { Box, Button, Divider, List, ListItem, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import {AuthContext} from '../../../contexts/AuthContext';
import { getUserEmail } from '../../../helpers/authUser';
import theme from '../../../theme';

const useStyles = makeStyles((theme) => ({
    titlePlan: {
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: '2rem',
        color: theme.colors.primary[600],
    },
    featureList: {
        fontFamily: 'Inter',
        fontWeight: '500',
        fontSize: '1rem',
    },
    featureCount: {
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: '1rem',
        color: theme.colors.primary[600],
    },
    originalPriceText: {
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: '2rem',
        color: theme.colors.neutral[400],
    },
    planButton: {
        width: 280,
        height: 50,
        borderRadius: 32,
        borderWidth: 3,
        textTransform: 'none',
        padding: '8px 24px',
        fontSize: '1.25rem',
        fontWeight: 'bold',
    },
    url: {
        color: theme.colors.cyan[400],
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'none !important',
        }
    },
}));

const FreePlan: React.FC<{
    selected: boolean,
    admin: string,
    daysLeft?: number,
    hasPayment: boolean,
    loaded: boolean,
}> = ({ selected, admin, daysLeft, hasPayment, loaded }) => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const [isAdmin, setAdmin] = useState<boolean>(false);

    useEffect(() => {
        if (user && admin)
            setAdmin(admin === getUserEmail(user).toLowerCase());
    }, [admin, user]);

    return (<Box position="relative">
        <Stack direction="row" alignItems="center" justifyContent="space-between" p={2} height="80px">
            <Typography className={classes.titlePlan}>Starter</Typography>
            <Typography className={classes.originalPriceText}>Free</Typography>
        </Stack>
        <Divider />
        <Stack alignItems="flex-start" p={2} height="304px">
            <Typography fontFamily="Poppins" fontWeight="bold" fontSize="1.2rem">What's included:</Typography>
            <List sx={{ width: '100%', margin: '0 32px', listStyleType: 'disc' }}>
                <ListItem sx={{ paddingLeft: 'unset', display: 'list-item' }}>
                    <Typography className={classes.featureCount} display="inline">0 companies</Typography>
                    <Typography className={classes.featureList} display="inline"> per month</Typography>
                </ListItem>
                <ListItem sx={{ paddingLeft: 'unset', display: 'list-item' }}>
                    <Typography className={classes.featureList}>Forward deals to your Notissia inbox</Typography>
                </ListItem>
                <ListItem sx={{ paddingLeft: 'unset', display: 'list-item' }}>
                    <Typography className={classes.featureList}>Access to your data in Notissia</Typography>
                </ListItem>
            </List>
        </Stack>
        <Divider sx={{ display: !selected && !!daysLeft && !hasPayment ? 'none' : 'inherit' }}/>
        {loaded ? (
             <Stack alignItems="center" justifyContent="center" p={2} height="112px">
                {selected ? (
                    <Button variant="contained" className={classes.planButton} disabled>Current plan</Button>
                ) : (daysLeft === undefined || hasPayment) && (
                    (!isAdmin) ? (<>
                        <Typography color="grey" textAlign="center">
                            {'Contact your account administrator to downgrade to Starter '}
                                (<Typography
                                    component="a"
                                    className={classes.url}
                                    href={'mailto:' + admin}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    display="inline"
                                >{admin}</Typography>)
                        </Typography>
                    </>) : (
                        <Typography color={theme.colors.primary[600]} textAlign="center">
                            {'To downgrade to Starter, go to Manage My Subscription below and cancel your subscription.'}
                        </Typography>
                    )
                )}
            </Stack>
        ) : (
            <Box height="112px" />
        )}
    </Box>);
}

export default FreePlan;