import { Button, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {ReactComponent as DashboardIcon } from "../../../../assets/icons/dashboard.svg";
import {ReactComponent as InvestorsIcon} from "../../../../assets/icons/investors.svg";
import theme from "../../../../theme";
import BulkShareDeals, { BulkShareCuratedTabs } from "../../../atoms/[legacy]/home-bulk-share-deals";

const useStyles = makeStyles((theme) => ({
    mainContent: {
        width: 532,
        height: 'fit-content',
        padding: '16px 8px',
        overflow: 'hidden',
    },
    mainTitle: {
        fontFamily: 'Inter',
        fontSize: '1.25rem !important',
        fontWeight: '700 !important',
        color: theme.colors.neutral['600'],
    },
    shareButton: {
        minWidth: 250,
        width: 'min-content',
        padding: '8px 16px',
        borderRadius: 12,
        background: theme.palette.common.white,
        color: theme.palette.common.black,
        textTransform: 'none',
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 700,
        transition: 'ease-in-out 300ms',
    },
}));

const ShareSelect: React.FC<{
    onSelect?: (selection: BulkShareCuratedTabs) => void,
}> = ({ onSelect }) => {
    const classes = useStyles();

    return (<>
        <BulkShareDeals.Panel value={BulkShareCuratedTabs.Selection}
            content={(
                <Stack direction="column" className={classes.mainContent} alignItems="flex-start" justifyContent="stretch">
                    <Typography className={classes.mainTitle}>{'Start by selecting...'}</Typography>
                </Stack>
            )}
            actions={(
                <Stack direction="row" gap={4} alignItems="center" justifyContent="space-around" pb={1}>
                    <Button variant="outlined"
                        className={classes.shareButton}
                        startIcon={<DashboardIcon height={24} width={24} fill={theme.palette.primary.main} />}
                        onClick={() => onSelect?.(BulkShareCuratedTabs.Companies)}>
                        {'Companies to share'}
                    </Button>
                    <Button variant="outlined"
                        className={classes.shareButton}
                        startIcon={<InvestorsIcon height={24} width={24} fill={theme.palette.primary.main} />}
                        onClick={() => onSelect?.(BulkShareCuratedTabs.Investors)}>
                        {'Investors to share with'}
                    </Button>
                </Stack>
            )}
            noActionDiv />
    </>);
}

export default ShareSelect;