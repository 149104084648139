import React, { useCallback, useContext } from 'react';
import { Box, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Dashboard } from '../../../types/files';
import {DashboardContext} from '../../../contexts/DashboardContext';
import useDashboard from '../../../hooks/useDashboard';
import DealOwnerSelector from './DealOwnerSelector';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'inline-block',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        padding: 'unset',
        border: 'unset',
        background: 'transparent',
        transition: 'all 300ms ease',
        "&:hover": {
            border: `unset !important`,
            background: 'transparent !important',
            boxShadow: 'unset !important',
        },
    },
    answerContainer: {
        width: 'auto',
        height: 'auto',
        marginLeft: 4,
        paddingLeft: 4,
        paddingRight: 16,
        border: `1px transparent solid`,
        borderRadius: 12,
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: '#666666',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginLeft: 8,
    }
}));

const DealOwner: React.FC<{ dashboard: Dashboard }> = ({ dashboard }) => {
    const classes = useStyles();
    const { isPublicView } = useContext(DashboardContext);
    const { updateOwner } = useDashboard();

    const handleSelectOwner = useCallback(async (answer: string) => {
        updateOwner(answer);
    }, [updateOwner]);

    return (<>
        <Box className={classes.masonryElement}>
            <Stack direction="column" alignItems="flex-start" width="100%">
                <Typography className={classes.title}>{'Deal Owner'}</Typography>
                <Stack className={classes.answerContainer}>
                    <DealOwnerSelector
                        defaultOwner={dashboard.userId ?? undefined}
                        onSelectOwner={handleSelectOwner}
                        readOnly={isPublicView} />
                </Stack>
            </Stack>
        </Box>
    </>);
}

export default DealOwner;
