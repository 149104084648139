import React from "react";
import { Box, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import theme from "../../../../theme";
import DashboardCardAssignItem from "../../dashboards/DashboardCardAssignItem";
import WorkspaceEmailEditor from "../../workspaces/WorkspaceEmailEditor";
import { Dashboard, Workspace } from "../../../../types/files";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
        height: 'fit-content',
        padding: '8px 12px',
        borderRadius: 16,
        border: `2px solid ${theme.colors.cyan['200']}`,
    },
    name: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
}));

const BulkShareAssignCard: React.FC<{
    workspace: Workspace,
    dashboards: Dashboard[],
    onWorkspace?: (workspace: Workspace) => void,
}> = ({ workspace, dashboards, onWorkspace }) => {
    const classes = useStyles();

    return (<>
        <Stack direction="row" className={classes.wrapper} alignItems="center" justifyContent="space-between">
            <Stack direction="column" alignItems="flex-start" width="100%">
                {dashboards.map((dashboard: Dashboard) => (
                    <DashboardCardAssignItem
                        dashboard={dashboard}
                        key={'bulk-share-assign-card-' + dashboard.id}
                    />
                ))}
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center" width="fit-content">
                <ArrowForwardRoundedIcon sx={{ width: 32, height: 32,  color: theme.colors.neutral['500'] }} />
                <WorkspaceEmailEditor
                    workspace={workspace}
                    onUpdate={onWorkspace} />
            </Stack>
            <Box width="10%" />
        </Stack>
    </>);
};

export default BulkShareAssignCard;