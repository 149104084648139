import { Divider, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState, useContext } from "react";
import WorkspacesSearchOrCreate from "../../workspaces-search-create/WorkspacesSearchOrCreate";
import {GroupSettingsContext} from "../../../../contexts/GroupSettingsContext";
import NoWorkspacesFoundView from "../../workspaces-empty/NoWorkspacesFoundView";
import DashboardListItem from "../../../atoms/dashboards/DashboardListItem";
import { Dashboard } from "../../../../types/files";
import configuredAsyncComponentLoader from "../../../templates/loader";

const InvestorsTable = configuredAsyncComponentLoader(() => import('../../../organisms/investors/InvestorsTable'));

const useStyles = makeStyles((theme) => ({
    mainContent: {
        width: '45vw',
        height: 'max-content',
        maxHeight: '60vh',
        padding: 'unset',
        overflow: 'hidden',
    },
    mainTitle: {
        fontFamily: 'Inter',
        fontSize: '1.3rem !important',
        fontWeight: '700 !important',
        color: theme.palette.primary.main,
    },
    divider: {
        width: '100%',
        borderColor: theme.colors.cyan['100'],
        margin: '8px 4px',
    },
    commonButton: {
        width: 'auto',
        minWidth: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    nextButton: {
        width: 'auto',
        minWidth: 100,
        borderRadius: 32,
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
    },
}));

const ShareEachInvestor: React.FC<{ dashboard: Dashboard }> = ({ dashboard }) => {
    const classes = useStyles();
    const { workspaces } = useContext(GroupSettingsContext);
    const [search, setSearch] = useState<string>('');

    return (<>
        <Typography className={classes.mainTitle}>
            {`Select which investors you'd like to share this company with:`}
        </Typography>
        <Divider className={classes.divider} />
        <DashboardListItem dashboard={dashboard} queryTitles={['Description']} noSharing solo />
        <Divider className={classes.divider} />
        <Stack alignItems="center" width="100%" px={3}>
            <WorkspacesSearchOrCreate search mediumFit onSearch={(value) => setSearch(value)} />
        </Stack>
        {!!workspaces.length ? (
            <InvestorsTable
                dashboard={dashboard}
                collectionKey={`selected-investors:${dashboard.id}`}
                search={search}
                forSharing noMailing />
        ) : (<NoWorkspacesFoundView noResult />)}
    </>);
}

export default ShareEachInvestor;