import MenuIcon from '@mui/icons-material/Menu';
import { Divider, Popover, Stack, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useCallback, useContext, useState } from 'react';
import { GroupSettingsContext } from '../../../contexts/GroupSettingsContext';
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import MailIcon from '@mui/icons-material/MailOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import ExcludeCompanyModal from './ExcludeCompanyModal';

const useStyles = makeStyles((theme) => ({
    modal: {
        width: 420,
        zIndex: 1300,
        '& > .MuiPaper-root': {
            width: '100%',
            height: 'fit-content',
            padding: '16px 12px',
            marginTop: 8,
            borderRadius: 16,
        }
    },
    title: {
        fontFamily: 'Inter',
        fontWeight: 'bold',
        fontSize: '1.2rem',
        color: '#000',
    },
    subtitle: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '1rem',
        color: '#666666',
        textTransform: 'none',
        textDecoration: 'none',
        "&:hover": {
            background: 'transparent',
        },
    },
    label: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '0.9rem',
        color: theme.colors.neutral['600'],
    },
    action: {
        width: '100%',
        fontWeight: 'bold',
        color: '#7bd4d4',
        "&:hover": {
            background: 'rgba(4, 130, 144, 0.04)'
        },
        cursor: 'pointer',
    },
    mainIcon: {
        width: 32,
        height: 32,
        fill: '#666666',
    },
    menuIcon: {
        width: 24,
        height: 24,
        fill: '#048290',
    },
    switchButton: {
        width: 80,
        height: 36,
        padding: 0,
        transform: 'scale(0.7)',
        '& .MuiSwitch-switchBase': {
            padding: '6px',
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(40px)',
                '& + .MuiSwitch-track': {
                    border: '2px solid #048290',
                    backgroundColor: '#fff',
                    opacity: 1,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 24,
            height: 24,
            color: '#048290',
        },
        '& .MuiSwitch-track': {
            borderRadius: '20px',
            border: '2px solid #048290',
            backgroundColor: '#fff',
            opacity: 1,
            '&:before, &:after': {
                position: 'absolute',
                color: '#7bd4d4',
                fontFamily: 'Inter',
                fontSize: '0.8rem',
                fontWeight: 600,
            },
            '&:before': {
                content: '"ON"',
                padding: '7px',
            },
            '&:after': {
                content: '"OFF"',
                padding: '7px 0 0 43px',
            },
        },
    },
}));

const AutomationSettings: React.FC<{ flags?: any }> = ({ flags }) => {
    const classes = useStyles();
    const { externalSync, autoIngestFromEmail, autoSendDigestEmail,
        setExternalSync, setAutoIngestFromEmail, setAutoSendDigestEmail } = useContext(GroupSettingsContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [excludeCompanyModalOpen, setExcludedCompanyModalOpen] = useState<boolean>(false);

    const handleAutoIngestFromEmail = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        setAutoIngestFromEmail(event.target.checked);
    }, [setAutoIngestFromEmail]);

    const handleExternalSync = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        setExternalSync(event.target.checked);
    }, [setExternalSync]);

    const handleAutoSendDigestEmail = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        setAutoSendDigestEmail(event.target.checked);
    }, [setAutoSendDigestEmail]);

    const handleExclude = useCallback(() => {
        setExcludedCompanyModalOpen(true);
        setAnchorEl(null);
    }, []);

    return (<>
        <Button variant="text"
            onClick={event => setAnchorEl(event.currentTarget)}
            endIcon={<MenuIcon className={classes.mainIcon} />}
            sx={{ "&:hover": { background: 'transparent' } }}
            disableRipple />
        <Popover open={Boolean(anchorEl)}
            className={classes.modal}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
            transformOrigin={{ vertical: 'top', horizontal: 'center', }}
            onClose={() => setAnchorEl(null)}>
            <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="center" width="100%">
                <Typography className={classes.title} display="inline" pl={1}>
                    {'Menu'}
                </Typography>
                <Divider sx={{ width: '100%' }} />
                <Stack direction="column" spacing={2} alignItems="flex-start" justifyContent="center" width="100%">
                    <Button variant="text" className={classes.subtitle}
                        onClick={undefined}
                        startIcon={<NotificationsIcon className={classes.menuIcon} />}
                        sx={{ "&:hover": { background: 'transparent' } }}
                        disableRipple>
                        {'Notifications'}
                    </Button>
                    <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" width="100%">
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" pl="4px">
                            <SettingsIcon className={classes.menuIcon} />
                            <Typography className={classes.subtitle}>
                                {'Automation settings'}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" width="100%" pl={4}>
                            <Typography className={classes.label}>
                                {'Auto-add companies from emails'}
                            </Typography>
                            <Switch className={classes.switchButton} checked={autoIngestFromEmail} onChange={handleAutoIngestFromEmail} disableRipple />
                        </Stack>
                        {flags.zapierPlan && (
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" width="100%" pl={4}>
                                <Typography className={classes.label}>
                                    {'Auto-sync to external tools'}
                                </Typography>
                                <Switch className={classes.switchButton} checked={externalSync} onChange={handleExternalSync} disableRipple />
                            </Stack>
                        )}
                    </Stack>
                </Stack>
                <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" width="100%">
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" pl="4px">
                        <MailIcon className={classes.menuIcon} />
                        <Typography className={classes.subtitle}>
                            {'Mailing settings'}
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" width="100%" pl={4}>
                        <Typography className={classes.label}>
                            {'Send weekly deal flow digest'}
                        </Typography>
                        <Switch className={classes.switchButton} checked={autoSendDigestEmail} onChange={handleAutoSendDigestEmail} disableRipple />
                    </Stack>
                </Stack>
                <Button variant="text" className={classes.subtitle}
                    onClick={handleExclude}
                    startIcon={<DoNotDisturbIcon className={classes.menuIcon} />}
                    sx={{ "&:hover": { background: 'transparent' } }}
                    disableRipple>
                    {'Excluded Companies'}
                </Button>
            </Stack>
        </Popover>
        {excludeCompanyModalOpen && (
            <ExcludeCompanyModal isOpen={excludeCompanyModalOpen}
                onClose={() => setExcludedCompanyModalOpen(false)} />
        )}
    </>);
}

export default withLDConsumer()(AutomationSettings);
