import TeamQuery from "../../molecules/dashboard-queries/TeamQuery";
import KeyPeopleQuery from "../../molecules/dashboard-queries/KeyPeopleQuery";
import AddKeyPeople from "../../atoms/AddKeyPeople";
import { useContext, useEffect, useState } from "react";
import {DashboardContext} from "../../../contexts/DashboardContext";

const PeopleColumnQueries: React.FC<{ onHidePeopleColumn ?: (state: boolean) => void}> = ({ onHidePeopleColumn }) => {
    const { isPublicView } = useContext(DashboardContext);
    const [keyPeopleCount, setKeyPeopleCount] = useState<number>(0);
    const [hasTeamQuery, setHasTeamQuery] = useState<boolean>(false);

    useEffect(() => {
        onHidePeopleColumn?.(isPublicView && !hasTeamQuery && !keyPeopleCount);
    // eslint-disable-next-line
    }, [hasTeamQuery, isPublicView, keyPeopleCount]);

    if (isPublicView && !hasTeamQuery && !keyPeopleCount)
        return (<></>);

    return (<>
        <TeamQuery onHasTeamQuery={setHasTeamQuery} />
        <KeyPeopleQuery onKeyPeopleCount={setKeyPeopleCount} />
        {!isPublicView && (<AddKeyPeople />)}
    </>);
}

export default PeopleColumnQueries;