import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, IconButton, InputAdornment, Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import { useCallback, useContext, useRef, useState } from "react";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import { scrollbarStyle } from '../../../shared/dashboard';
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            minWidth: 550,
            height: 'fit-content',
            padding: 16,
            borderRadius: 24,
        },
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 8,
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.3rem',
        gap: 8,
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: 8,
        height: '100%',
        overflowY: 'auto',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 16,
    },
    mainIcon: {
        width: 32,
        height: 32,
        fill: '#048290',
    },
    subtitle: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: '#000',
    },
    text: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        color: theme.colors.neutral['600'],
    },
    item: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        color: '#666666',
    },
    inputField: {
        minWidth: 'fit-content',
        "& .MuiOutlinedInput-root": {
            minWidth: 250,
            height: 40,
            borderRadius: 24,
            padding: '0 8px !important',
            background: '#fff',
            "& > input": {
                padding: 'unset !important',
                fontSize: '1rem',
            },
            "& fieldset": {
              	borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              	borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              	borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    },
    addButton: {
        width: 80,
        height: 40,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    closeButton: {
        minWidth: 80,
        height: 36,
        borderRadius: 32,
        fontSize: '0.95rem',
        fontWeight: 'bold !important',
        textTransform: 'none !important' as any,
        transition: 'ease-in-out 300ms',
    },
    deleteButton: {
        cursor: 'pointer',
        color: theme.colors.neutral['400'],
        transition: 'ease-in-out 300ms',
        '&:hover': {
            color: theme.colors.neutral['600'],
        },
    },
}));

const ExcludeCompanyModal: React.FC<{
    isOpen: boolean,
    onClose: () => void,
}> = ({ isOpen, onClose }) => {
    const classes = useStyles();
    const { excludedCompanyTerms, setExcludedCompanyTerms } = useContext(GroupSettingsContext);
    const [ready, setReady] = useState<boolean>(false);
    const websiteRef = useRef<TextFieldProps>(null);

    const handleExclude = useCallback(() => {
        if (!!websiteRef.current?.value) {
            setExcludedCompanyTerms(prev => Array.from(new Set([...prev, websiteRef.current?.value as string])));
            setReady(false);
            websiteRef.current.value = '';
        }
    }, [setExcludedCompanyTerms]);

    const handleRemove = useCallback((index: number) => {
        setExcludedCompanyTerms(prev => prev.filter((_, i) => index !== i));
    }, [setExcludedCompanyTerms]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={onClose}>
            <DialogTitle className={classes.dialogTitle}>
                <DoNotDisturbIcon className={classes.mainIcon} />
                {'Excluded Companies'}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Stack direction="column" spacing={2} alignItems="flex-start" width="100%">
                    <Stack direction="column" alignItems="flex-start" width="100%">
                        <Typography className={classes.text}>
                            {`Add company names and/or websites that will be skipped when`}
                        </Typography>
                        <Typography className={classes.text}>
                            {`ingesting deals from emails.`}
                        </Typography>
                    </Stack>
                    <Stack direction="column" spacing="4px" alignItems="flex-start" width="100%">
                        <Typography className={classes.subtitle}>
                            {`Excluded terms`}
                        </Typography>
                        <Stack direction="row"  alignItems="center" justifyContent="space-between" width="100%">
                            <TextField variant="outlined"
                                className={classes.inputField}
                                placeholder={'Company name or website ...'}
                                inputRef={websiteRef}
                                onChange={() => setReady(!!websiteRef.current?.value)}
                                sx={{ width: '80%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AddRoundedIcon sx={{ fill: theme.palette.primary.main }} />
                                        </InputAdornment>
                                    ),
                                }} />
                            <Button variant="outlined"
                                className={classes.addButton}
                                onClick={handleExclude}
                                disabled={!ready}
                                disableRipple> {'Add'} </Button>
                        </Stack>
                    </Stack>
                    <Box />
                    <Stack direction="column" spacing={1} alignItems="flex-start" width="100%">
                        <Typography className={classes.subtitle}>
                            {`Excluded terms list`}
                        </Typography>
                        <Divider sx={{ width: '100%' }} />
                        <Stack direction="column" spacing="4px" alignItems="flex-start" width="100%"
                            height="fit-content" minHeight={100} maxHeight={300} paddingX={1}
                            sx={{ overflowX: 'hidden', overflowY: 'auto', ...scrollbarStyle }}>
                            {excludedCompanyTerms.map((term, i) => (
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography className={classes.item}>
                                        {term}
                                    </Typography>
                                    <IconButton className={classes.deleteButton}
                                        onClick={() => handleRemove(i)}
                                    > <DeleteIcon /> </IconButton>
                                </Stack>
                            ))}
                        </Stack>
                        <Divider sx={{ width: '100%' }} />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button variant="contained"
                    className={classes.closeButton}
                    onClick={onClose}
                > {'Close'} </Button>
            </DialogActions>
        </Dialog>
    </>);
}

export default ExcludeCompanyModal;
