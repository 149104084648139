import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import classnames from "classnames";
import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import theme from "../../../theme";
import DashboardTagModal from "../../modals/dashboards-overview/DashboardTagModal";
import CompaniesMultiAction from "../multi-action/CompaniesMultiAction";
import SyncIcon from '@mui/icons-material/Sync';
import { DashboardsContext } from '../../../contexts/DashboardsContext';

const useStyles = makeStyles((theme) => ({
    row: {
        minWidth: '100%',
        width: 'fit-content',
        height: 'fit-content',
    },
    header: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['900'],
        textTransform: 'none',
        paddingLeft: 'unset !important',
        "&:hover": {
            background: 'unset',
        }
    },
    lightHeader: {
        fontWeight: '600',
        color: theme.colors.neutral['600'],
    },
    checkbox: {
        minWidth: 24,
        maxWidth: 24,
    },
    cell: {
        minWidth: 180,
        maxWidth: 180,
        minHeight: 60,
        maxHeight: 60,
        padding: '0 10px',
    },
    narrowCell: {
        minWidth: 150,
        maxWidth: 150,
    },
    narrowerCell: {
        minWidth: 50,
        maxWidth: 50,
    },
    customNarrowCell: {
        minWidth: 20,
        maxWidth: 20,
    },
    wideCell: {
        minWidth: 280,
        maxWidth: 280,
    },
    widerCell: {
        minWidth: 380,
        maxWidth: 380,
    },
    fullWidthCell: {
        minWidth: 0,
        maxWidth: 'unset !important',
        width: '100%',
    },
    hiddenCell: {
        display: 'none',
    },
    endCell: {
        minWidth: 4,
        maxWidth: 4,
        padding: 'unset',
    },
    addSector: {
        minWidth: 'unset',
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        color: theme.palette.primary.light,
        textTransform: 'none',
        "&:hover": {
            background: 'unset',
        }
    },
    customCell: {
        minWidth: 240,
        maxWidth: 240,
    },
    sortHeaders: {
        minWidth: 'unset',
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: '600',
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        "&:hover": {
            background: 'unset',
        }
    },
}));

export const TableHeaders = ['Name', '', 'Website', 'Fit score', 'Stage', 'Sectors', 'Description', 'Location', 'Data from...', 'Deal owner', 'Deal source', 'Fundraising', 'Revenue', 'Valuation', 'Margins', 'Request Intro', '', 'Added', ''];
export const RowActions = ['Find investors & share', 'Move to', 'Copy deals snippet', 'Add to shared workspace', 'Download as PDF', 'Remove from workspace', 'Delete'];

const DashboardsTableHeader: React.FC<{
    columns: string[],
    actions?: string[],
    multiAction?: boolean,
    lightHeader?: boolean,
    noStatus?: boolean,
    editableSector?: boolean,
    columnSorted?: string,
    xAdjust?: number,
    reducedWidth?: boolean,
    onColumnSort?: (column: string) => void,
    onRefreshScores?: () => void,
}> = ({ columns, actions, multiAction, lightHeader, noStatus, editableSector, columnSorted, xAdjust, reducedWidth,
    onColumnSort, onRefreshScores }) => {
    const classes = useStyles();
    const { workspace } = useContext(DashboardsContext);
    const [manageTagsOpenModal, setManageTagsOpenModal] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState<string>('Added');
    const multiActionRef = useRef<any>(null);

    const props = { actions, xAdjust };

    const showColumn = (column: string) => columns.join(';').includes(column);

    const handleColumnSort = useCallback((column: string) => {
        setSortBy(column);
        onColumnSort?.(column);
    }, [onColumnSort]);

    useEffect(() => {
        if (columnSorted !== undefined)
            setSortBy(columnSorted);
    }, [columnSorted]);

    return (<>
        <Stack direction="row" className={classes.row} alignItems="flex-start" justifyContent="flex-start" ref={multiActionRef}>
            <Stack className={classes.checkbox} />
            {TableHeaders.map((header, i, self) => (i === 1 ? !noStatus : showColumn(header)) && (
                <Fragment key={'dashboads-table-header-74-' + i}>
                    <Stack direction="column" className={classnames(classes.cell,
                        (i === 0) && !reducedWidth && classes.customCell,
                        (i === 3) && !reducedWidth && classes.customCell,
                        (i === 5) && classes.wideCell,
                        (i === 6) && classes.widerCell,
                        (i === 7) && classes.customCell,
                        (i === 10) && classes.wideCell,
                        (i === self.length - 3) && classes.fullWidthCell,
                        (i === self.length - 2) && classes.narrowCell,
                        (i === self.length - 1) && classes.narrowerCell)}
                        alignItems="flex-start" justifyContent="center" >
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                            {!!onColumnSort && (['Fit score', 'Added'].includes(header)) ? (
                                <Button variant="text"
                                    className={classnames(classes.header, lightHeader && classes.lightHeader)}
                                    onClick={() => handleColumnSort(header)}
                                    disableRipple>
                                    {header}
                                    {sortBy === header ? (
                                        <ArrowDropDownIcon sx={{ fill: theme.palette.primary.main, transform: 'scale(1.5, 2.5)', translate: '4px 0px'}} />
                                    ) : (
                                        <ChangeHistoryIcon sx={{ fill: theme.colors.neutral['600'],
                                            transform: 'rotate(180deg) scale(0.85, 0.85)', translate: '4px 1px' }} />
                                    )}
                                </Button>
                            ) : !['Request Intro'].includes(header) && (
                                <Typography className={classnames(classes.header, lightHeader && classes.lightHeader)}>{header}</Typography>
                            )}
                            {editableSector && (header === 'Sectors') && (
                                <Button variant="text"
                                    className={classes.addSector}
                                    onClick={() => setManageTagsOpenModal(true)}
                                    disableRipple> {'Add...'} </Button>
                            )}
                            {!!onRefreshScores && (header === 'Fit score') && (<>
                                <Tooltip placement="top-start" title={
                                    <Typography component="span" fontSize="0.95rem" fontWeight={500}>
                                        {workspace?.isShared ? `Fit scores are calculated based on your deal preferences.`
                                            : `Calculate fit scores for each company based on ${workspace?.name || 'the workspace'}'s preferences.`}
                                    </Typography>}
                                    componentsProps={{tooltip:{sx:{
                                        display: 'flex', alignItems: 'center', borderRadius: '12px', transform: 'translate(-90px) !important',
                                        backgroundColor: 'rgba(150, 150, 150, 0.95)', width: '210px', height: '110px',  padding: 2,
                                    }}}}>
                                    <IconButton onClick={() => onRefreshScores?.()} sx={{ padding: 'unset' }} disableRipple>
                                        <SyncIcon sx={{ fill: theme.palette.primary.main, transform: 'scale(1.05, 1.05)', translate: '-5px 1px' }} />
                                    </IconButton>
                                </Tooltip>
                            </>)}
                        </Stack>
                    </Stack>
                    {(header === 'Sectors') && showColumn(header) && (
                        <Stack className={classnames(classes.cell, classes.customNarrowCell)} />
                    )}
                </Fragment>
            ))}
            <Stack className={classnames(classes.cell, classes.endCell)} />
        </Stack>
        {multiAction && (<CompaniesMultiAction {...props} ref={multiActionRef} />)}
        {manageTagsOpenModal && (
            <DashboardTagModal
                open={manageTagsOpenModal}
                onClose={() => setManageTagsOpenModal(false)}
                forManagement
            />
        )}
    </>);
}

export default DashboardsTableHeader;