import { Checkbox } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { memo, useEffect, useRef, useState } from "react";

const useStyles = makeStyles(() => ({
    checkBox: {
        padding: 'unset !important',
        marginRight: '4px !important',
        "& > svg": {
            width: '20px !important',
            height: '20px !important',
        }
    },
}));

const CheckSelect: React.FC<{
    selected: boolean,
    cleared?: boolean,
    onSelect: () => void,
    onClear: () => void,
}> = ({ selected, cleared, onSelect, onClear, }) => {
    const classes = useStyles();
    const [checked, setChecked] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            if (loaded) {
                if (!checked && selected)
                    setChecked(true);
                else if (checked && !selected)
                    setChecked(false);
            }

            clearTimeout(timerRef.current);
        }, 200);

        return () => {
            clearTimeout(timerRef.current);
        }
    // eslint-disable-next-line
    }, [selected]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            if (loaded) {
                if (checked && !selected)
                    onSelect();
                else if (!checked && selected)
                    onClear();
            }

            clearTimeout(timerRef.current);
        }, 200);

        return () => {
            clearTimeout(timerRef.current);
        }
    // eslint-disable-next-line
    }, [checked]);

    useEffect(() => {
        if (!loaded) {
            setChecked(selected);
            setLoaded(true);
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (cleared || !selected)
            setChecked(false);
    // eslint-disable-next-line
    }, [cleared]);

    return (<>
        <Checkbox edge="end"
            className={classes.checkBox}
            checked={checked}
            onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                setChecked(prev => !prev);
            }}
            disableRipple
        />
    </>);
}

export default memo(CheckSelect);